import _ from "lodash";
import { useMemo } from "react";
import type { ComboBoxParentProps } from "~/components/combobox";
import ComboBox from "~/components/combobox";
import type { PtoPlansQuery } from "~/types/api";
import { useFetcherData } from "~/utils/remix";

export default function PTOPicker({
  placeholder = "Select a PTO Plan...",
  ...rest
}: ComboBoxParentProps) {
  const fetcher = useFetcherData<PtoPlansQuery>("/resources/pto-plans/picker");

  const options = useMemo(
    () =>
      _.sortBy(fetcher.data?.ptoPlans || [], "id").map((o) => ({
        value: o.id,
        label: o.name
      })),
    [fetcher.data]
  );

  return <ComboBox {...rest} options={options} placeholder={placeholder} />;
}
