import _ from "lodash";
import type { Node, Text } from "slate";
import { Editor, Element, Transforms, createEditor } from "slate";
import { withReact } from "slate-react";
import type {
  CustomElement,
  CustomText,
  LinkElement,
  ListItemElement
} from "~/components/rich-editor";
import { withRichText } from "~/components/rich-editor";
import { withImages } from "~/components/rich-editor/images";
import { withLinks } from "~/components/rich-editor/links";
import { withLists } from "~/components/rich-editor/lists";
import withNormalization from "~/components/rich-editor/normalization";
import { dateIsValid, dayIsSame, formatDate, parseDate } from "~/utils/dates";

const processText = (input: string) => {
  let cleaned = input;
  let matches = cleaned.match(/(.*?)(\\_+)(.*)/);
  while (matches) {
    cleaned = matches[1] + "π".repeat(matches[2].length - 1) + matches[3];
    matches = cleaned.match(/(.*?)(\\_+)(.*)/);
  }
  const sections = cleaned.match(
    /(.*?)(_[^_]+?_|\[\[.*?\]\]|'''.*?'''|''.*?''|\b(?:pfcs\.co|www\.|\w+:\/\/)(?:[.)](?![\s.)]|$)|[^\s.)])*)(.*)/
  );

  if (!sections) {
    return [{ text: cleaned }];
  }

  let nodes: Node[] = [];
  if (sections && !sections[2].startsWith("\\")) {
    // const text = [];

    if (sections[1].length) {
      nodes.push({ text: sections[1] });
    }
    const section = sections[2];
    if (section.startsWith("_")) {
      nodes.push({
        text: section.replace(/^_(.*)_$/, "$1"),
        underline: true
      });
    } else if (section.startsWith("'''")) {
      nodes.push({
        text: section.replace(/^'''(.*)'''$/, "$1"),
        bold: true
      });
    } else if (section.startsWith("''")) {
      nodes.push({
        text: section.replace(/^''(.*)''$/, "$1"),
        italic: true
      });
    } else if (section.startsWith("[[")) {
      nodes.push({
        text: section.replace(/^\[\[(.*)\]\]$/, "$1"),
        highlight: true
      });
    } else {
      if (
        nodes.length === 0 ||
        (nodes[nodes.length - 1] as CustomElement).type === "link"
      ) {
        nodes.push({ text: "" });
      }
      nodes.push({
        type: "link",
        url: /\w+:\/\/.*/.test(section) ? section : `http://${section}`,
        children: [{ text: section }]
      });
    }
    if (sections[3].length) {
      nodes = nodes.concat(processText(sections[3]));
    }
  }
  if (
    nodes.length &&
    (nodes[nodes.length - 1] as CustomElement).type === "link"
  ) {
    nodes.push({ text: "" });
  }

  let dupes = true;
  do {
    const currentNodes = [...nodes];
    const index = currentNodes.findIndex((n, index) => {
      return (
        currentNodes[index + 1] &&
        Object.prototype.hasOwnProperty.call(n, "text") &&
        Object.prototype.hasOwnProperty.call(currentNodes[index + 1], "text") &&
        Object.keys(n).sort().toString() ===
          Object.keys(currentNodes[index + 1])
            .sort()
            .toString()
      );
    });
    if (index >= 0) {
      (nodes[index] as CustomText).text = `${
        (nodes[index] as CustomText).text || ""
      }${(nodes[index + 1] as CustomText).text || ""}`;
      nodes = [...nodes.slice(0, index + 1), ...nodes.slice(index + 2)];
    } else {
      dupes = false;
    }
  } while (dupes);

  return nodes;
};

const processLine = (
  line: string,
  newline: string | boolean = "normal"
): Node | Node[] => {
  const text: Node[] = processText(line).map((t) => {
    if (Object.prototype.hasOwnProperty.call(t, "text")) {
      return { ...t, text: (t as Text).text.replace(/π/g, "_") };
    }
    return t;
  });

  if (newline === "normal") {
    return {
      type: "paragraph",
      children: text as (CustomText | LinkElement)[]
    };
  }
  if (newline === "hard") {
    return {
      type: "paragraph",
      softBreak: true,
      children: text as (CustomText | LinkElement)[]
    };
  }
  return text;
};

export const convertTextToSlate = (text: string): Node[] => {
  let ops: Node[] = [];
  const lines = text.replace(/\r\n/g, "\n").split("\n");
  let prevLine = "";
  let prevIsList = false;
  let list: ListItemElement[] = [];
  let list2: ListItemElement[] = [];
  lines.forEach((_line, index) => {
    let line = _line;
    const nextLine = lines[index + 1];
    const isList = /^[-*#]/.test(line);
    const nextIsList = nextLine && /^[-*#]/.test(nextLine);
    // If this is an isolated "list" it's probably not a list
    if (!prevIsList && !nextIsList && /^[-*#]+/.test(line)) {
      ops = ops.concat(processLine(line));
    } else if (line.startsWith("=") && line.match(/(=+)\s*(.+)/)) {
      const parts = line.match(/(=+)\s*(.+)/)!;
      ops.push({
        type: `h${parts[1].length}` as "h1" | "h2" | "h3" | "h4" | "h5",
        children: [{ text: parts[2] }]
      });
    } else if (/^[-*#]/.test(line) && line.match(/^([-*#\s]+)(.*)/)) {
      line = `${line} `;
      const parts = line.match(/^([-*#\s]+)(.+)/)!;
      const mode = line.startsWith("#") ? "ordered-list" : "unordered-list";
      const prefix = parts[1].replace(/\s/g, "");
      const indent = prefix.length === 1 ? undefined : prefix.length - 1;
      const text = processLine(parts[2].trim(), "no");

      list.push({
        type: "list-item",
        children: [{ type: "list-item-child", children: text }],
        indent
      } as ListItemElement);
      if (!nextIsList) {
        for (const item of list) {
          if (!item.indent) {
            list2.push(item);
          } else {
            let _i = item.indent as number;
            let parent: ListItemElement[] = list2;
            while (_i > 0) {
              let sub = _.last(parent) as ListItemElement;
              if (!sub) {
                parent.push({
                  type: "list-item",
                  children: [
                    { type: "list-item-child", children: [{ text: "" }] }
                  ]
                });
                sub = _.last(parent) as ListItemElement;
              }
              if (sub.children.length === 1) {
                sub.children.push({ type: mode, children: [] });
              }
              parent = (sub.children[1] as CustomElement)
                .children as ListItemElement[];
              _i--;
            }
            parent.push(_.omit(item, "indent") as ListItemElement);
          }
        }

        ops.push({ type: mode, children: list2 });

        list = [];
        list2 = [];
      }
    } else if (line.trim() === "<page break>") {
      ops.push({ type: "page-break", children: [{ text: "" }] });
      // prevLine = line;
      // } else if (
      //   index > 0 &&
      //   prevLine &&
      //   prevLine.length &&
      //   line.length &&
      //   prevLine !== "<page break>" &&
      //   !prevLine.startsWith("=") &&
      //   !prevIsList
      // ) {
      //   ops = ops.concat(processLine(line, "hard"));
    } else if (
      nextLine?.length &&
      line.length &&
      nextLine !== "<page break>" &&
      !nextLine.startsWith("=")
    ) {
      ops = ops.concat(processLine(line, "hard"));
    } else if (prevIsList && nextIsList && !line.length) {
      // no op
    } else if (index > 0 && prevLine.length && !line.length) {
      if (
        prevIsList &&
        index < lines.length - 1 &&
        /^[-*#]/.test(lines[index + 1])
      ) {
        ops = ops.concat(processLine(line));
      }
    } else {
      // Normal line with no list/header
      ops = ops.concat(processLine(line));
    }
    prevLine = line;
    prevIsList = isList;
  });

  const e = withNormalization(
    withImages(withLinks(withLists(withRichText(withReact(createEditor())))))
  );

  // Merge similar lists
  while (
    ops.some((op, index) => {
      const next = ops[index + 1];
      return (
        Element.isElement(op) &&
        (op.type as string).endsWith("-list") &&
        next &&
        Element.isElement(next) &&
        next.type === op.type
      );
    })
  ) {
    const i = ops.findIndex((op, index) => {
      const next = ops[index + 1];
      return (
        Element.isElement(op) &&
        (op.type as string).endsWith("-list") &&
        next &&
        Element.isElement(next) &&
        next.type === op.type
      );
    })!;
    (ops[i] as CustomElement).children = [
      ...(ops[i] as CustomElement).children,
      ...(ops[i + 1] as CustomElement).children
      // Not sure how to fix for now
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    ] as any;
    ops = [...ops.slice(0, i + 1), ...ops.slice(i + 2)];
  }

  if (ops.length > 0 && (_.last(ops)! as CustomElement).type === "page-break") {
    ops.push({ type: "paragraph", children: [{ text: "" }] });
  }
  Transforms.insertNodes(e, ops);
  Editor.normalize(e, { force: true });
  return ops;
};

export const BLANK_SLATE: CustomElement[] = [
  { type: "paragraph", children: [{ text: "" }] }
];

export const slateHasFlags = (value: string | unknown) => {
  const string = _.isString(value) ? value : JSON.stringify(value);
  return string.includes('"highlight":true');
};

export const slateIsBlank = (
  value: Node[] | string | null | undefined
): boolean => {
  if (!value) return true;
  const array = _.isArray(value) ? value : JSON.parse(value); // : value;
  return _.isEqual(array, BLANK_SLATE);
};

export const slateHTMLIsBlank = (value: string) => value === "<p>&nbsp;</p>";

export const truncate = (text: string, length = 50) =>
  text.length > length ? `${text.substring(0, length - 3)}...` : text;

export const extractDate = (text: string) => {
  const regex1 = /\b\d{4}-\d{2}-\d{2}\b/;
  const regex2 = /\b\d{2}-\d{2}-\d{2}\b/;
  const match1 = text.match(regex1);
  const match2 = text.match(regex2);
  if (match1) {
    return parseDate(match1[0]);
    // trimmed = trimmed.replace(regex, "").replace(/\s+/, " ");
  }
  if (match2) {
    return parseDate(match2[0], "yy-MM-dd");
  }
  return null;
};

export const extractDateAndTitle: (
  text: string,
  options?: {
    removeSection?: boolean;
    removeDate?: boolean;
    removeSuffix?: boolean;
  }
) => [string | null, string, string] = (text, options = {}) => {
  let trimmed = text;
  if (options.removeSection) {
    const section = extractSection(trimmed);
    if (section) {
      trimmed = trimmed.replace(section, "").trim();
    }
  }
  const date = extractDate(text);
  let suffix = "";
  trimmed = trimmed.replace(/\.[a-z]{1,4}$/i, "");
  if (date && dateIsValid(date) && options.removeDate) {
    const regex1 = options.removeSuffix
      ? /\s(\d{2}(?:\d{2})?-\d{2}-\d{2})(\s+[\w\s]*[\w])?$/
      : /\s(\d{2}(?:\d{2})?-\d{2}-\d{2})$/;
    const regex2 = /^(\d{2}(?:\d{2})?-\d{2}-\d{2})\s/;
    const regexGlobal = /\b(\d{2}(?:\d{2})?-\d{2}-\d{2})\b/g;
    const match1 = trimmed.match(regex1);
    const match2 = trimmed.match(regex2);
    const count = trimmed.match(regexGlobal)?.length;
    if (match1) {
      suffix = (match1[2] || "").trim();
    }
    if ((match1 || match2) && count === 1) {
      const match = (match1 || match2)!;
      const d =
        match[1].length === 10
          ? parseDate(match[1])
          : parseDate(match[1], "yy-MM-dd");
      if (dateIsValid(d) && dayIsSame(d, date)) {
        trimmed = trimmed.replace(regex1, "").replace(regex2, "");
      }
    }
  }

  return [
    dateIsValid(date) ? formatDate(date, { format: "YYYY-MM-DD" }) : null,
    trimmed.trim(),
    options.removeSuffix ? suffix : ""
  ];
};

export const extractBates = (text: string) => {
  const re1 = /\b([A-Z_]{2,})\s*\d{4,}\s*-+\s*\d{1,3}\b/;
  const re = /([A-Z_]{2,})\s*\d{4,}(\s*-*\s*\1?\s*\d{4,})?/;
  const reNumOnly = /\d{4,}\s*-+\s*\s*\d{4,}/;
  const match = text.match(re) || text.match(reNumOnly);
  return !text.match(re1) && match ? match[0].trim() : null;
};

export const extractSection = (text: string) => {
  const base = text.replace(/\.\w+$/, "");
  const regex = /^(\d[A-Z]\d?)\s.+$/;
  const match = base.match(regex);
  if (match) {
    return match[1];
  }

  return null;
};

export const letterIndex = (num: number): string => {
  const prefix = num >= 26 ? letterIndex(((num / 26) >> 0) - 1) : "";
  return prefix + String.fromCharCode(65 + ((num % 26) >> 0));
};
