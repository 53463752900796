import { useState } from "react";

export type ArrayInputProps = {
  defaultValue?: string[];
  name: string;
  onChange?: (name: string, value: string[]) => void;
};

/**
 * This component takes a comma-separated string and sends a JSON array to the server.
 */
export default function ArrayInput({
  name,
  defaultValue = [],
  onChange
}: ArrayInputProps) {
  const [text, setText] = useState(defaultValue.join(", "));
  const values = text
    .split(",")
    .map((v) => v.trim())
    .filter(Boolean);

  return (
    <>
      <input
        className="form-control"
        type="text"
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <input type="hidden" name={name} value={JSON.stringify(values)} />
    </>
  );
}
