import clsx from "clsx";
import _ from "lodash";
import { Fragment, useMemo } from "react";
import Alert from "~/components/alert";
import Badge from "~/components/badge";
import Heading from "~/components/heading";
import Link from "~/components/link";
import NumberedTable from "~/components/numbered-table";
import ProjectTeamCells from "~/components/projects/team-cells";
import type {
  PumProjectFieldsFragment,
  UserNameFieldsFragment
} from "~/types/api";
import { useCurrentUser } from "~/utils/auth";
import {
  addMonths,
  dayIsAfter,
  dayIsBefore,
  dayIsSameOrAfter,
  dayIsSameOrBefore,
  formatDate
} from "~/utils/dates";
import { formatMoney, plural } from "~/utils/formatting";

interface Props {
  userId: string;
  role?: string;
  showHeading?: boolean;
  onlyWithIssues?: boolean;
  user?: UserNameFieldsFragment;
  projects: PumProjectFieldsFragment[];
}

export interface PUMProject extends PumProjectFieldsFragment {
  _planDate?: string | null;
  _technicalSummaryDate?: string | null;
  _due: number;
  _budget: number;
  _totalBilled: number;
  _psmAuditDate?: string | null;
  _techAuditDate?: string | null;
}

export default function PUMTable({
  userId,
  role,
  showHeading,
  onlyWithIssues,
  user,
  projects
}: Props) {
  const currentUser = useCurrentUser();

  const filteredProjects = useMemo(() => {
    if (!role) return projects;

    switch (role) {
      case "PM":
        return projects.filter((p) => p.pm?.id === userId);
      case "PC":
        return projects.filter(
          (p) => p.pc?.id === userId || (!p.pc && p.pm?.id === userId)
        );
      case "TL":
        return projects.filter((p) => p.technicalLead?.id === userId);
      default:
        return projects.filter((p) =>
          p.projectUsers.some((pu) => pu.role === role && pu.user.id === userId)
        );
    }
  }, [projects, role, userId]);

  const cutoff = addMonths(new Date(), -3);
  const cutoff1 = addMonths(new Date(), -1);

  const groups = useMemo(() => {
    let filtered = filteredProjects.map((p) => ({
      ...p,
      _budget: p.plan ? Number.parseFloat(p.plan.amount) : 0,
      _totalBilled: p.plan
        ? Number.parseFloat(p.plan.pendingTotal) +
          Number.parseFloat(p.plan.billedTotal)
        : 0,
      _due: Number.parseFloat(p.totalDue),
      _planDate: p.planUpdatedOn,
      _technicalSummaryDate: p.technicalSummaryDate,
      _psmAuditDate: _.first(
        _.orderBy(
          p.audits.filter((audit) => audit.auditTemplate.name === "PC/PM"),
          "itemDate",
          "desc"
        )
      )?.itemDate,
      _techAuditDate: _.first(
        _.orderBy(
          p.audits.filter((audit) => audit.auditTemplate.name === "Technical"),
          "itemDate",
          "desc"
        )
      )?.itemDate
    }));

    if (onlyWithIssues) {
      filtered = filtered.filter((p) => {
        return (
          p.status === "Active" &&
          (p._budget < p._totalBilled ||
            !p._psmAuditDate ||
            dayIsAfter(cutoff, p._psmAuditDate) ||
            !p._techAuditDate ||
            dayIsAfter(cutoff, p._techAuditDate) ||
            !p._planDate ||
            dayIsAfter(cutoff, p._planDate) ||
            !p._technicalSummaryDate ||
            dayIsAfter(cutoff1, p._technicalSummaryDate) ||
            !p.guesstimate ||
            Number.parseFloat(p.guesstimate) === 0)
        );
      });
    }

    return _.groupBy(filtered, "status");
  }, [filteredProjects, onlyWithIssues, cutoff, cutoff1]);

  return (
    <>
      {showHeading && (
        <Heading
          title={`Projects Under Management${
            userId === "ALL" ? ": All Staff" : user ? `: ${user.fullname}` : ""
          }`}
        />
      )}

      {!projects.length ? (
        <Alert mode="warning">
          {currentUser.id === userId
            ? "You don't"
            : "The selected person doesn't"}{" "}
          have any billable projects
        </Alert>
      ) : (
        <NumberedTable>
          <thead>
            <tr>
              <th />
              <th>Project</th>
              <th className="text-center">PC</th>
              <th className="text-center">TL</th>
              <th className="text-center">Exp</th>
              <th className="text-center">PM</th>
              <th className="text-center">Plan Updated</th>
              <th className="text-center">
                Tech Summary
                <br />
                Updated
              </th>
              <th className="text-center">
                Project
                <br />
                Audit
              </th>
              <th className="text-center">
                Tech
                <br />
                Audit
              </th>
              <th className="text-right">Balance Due</th>
              <th className="text-right">Budget</th>
              <th className="text-right">Billed (incl. Pending)</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(groups)
              .sort()
              .map((g) => (
                <Fragment key={g}>
                  <tr className="success font-bold">
                    <td />
                    <td colSpan={5}>
                      {plural(`${g} Project`, groups[g].length, true)}
                    </td>
                    <td className="text-center">
                      {
                        groups[g].filter(
                          (p) =>
                            p.planUpdatedOn &&
                            dayIsSameOrAfter(p.planUpdatedOn, cutoff)
                        ).length
                      }{" "}
                      Current
                    </td>
                    <td className="text-center">
                      {
                        groups[g].filter(
                          (p) =>
                            p.technicalSummaryDate &&
                            dayIsSameOrAfter(p.technicalSummaryDate, cutoff1)
                        ).length
                      }{" "}
                      Current
                    </td>
                    <td className="text-center">
                      {
                        groups[g].filter((p) =>
                          p.audits.some(
                            (audit) =>
                              audit.auditTemplate.name === "PC/PM" &&
                              dayIsSameOrBefore(cutoff, audit.itemDate)
                          )
                        ).length
                      }{" "}
                      Current
                    </td>
                    <td className="text-center">
                      {
                        groups[g].filter((p) =>
                          p.audits.some(
                            (audit) =>
                              audit.auditTemplate.name === "Technical" &&
                              dayIsSameOrBefore(cutoff, audit.itemDate)
                          )
                        ).length
                      }{" "}
                      Current
                    </td>
                    <td className="text-right">
                      {formatMoney(
                        groups[g].reduce((acc, p) => acc + p._due, 0)
                      )}
                    </td>
                    <td className="text-right">
                      {formatMoney(
                        groups[g].reduce((acc, p) => acc + p._budget, 0)
                      )}
                    </td>
                    <td className="text-right">
                      {
                        groups[g].filter((p) => p._totalBilled > p._budget)
                          .length
                      }{" "}
                      Over Budget
                    </td>
                  </tr>
                  {groups[g].map((p) => (
                    <Project key={p.id} project={p} />
                  ))}
                </Fragment>
              ))}
          </tbody>
        </NumberedTable>
      )}
    </>
  );
}

type RowProps = {
  project: PUMProject;
};

const Project = ({ project }: RowProps) => {
  const cutoff = addMonths(new Date(), -3);
  const cutoff1 = addMonths(new Date(), -1);

  return (
    <tr>
      <td />
      <td>
        <div className="flex items-center justify-between space-x-4">
          <Link to={`/projects/${project.number}`}>
            {project.number} {project.name}
          </Link>
          {(!project.guesstimate ||
            Number.parseFloat(project.guesstimate) === 0) && (
            <Badge mode="danger">Missing Guesstimate</Badge>
          )}
        </div>
      </td>
      <ProjectTeamCells project={project} />
      <td
        className={clsx(
          "text-center",
          project.status === "Active" &&
            (!project._planDate || dayIsBefore(project._planDate, cutoff)) &&
            "danger text-danger font-bold"
        )}
      >
        {project._planDate ? formatDate(project._planDate) : "-"}
      </td>
      <td
        className={clsx(
          "text-center",
          project.status === "Active" &&
            !project.qcExemptTechnical &&
            (!project._technicalSummaryDate ||
              dayIsBefore(project._technicalSummaryDate, cutoff1)) &&
            "danger text-danger font-bold"
        )}
      >
        {project._technicalSummaryDate
          ? formatDate(project._technicalSummaryDate)
          : "-"}
      </td>
      <td
        className={clsx(
          "text-center",
          !project._psmAuditDate ||
            (dayIsAfter(cutoff, project._psmAuditDate) &&
              "danger text-danger font-bold")
        )}
      >
        {project._psmAuditDate ? formatDate(project._psmAuditDate) : "-"}
      </td>
      <td
        className={clsx(
          "text-center",
          !project.qcExemptTechnical &&
            (!project._techAuditDate ||
              dayIsAfter(cutoff, project._techAuditDate)) &&
            "danger text-danger font-bold"
        )}
      >
        {project._techAuditDate ? formatDate(project._techAuditDate) : "-"}
      </td>
      <td className="text-right">
        {formatMoney(project._due, { zeroes: false })}
      </td>
      <td className="text-right">
        {formatMoney(project._budget, { zeroes: false })}
      </td>

      <td
        className={clsx(
          "text-right",
          project._totalBilled > project._budget &&
            "danger text-danger font-bold"
        )}
      >
        {formatMoney(project._totalBilled, { zeroes: false })}
      </td>
    </tr>
  );
};
