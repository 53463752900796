import _ from "lodash";
import { useContext } from "react";
import { Input } from "~/components/remix-form";
import { BPALinksContext } from "~/contexts";
import type { BpaLinkInput } from "~/types/api";

interface Props {
  source: { __typename: string; id: string };
}

const mappings: Record<string, string[]> = {
  Address: ["addressId", "Address"],
  Location: ["locationId", "Location"],
  Element: ["elementId", "Element"],
  Issue: ["issueId", "Issue"],
  Player: ["playerId", "Player"],
  TestLocation: ["testLocationId", "Test Location"],
  InfoImage: ["infoImageId", "Image"],
  Document: ["documentId", "File"]
};

const enabled = [
  "Address.Document",
  "Address.Issue",
  "Address.Location",
  "Address.Player",
  "Document.Location",
  "Document.Issue",
  "Document.Player",
  "Document.TestLocation",
  "Element.Location",
  "Issue.Location",
  "Issue.Player",
  "Issue.TestLocation",
  "Location.Player",
  "Location.TestLocation"
];

export default function DescriptionField({ source }: Props) {
  const { items, sourceType, change } = useContext(BPALinksContext)!;
  const index = items.findIndex(
    (l) => l[mappings[source.__typename][0] as keyof BpaLinkInput] === source.id
  );

  const rel = [source.__typename, sourceType].sort().join(".");

  if (!enabled.includes(rel)) return null;

  return (
    <tr className="skip-numbering hover:!bg-white">
      <td />
      <td />
      <td colSpan={99} className="!pb-0">
        <Input
          type="slate"
          name={`bpaLinks.${index}.descriptionSlate`}
          value={items[index].descriptionSlate as string}
          onChange={(_name, value) =>
            change(items[index], "descriptionSlate", JSON.stringify(value))
          }
          noLabel
          placeholder={`Add more information on how this ${_.lowerCase(
            mappings[source.__typename][1]
          )} and ${_.lowerCase(sourceType || "Entry")} relate...`}
        />
      </td>
    </tr>
  );
}
