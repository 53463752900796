import _ from "lodash";
import { useMemo } from "react";
import type { ComboBoxParentProps } from "~/components/combobox";
import ComboBox from "~/components/combobox";
import type { AgreementPickerQuery } from "~/types/api";
import { useFetcherData } from "~/utils/remix";

export default function AgreementPicker({
  placeholder = "Select a Consulting Agreement...",
  ...rest
}: ComboBoxParentProps) {
  const fetcher = useFetcherData<AgreementPickerQuery>(
    "/resources/agreements/picker"
  );

  const options = useMemo(
    () =>
      _.orderBy(
        fetcher.data?.agreements || [],
        [(a) => a.title === "Standard Consulting Agreement", "title"],
        ["desc", "asc"]
      ).map((d) => ({
        value: d.id,
        label: d.title
      })),
    [fetcher.data]
  );

  return <ComboBox {...rest} placeholder={placeholder} options={options} />;
}
