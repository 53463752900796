import _ from "lodash";

const S3_CHUNK_SIZE = 100_000_000;

// In case of a network error, retry the upload up to 5 times
export const uploadWithProgressRetryable = async (
  method: string,
  url: string | URL,
  file: Blob,
  onProgress?: (event: ProgressEvent<XMLHttpRequestEventTarget>) => void
) => {
  let retries = 0;
  while (retries < 5) {
    try {
      return await uploadWithProgress(method, url, file, onProgress);
    } catch (e) {
      console.log("Error uploading part to S3. Retrying...", e);
      retries++;
      await new Promise((resolve) => setTimeout(resolve, 5000));
      if (retries >= 5) {
        throw e;
      }
    }
  }
  throw new Error("Max retries reached");
};

export const uploadWithProgress = (
  method: string,
  url: string | URL,
  file: Blob,
  onProgress?: (event: ProgressEvent<XMLHttpRequestEventTarget>) => void
) => {
  return new Promise<XMLHttpRequest>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.addEventListener("load", () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr);
      } else {
        // Add more debugging to see why this sometimes rejects
        reject({
          url,
          method,
          fileSize: file.size,
          status: xhr.status,
          statusText: xhr.statusText
        });
      }
    });
    onProgress && xhr.upload.addEventListener("progress", onProgress);
    xhr.addEventListener("error", (e) => {
      reject({
        status: xhr.status,
        statusText: xhr.statusText,
        error: e
      });
    });

    xhr.open(method, url);
    xhr.send(file);
  });
};

export const uploadMultipartWithProgress = async (
  file: File,
  urlString: string,
  onProgress?: (
    event: ProgressEvent<XMLHttpRequestEventTarget>,
    partNumber: number
  ) => void
) => {
  const urls = urlString.split("\n");
  console.log("upload urls", urls);
  const etags: string[] = [];

  for (const partNumber of _.range(1, urls.length + 1)) {
    console.log("uploading part", partNumber);
    const blob = file.slice(
      (partNumber - 1) * S3_CHUNK_SIZE,
      partNumber * S3_CHUNK_SIZE
    );

    try {
      const response = await uploadWithProgressRetryable(
        "PUT",
        urls[partNumber - 1],
        blob,
        (e) => {
          onProgress?.(e, partNumber);
        }
      );
      etags.push(response.getResponseHeader("ETag") as string);
    } catch (e) {
      console.log("Error uploading part", partNumber, e);
      throw e;
    }
  }
  console.log("etags", etags);
  return etags;
};
