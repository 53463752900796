import { useLoaderData } from "@remix-run/react";
import invariant from "tiny-invariant";
import IndividualBoard from "~/components/kanban/individual-board";
import IndividualRocks from "~/components/scoreboard/individual-rocks";
import type { loader } from "~/routes/_index/route";

export default function DashboardIT() {
  const loaderData = useLoaderData<typeof loader>();
  const { dashboard } = loaderData;
  invariant(dashboard === "IT", "Dashboard data doesn't match");
  return (
    <div>
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-3">
          <IndividualBoard />
        </div>
        <div>
          <IndividualRocks />
        </div>
      </div>
    </div>
  );
}
