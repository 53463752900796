import { atom } from "jotai";
import _ from "lodash";
import type {
  BasicAnalysisProjectFieldsFragment,
  OpportunityFieldsFragment,
  ProjectFormFieldsFragment,
  TeamFieldsFragment
} from "~/types/api";
import {
  addMonths,
  dayIsBefore,
  differenceInDays,
  formatDate,
  isPastDue,
  maxDate,
  startOfDay
} from "~/utils/dates";

export const technicalSummaryOutdated = (
  project: ProjectFormFieldsFragment | BasicAnalysisProjectFieldsFragment
) =>
  ["Active", "HOLD"].includes(project.status) &&
  (!project.technicalSummaryDate ||
    dayIsBefore(project.technicalSummaryDate, addMonths(new Date(), -1)));

export const teamIncludes = (project: TeamFieldsFragment, id: string) => {
  const users = [project.pc, project.pm, project.technicalLead]
    .concat(project.projectUsers.map((pu) => pu.user))
    .filter((u) => !!u)
    .map((u) => u!.id);
  return users.includes(id);
};

export const PROJECT_TEAM_MODES = {
  all: "PM/PC/Technical Lead/Expert",
  pm_or_pc: "PM/PC",
  pm: "PM",
  pc: "PC",
  tl: "Technical Lead",
  expert: "Expert"
};

export const compileOpportunities = (projects: OpportunityFieldsFragment[]) =>
  projects.map((p) => {
    const sp = _.sortBy(p.salesProjections, "createdAt").filter(
      (sp) => sp.salesStage!.id === p.salesStage!.id
    )[0];
    const diff = differenceInDays(new Date(), startOfDay(sp.createdAt));
    const age = differenceInDays(new Date(), p.opportunityStartDate!);
    const recentSp = _.orderBy(p.salesProjections, "updatedAt", "desc")[0]
      .updatedAt;
    const noteDate = _.first(
      _.orderBy(p.notes || [], "itemDate", "desc")
    )?.itemDate;

    const lastTouched = noteDate
      ? formatDate(maxDate([recentSp, noteDate].filter(Boolean)), {
          format: "YYYY-MM-DD"
        })
      : formatDate(recentSp, { format: "YYYY-MM-DD" });
    const tasks = _.sortBy(
      p.tasks.filter((t) => !t.completed),
      "itemDate"
    );
    const report = _.orderBy(
      p.reports.filter(
        (r) =>
          (r.name.match(/proposal/i) || r.proposalFields) &&
          r.status === "Published"
      ),
      "publishedOn",
      "desc"
    )[0];
    const cl = p.checklists.find(
      (c) => c.checklistTemplate.title === "Sales/Opportunity Checklist"
    );
    const nextItem = cl
      ? _.sortBy(cl.checklistItems, "checklistItemTemplate.rank").find(
          (i) => !i.complete
        )
      : null;
    return {
      ...p,
      report,
      nextItem,
      stageDuration: diff,
      age,
      lastTouched,
      task: tasks[0],
      pastDue: tasks[0] && isPastDue(tasks[0].itemDate)
    };
  });

export const rotatingAtom = atom<string[]>([]);
export const selectedIdsAtom = atom<string[]>([]);
export const invoiceDetailsShowState = atom<string[]>([]);
