import _ from "lodash";
import { useMemo } from "react";
import type { ComboBoxParentProps } from "~/components/combobox";
import ComboBox from "~/components/combobox";
import { useProject } from "~/contexts";
import type { RetainerInvoicesPickerQuery } from "~/types/api";
import { formatDate } from "~/utils/dates";
import { formatMoney } from "~/utils/formatting";
import { useFetcherData } from "~/utils/remix";

export default function RetainerInvoicePicker(props: ComboBoxParentProps) {
  const project = useProject();
  const fetcher = useFetcherData<RetainerInvoicesPickerQuery>(
    `/resources/retainer-invoices/picker?projectId=${project.id}`
  );

  const options = useMemo(
    () =>
      _.sortBy(fetcher.data?.retainerInvoices, "date").map((d) => ({
        value: d.id,
        label: `${formatDate(d.date)}: ${formatMoney(d.amount)}`
      })),
    [fetcher.data]
  );

  return (
    <ComboBox
      placeholder="Select a retainer invoice..."
      {...props}
      options={options}
      noOptionsMessage="No Available Retainer Invoices"
    />
  );
}
