import { useEffect, useState } from "react";
import type { TextareaAutosizeProps } from "react-textarea-autosize";
import Textarea from "react-textarea-autosize";

export default function WrappedTextarea({
  forwardRef,
  ...rest
}: TextareaAutosizeProps & {
  forwardRef?: React.MutableRefObject<
    HTMLInputElement | HTMLTextAreaElement | null
  >;
}) {
  // Need to trigger a second render to get the textarea to resize properly
  // inside modals
  const [, setRendered] = useState(false);
  useEffect(() => setRendered(true), []);

  return (
    <Textarea
      {...rest}
      ref={forwardRef as React.MutableRefObject<HTMLTextAreaElement>}
    />
  );
}
