import clsx from "clsx";
import type React from "react";

export default function ButtonGroup({
  className = "",
  children,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={clsx("btn-group whitespace-nowrap", className)} {...rest}>
      {children}
    </div>
  );
}
