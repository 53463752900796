import _ from "lodash";
import FormattedDate from "~/components/formatted-date";
import Modal from "~/components/modal";
import Table from "~/components/table";
import type { KanbanCardFieldsFragment } from "~/types/api";
import { plural } from "~/utils/formatting";

interface Props {
  card: KanbanCardFieldsFragment;
}

export default function Metrics({ card }: Props) {
  const events = _.sortBy(card.events, "time");

  return (
    <Modal.Body>
      <Table>
        <thead>
          <tr>
            <th>Column</th>
            <th>Updated At</th>
          </tr>
        </thead>
        <tbody>
          {events.map((event, index) => (
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            <tr key={index}>
              <td>{event.column}</td>
              <td>
                <FormattedDate date={event.time!} format="L LT" />
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr className="success">
            <td title="Days since the card was created">Lead Time</td>
            <td>{plural("Day", card.leadTime!, true)}</td>
          </tr>
          <tr className="success">
            <td title="Days since work was started">Cycle Time</td>
            <td>
              {card.cycleTime === null || card.cycleTime === undefined
                ? "Not Yet Completed"
                : plural("Day", card.cycleTime, true)}
            </td>
          </tr>
        </tfoot>
      </Table>
    </Modal.Body>
  );
}
