import _ from "lodash";
import { Presence } from "phoenix";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import invariant from "tiny-invariant";
import { v1 as uuidv1 } from "uuid";
import { useSocket } from "~/contexts";
import { useCurrentUser } from "~/utils/auth";
import { useHydrated } from "~/utils/remix";

export const useToggle = (
  initial = false
): [boolean, (next?: boolean) => void] => {
  const [value, setValue] = useState(initial);

  const toggle = useCallback((next?: boolean) => {
    setValue((value) => {
      return typeof next === "boolean" ? next : !value;
    });
  }, []);

  return [value, toggle];
};

export const useWindowSize = () => {
  const [size, setSize] = useState(
    typeof document !== "undefined"
      ? [window.innerWidth, window.innerHeight]
      : [0, 0]
  );
  useEffect(() => {
    if (typeof document === "undefined") return;
    const updateSize = () => setSize([window.innerWidth, window.innerHeight]);
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

export type PresenceInfo = {
  fullname: string;
  user_id: string;
  ip?: string;
  online_at: number;
  phx_ref: string;
  version: string;
  dirty?: boolean;
  uuid?: string;
};

export type PresenceStruct = Record<string, { metas: PresenceInfo[] }>;
export type FormPresence = {
  uuid: string;
  presences: PresenceInfo[];
  others: PresenceInfo[];
  mine: PresenceInfo[];
  updated?: boolean;
};

export const usePresence = (
  key: string | null,
  dirty?: boolean
): FormPresence => {
  invariant(key !== "app", "Cannot use presence for app channel");
  const [presences, setPresences] = useState<PresenceInfo[]>([]);
  const currentUser = useCurrentUser();
  const [uuid] = useState(uuidv1());
  const { socket } = useSocket();

  useEffect(() => {
    if (socket && key && currentUser.id) {
      const room = socket.channel(`room:${key}`, { uuid, dirty });
      const presence = new Presence(room);
      presence.onSync(() => {
        const state = (presence as unknown as { state: PresenceStruct }).state;
        const all = _.flatMap(state, (value) => value.metas);
        setPresences(all);
      });
      room.join();

      return () => {
        room?.leave();
      };
    }
  }, [currentUser.id, key, dirty, socket, uuid]);

  const data = useMemo(
    () => ({
      presences,
      others: presences.filter((p) => p.user_id !== currentUser.id),
      mine: presences.filter(
        (p) => p.user_id === currentUser.id && p.uuid !== uuid
      ),
      uuid: uuid
    }),
    [presences, uuid, currentUser.id]
  );

  return data;
};

export const useDelayedValue = (
  value: string,
  opts: { delay: number; minLength: number } = { delay: 500, minLength: 1 }
) => {
  const [delayedValue, setDelayedValue] = useState(value);
  const timer = useRef<number | null>(null);

  useEffect(() => {
    if (timer.current) window.clearTimeout(timer.current);

    if (value.length === 0) {
      setDelayedValue("");
    } else {
      timer.current = window.setTimeout(() => {
        if (value.length >= opts.minLength) setDelayedValue(value);
      }, opts.delay);
    }
    return () => {
      if (timer.current) window.clearTimeout(timer.current);
    };
  }, [value, opts]);

  return delayedValue;
};

export const useIsStale = () => {
  const isHydrated = useHydrated();
  const [data, setData] = useState<{ version: string } | null>(null);
  let isStale = false;
  if (isHydrated && data) {
    isStale =
      data.version !==
      `${import.meta.env.VITE_SENTRY_RELEASE || "N/A"} (${
        import.meta.env.VITE_SENTRY_RELEASE_DATE || "N/A"
      })`;
  }

  useEffect(() => {
    if (isStale) {
      return;
    }
    const id = setInterval(async () => {
      // Use fetch instead of useFetcher to ensure we get a response
      // even if the remix server had updated.
      const result = await fetch("/resources/api/version");

      // This can sometimes fail I guess, maybe if their wifi/network is down?
      if (result) {
        const json = (await result.json()) as { version: string };
        setData(json);
      }
    }, 15_000);
    return () => clearInterval(id);
  }, [isStale]);

  return isStale;
};

export const useSleepLock = () => {
  const lock = useRef<WakeLockSentinel | null>(null);
  const [locked, setLocked] = useState(false);

  const start = useCallback(async () => {
    try {
      lock.current = await navigator.wakeLock.request();
      setLocked(true);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const stop = useCallback(async () => {
    if (lock.current) {
      await lock.current.release();
      setLocked(false);
      lock.current = null;
    }
  }, []);

  // Re-request the lock if the tab becomes visible and we didn't explicitly release
  useEffect(() => {
    const handle = async () => {
      if (
        lock.current?.released &&
        locked &&
        document.visibilityState === "visible"
      ) {
        lock.current = await navigator.wakeLock.request();
      }
    };

    document.addEventListener("visibilitychange", handle);

    return () => {
      document.removeEventListener("visibilitychange", handle);
    };
  }, [locked]);

  return useMemo(() => ({ locked, start, stop }), [locked, start, stop]);
};
