import _ from "lodash";
import { useMemo } from "react";
import type { ComboBoxParentPropsSingle } from "~/components/combobox";
import ComboBox from "~/components/combobox";
import type { OfficesPickerQuery } from "~/types/api";
import { useFetcherData } from "~/utils/remix";

export interface OfficePickerProps extends ComboBoxParentPropsSingle {
  physical?: boolean;
  valueField?: "id" | "location";
}

export default function OfficePicker({
  valueField = "id",
  placeholder = "Select an Office...",
  physical,
  ...rest
}: OfficePickerProps) {
  const fetcher = useFetcherData<OfficesPickerQuery>(
    `/resources/offices/picker?physical=${physical}`
  );

  const options = useMemo(
    () =>
      _.sortBy(fetcher.data?.offices || [], "location").map((o) => ({
        value: o[valueField as "id" | "location"],
        label: o.location
      })),
    [fetcher.data, valueField]
  );

  return <ComboBox {...rest} placeholder={placeholder} options={options} />;
}
