import { useState } from "react";
import type { Crop } from "react-image-crop";
import ReactCrop from "react-image-crop";
import { IconEdit } from "~/components/icons";
import Modal from "~/components/modal";
import { Buttons, Errors, RemixForm } from "~/components/remix-form";
import type { UploadFieldsFragment } from "~/types/api";

type Props = {
  upload?: UploadFieldsFragment | null;
  editable?: boolean;
};

export default function EditableThumb({ editable, upload }: Props) {
  const [editing, setEditing] = useState(false);
  const [crop, setCrop] = useState<Crop | undefined>();

  if (!upload || !upload?.thumbUrl) {
    return null;
  }

  return (
    <>
      <div className="relative aspect-square w-32">
        <img
          src={upload.thumbUrl.replace("files-dev", "files")}
          key={upload.thumbUrl}
          className="aspect-square w-32 rounded-full object-cover"
        />
        {editable && (
          <div
            className="absolute bottom-0 left-0 right-0 top-0 flex cursor-pointer items-center justify-center rounded-full opacity-0 transition-all hover:bg-black hover:bg-opacity-50 hover:opacity-100"
            onClick={() => setEditing(true)}
          >
            <IconEdit className="text-white" />
          </div>
        )}
      </div>
      {editing && (
        <Modal onClose={() => setEditing(false)}>
          <RemixForm
            fetcher
            action="/resources/uploads/save"
            onSuccess={() => setEditing(false)}
          >
            <input type="hidden" name="id" value={upload.id} />
            <input
              type="hidden"
              name="cropInfo"
              value={
                crop?.width === 0 ? JSON.stringify("") : JSON.stringify(crop)
              }
            />
            <Modal.Header title="Edit Thumbnail" />
            <Modal.Body>
              <Errors />
              <ReactCrop
                crop={crop}
                onChange={(c, pc) => setCrop(pc)}
                aspect={1}
                circularCrop
                ruleOfThirds
              >
                <img
                  src={upload.url}
                  className="h-full w-full rounded-md object-cover"
                  onLoad={() => {
                    // Set it here, otherwise the overlay doesn't work
                    // See https://github.com/sekoyo/react-image-crop/issues/579
                    setCrop(
                      upload?.cropInfo
                        ? (JSON.parse(upload.cropInfo) as Crop)
                        : undefined
                    );
                  }}
                />
              </ReactCrop>
            </Modal.Body>
            <Modal.Footer>
              <Buttons modal savingLabel="Updating Thumbnail..." />
            </Modal.Footer>
          </RemixForm>
        </Modal>
      )}
    </>
  );
}
