import _ from "lodash";
import type {
  Address,
  AddressFieldsFragment,
  AddressFormFieldsFragment,
  DocumentRowFieldsFragment,
  DocumentRowFieldsWithSourceFragment,
  DocumentShowFieldsFragment,
  Element,
  ElementListFieldsFragment,
  Issue,
  IssueFieldsFragment,
  Location,
  LocationFieldsFragment,
  MeetingFieldsFragment,
  PhotosetPickerFieldsFragment,
  Player,
  PlayerFieldsFragment,
  ShowTestLocationFieldsFragment,
  TestLocation
} from "~/types/api";
import { formatDate } from "~/utils/dates";
import { getPrimaryFile } from "~/utils/documents";
import { plural } from "~/utils/formatting";

const DisplayNames = {
  interview(item: MeetingFieldsFragment) {
    let result = "";
    if (item.date) {
      result = `${formatDate(item.date)} `;
    }
    result = `${result}${item.description}`;

    if (item.attendees) {
      result = `${result} - ${item.attendees}`;
    }
    return result;
  },
  photoset(item: Partial<PhotosetPickerFieldsFragment>, keyPhotos = false) {
    let result: string;

    if (!item.pfcs) {
      result = item.party || "";
      if (item.inspectionDate) {
        const formatted = formatDate(item.inspectionDate);
        result = `${result} - ${formatted}`;
      } else {
        result = `${result} - N/A`;
      }
      return result;
    }

    const date = formatDate(item.inspectionDate!);
    const day = item.day! < 10 ? `0${item.day}` : item.day;
    const expert = item.user!.login;

    result = `${date} ${expert}-${day} - ${item.user!.fullname}`;

    if (keyPhotos && item.keyPhotoCount !== undefined) {
      result = `${result} (${plural("key photo", item.keyPhotoCount, true)})`;
    }

    return result;
  },

  address(
    item:
      | AddressFieldsFragment
      | AddressFormFieldsFragment
      | Pick<Address, "id" | "displayNameShort">,
    keyPhotos = false
  ) {
    // let result = item.physicalAddress?.formatted || item.name!;
    // if (item.physicalAddress?.unit && item.address) {
    //   result = `${item.physicalAddress.unitType || ""} ${
    //     item.physicalAddress.unit
    //   }`.trim();
    // }
    // if (item.physicalAddress && item.name) {
    //   result = `${result}: ${item.name}`;
    // }
    if (keyPhotos && "pfcsKeyPhotoCount" in item) {
      return `${item.displayNameShort} (${plural(
        "key photo",
        item.pfcsKeyPhotoCount + item.otherKeyPhotoCount,
        true
      )})`;
    }
    return item.displayNameShort;
  },

  location(
    item:
      | LocationFieldsFragment
      | Pick<
          Location,
          "id" | "name" | "pfcsKeyPhotoCount" | "otherKeyPhotoCount"
        >,
    keyPhotos = false
  ) {
    let result = item.name;
    if (keyPhotos) {
      result = `${result} (${plural(
        "key photo",
        item.pfcsKeyPhotoCount + item.otherKeyPhotoCount,
        true
      )})`;
    }

    return result;
  },

  element(
    item:
      | ElementListFieldsFragment
      | Pick<
          Element,
          "id" | "number" | "name" | "pfcsKeyPhotoCount" | "otherKeyPhotoCount"
        >,
    keyPhotos = false
  ) {
    let result = `${item.number} ${item.name}`;
    if (keyPhotos) {
      result = `${result} (${plural(
        "key photo",
        item.pfcsKeyPhotoCount + item.otherKeyPhotoCount,
        true
      )})`;
    }

    return result;
  },

  issue(
    item:
      | IssueFieldsFragment
      | Pick<
          Issue,
          | "id"
          | "number"
          | "description"
          | "pfcsKeyPhotoCount"
          | "otherKeyPhotoCount"
        >,
    keyPhotos = false
  ) {
    const number = item.number ? `${item.number}: ` : "";
    let result = `${number}${item.description}`;
    if (keyPhotos) {
      result = `${result} (${plural(
        "key photo",
        item.pfcsKeyPhotoCount + item.otherKeyPhotoCount,
        true
      )})`;
    }

    return result;
  },

  player(
    item:
      | PlayerFieldsFragment
      | Pick<
          Player,
          | "id"
          | "company"
          | "name"
          | "role"
          | "description"
          | "pfcsKeyPhotoCount"
          | "otherKeyPhotoCount"
        >,
    keyPhotos = false,
    comments = false
  ) {
    let result = [item.company, item.name].filter((i) => i?.length).join(" - ");
    if (item.role?.length) {
      result = `${result} (${item.role})`;
    }

    if (comments && item.description) {
      result = `${result}: ${item.description}`;
    }

    if (keyPhotos) {
      result = `${result} (${plural(
        "key photo",
        item.pfcsKeyPhotoCount + item.otherKeyPhotoCount,
        true
      )})`;
    }

    return result;
  },

  testLocation(
    item:
      | ShowTestLocationFieldsFragment
      | Pick<
          TestLocation,
          | "id"
          | "description"
          | "number"
          | "pfcsKeyPhotoCount"
          | "otherKeyPhotoCount"
        >,
    keyPhotos = false
  ) {
    let result = item.number;
    if (item.description?.length) {
      result = `${result}: ${item.description}`;
    } else {
      result = `${result}: No Description`;
    }

    if (keyPhotos) {
      result = `${result} (${plural(
        "key photo",
        item.pfcsKeyPhotoCount + item.otherKeyPhotoCount,
        true
      )})`;
    }

    return result;
  },

  document(
    document:
      | DocumentRowFieldsFragment
      | DocumentRowFieldsWithSourceFragment
      | DocumentShowFieldsFragment,
    options: { includeSection?: boolean; useReferenceSection?: boolean } = {}
  ) {
    if (!document) {
      return "";
    }
    let output = [];
    const d = document.referenceDocument || document;

    if (options.includeSection) {
      output.push(
        `${document.section || ""}${
          document.suffix ? `-${document.suffix}` : ""
        }`
      );
    } else if (options.useReferenceSection && document.referenceDocument) {
      output.push(`${d.section || ""}${d.suffix ? `-${d.suffix}` : ""}`);
    }
    let title = d.description;
    if (!title) {
      const af = getPrimaryFile(d);
      if (af?.upload) {
        title = af.upload.filename;
      } else if (document.mode === "File") {
        title = "N/A";
      } else {
        title = "";
      }
    }

    if (d.author && title.length) {
      output.push(`${d.author} - ${title}`);
    } else if (d.author) {
      output.push(d.author);
    } else {
      output.push(title);
    }

    if (d.date) {
      output.push(formatDate(d.date));
    } else if (d.receivedDate) {
      output.push(`RECD ${formatDate(d.receivedDate)}`);
    }

    output = _.reject(output, (item) => _.isUndefined(item) || _.isNull(item));

    return output.join(" ");
  }
};

export default DisplayNames;
