import _ from "lodash";
import { useMemo } from "react";
import type { ComboBoxParentProps } from "~/components/combobox";
import ComboBox from "~/components/combobox";
import type { DocumentCategoriesQuery } from "~/types/api";
import { useFetcherData } from "~/utils/remix";

export default function DocumentCategoryPicker(props: ComboBoxParentProps) {
  const fetcher = useFetcherData<DocumentCategoriesQuery>(
    "/resources/document-categories/list"
  );

  const options = useMemo(() => {
    const categories = _.sortBy(fetcher.data?.documentCategories || [], "name");
    return categories.map((category) => ({
      label: category.name,
      options: category.documentCategories.map(
        (child: { id: string; name: string }) => ({
          value: child.id,
          label: child.name
        })
      )
    }));
  }, [fetcher.data]);

  return (
    <ComboBox placeholder="Select a Category..." options={options} {...props} />
  );
}
