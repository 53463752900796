import _ from "lodash";
import { useContext, useMemo, useState } from "react";
import type { ComboBoxParentPropsSingle } from "~/components/combobox";
import ComboBox from "~/components/combobox";
import {
  ContentTopicContext,
  useOptionalEmployee,
  useOptionalProject
} from "~/contexts";
import type { DocumentPartiesQuery } from "~/types/api";
import { useFetcherData } from "~/utils/remix";

export default function AuthorCombo(props: ComboBoxParentPropsSingle) {
  const project = useOptionalProject();
  const contentTopic = useContext(ContentTopicContext);
  const employee = useOptionalEmployee();
  const [newOptions, setNewOptions] = useState<string[]>([]);
  const fetcher = useFetcherData<DocumentPartiesQuery>(
    project || employee || contentTopic
      ? "/resources/documents/parties"
      : undefined,
    {
      projectId: project?.id,
      employeeId: employee?.id,
      employeeScope: employee?.scope,
      contentTopicId: contentTopic?.id
    }
  );

  const handleCreate = (option: string) => {
    if (
      option &&
      !(fetcher.data?.documentParties || []).includes(option) &&
      !newOptions.includes(option)
    ) {
      setNewOptions([...newOptions, option]);
    }
  };

  const options = useMemo(
    () =>
      _.sortBy(fetcher.data?.documentParties.concat(newOptions)).map((dp) => ({
        value: dp,
        label: dp
      })),
    [fetcher.data, newOptions]
  );

  return (
    <ComboBox
      {...props}
      creatable
      onCreateOption={handleCreate}
      options={options}
      placeholder="N/A"
    />
  );
}
