import type { GroupedComboBoxOption } from "~/components/combobox";

export const OPENAI_KEY =
  "sk-proj-jL1tIctntcc1rPHRPwuwT3BlbkFJ8fC8UQ03iP2zfyxfPhoE";

export const SOCKET_URL = import.meta.env.PROD
  ? "wss://api.petefowler.com/socket"
  : "ws://127.0.0.1:4001/socket";
export const BANK_ACCOUNTS = [
  ["Advanced Analysis", "Advanced Analysis"],
  ["Commerce West (Primary Account - Credit Cards & ACH)", "Commerce West"],
  [
    "Commerce West (Deposits Account - Mobile Deposits, Checks, and ACH)",
    "Commerce West (Checks)"
  ],
  ["NW Bank", "NW Bank"],
  ["Wells Fargo", "Wells Fargo"]
];
export const BILLING_POSITIONS = [
  "Architect",
  "Assistant Consultant",
  "Construction Analyst",
  "Construction Manager",
  "Engineer",
  "Expert",
  "Principal",
  "Senior Consultant",
  "Overhead"
];

export const ENTITIES = [
  { value: "AA", label: "Advanced Analysis" },
  { value: "CI", label: "Cramer Investigations" },
  { value: "GCG", label: "Gifford Consulting Group" },
  { value: "MCV", label: "Marc C. Viau & Associates" },
  { value: "PFCS", label: "PFCS" }
];

export const ELEMENT_GROUPS = {
  A: "Substructure",
  B: "Superstructure",
  C: "Interiors",
  D: "Services",
  E: "Equipment and Furnishings",
  F: "Special Construction and Demolition",
  G: "Building Sitework",
  H: "Other"
};

export const STAFF_SECTIONS = [
  "Project Management Team",
  "Technical Team",
  "Technical Team: Experts",
  "Technical Team: Fire",
  "Flex Technical Team",
  "Overhead Team",
  "M&S Team"
];

// TODO: Use the database
export const POSITIONS = [
  ["senior_consultant", "Senior Consultant"],
  ["expert", "Expert"],
  ["engineer", "Engineer"],
  ["assistant_consultant", "Assistant Consultant"],
  ["architect", "Architect"],
  ["construction_analyst", "Construction Analyst"],
  ["construction_manager", "Construction Manager"],
  ["testimony", "Testimony"],
  ["principal", "Principal"],
  ["technical_consultant", "Technical Consultant"],
  ["project_manager", "Project Manager"],
  ["project_coordinator", "Project Coordinator"],
  ["project_assistant", "Project Assistant"],
  ["technical_analyst", "Technical Analyst"],
  ["technical_assistant", "Technical Assistant"],
  ["overhead", "Overhead"],
  ["content_coordinator", "Content Coordinator"],
  ["campaign_coordinator", "Campaign Coordinator"],
  ["campaign_manager", "Campaign Manager"],
  ["account_executive", "Account Executive (Sales)"],
  ["contact_management_coordinator", "Contact Management Coordinator"],
  ["digital_marketing_specialist", "Digital Marketing Specialist"],
  ["marketing_manager", "Marketing Manager"]
];

export const OUTDATED_EXPENSE_TYPES = [
  { label: "Travel: Mileage", value: "Mileage" },
  { label: "Travel: Other (Parking, Tolls, etc.)", value: "Travel" },
  { label: "Project Expense", value: "Project Expense" },
  { label: "Equipment", value: "Equipment" },
  { label: "Publications & Subscriptions", value: "Publications" },
  { label: "Office Supplies", value: "Office Supplies" },
  { label: "Tools", value: "Tools" },
  { label: "Telephone", value: "Telephone" },
  { label: "Training & Education", value: "Training" },
  { label: "Miscellaneous / Other", value: "Miscellaneous" }
];

export const EXPENSE_TYPES: GroupedComboBoxOption[] = [
  {
    label: "Project Expenses",
    options: [
      { label: "Mileage", value: "Project Travel & Admin: Mileage" },
      {
        label: "Travel & Other Project Expenses",
        subtitle:
          "Any other expenses related to a project. Travel includes meals while traveling, hotels, car rental, and airfare.",
        value: "Project Travel & Admin"
      }
    ]
  },
  {
    label: "Administrative/Overhead Expenses",
    options: [
      { label: "Licenses & Memberships", value: "Licenses & Memberships" },
      {
        label: "Meals/Entertainment for Staff",
        subtitle: "Working lunches, group outings",
        value: "Employee Perks, Meals & Entertainment"
      },
      { label: "Mileage", value: "Corporate Travel: Mileage" },
      {
        label: "Office Expenses",
        subtitle: "Supplies, postage & shipping, etc.",
        value: "Office Expenses"
      },
      {
        label: "Travel",
        subtitle:
          "Travel other than Project/M&S. Includes meals while traveling, hotels, car rental, and airfare.",
        value: "Corporate Travel"
      },
      { label: "All Other Admin Expenses", value: "Other Costs" }
    ]
  },
  {
    label: "Marketing & Sales Expenses",
    options: [
      { label: "Advertising", value: "Advertising" },
      {
        label: "Events & Travel",
        subtitle:
          "Includes any costs related to marketing events. Travel includes meals while traveling, hotels, car rental, and airfare.",
        value: "Marketing Events & Travel"
      },
      { label: "Meals w- Prospects/Clients", value: "Marketing Meals" },
      { label: "Mileage", value: "Marketing Events & Travel: Mileage" },
      { label: "All Other M&S Expenses", value: "Marketing Administration" }
    ]
  }
];

export const EXPENSE_TYPES_WITH_OUTDATED = [
  ...EXPENSE_TYPES,
  { label: "OLD CATEGORIES - DO NOT USE", options: OUTDATED_EXPENSE_TYPES }
];

type ContactTypeOption = { sales?: boolean; employees?: boolean };

export const CONTACT_TYPES: [string, string, ContactTypeOption?][] = [
  ["Internal Note", "Internal Note"],
  ["Employee Coaching", "Employee Coaching", { employees: true }],
  ["Call - Dial", "Call - Dial"],
  ["Call - Voicemail", "Call - Voicemail"],
  ["Call - Conversation", "Call - Conversation"],
  ["Networking - Conversation", "Networking - Conversation"],
  ["Text Message", "Text Message"],
  ["Email", "Email"],
  ["Positive Response Received", "Positive Response Received", { sales: true }],
  ["Negative Response Received", "Negative Response Received", { sales: true }],
  ["LinkedIn Connection", "LinkedIn Connection", { sales: true }],
  ["LinkedIn InMail", "LinkedIn InMail", { sales: true }],
  ["US Mail", "US Mail (Letter, Handwritten Note, etc.)"],
  ["Appointment", "Coordinated an Expert Meeting/Presentation w- Prospect"],
  ["Meeting", "Attended Meeting"],
  ["Prospect Meeting (Expert)", "Attended Meeting w- Prospect (Expert)"],
  ["Prospect Meeting", "Attended Meeting w- Prospect (Sales)"],
  [
    "In-house Presentation to Strategic Target",
    "Delivered In-house Presentation to Strategic Target"
  ],
  ["Referral", "Referral"],
  ["Referral - Warm", "Referral - Warm"],
  ["Other", "Other"]
];

// TODO: Get rid of this
export const PRACTICE_AREAS = [
  {
    value: "BIT",
    label: "Building Inspection & Testing",
    short: "Inspection & Testing",
    mode: "Project"
  },
  {
    value: "EQCM",
    label: "Estimating, Quality & Construction Management",
    short: "Estimating, Quality, CM",
    mode: "Project"
  },
  {
    value: "FIRE",
    label: "Fire Claims",
    short: "Fire Claims",
    mode: "Project"
  },
  {
    value: "PC",
    label: "Property Claims",
    short: "Property Claims",
    mode: "Project"
  },
  {
    value: "IC",
    label: "Injury Claims",
    short: "Injury Claims",
    mode: "Project"
  },
  {
    value: "CDL",
    label: "Construction Defect Litigation",
    short: "Construction Defect",
    mode: "Project"
  },
  {
    value: "CCC",
    label: "Construction Contract Claims",
    short: "Contract Claims",
    mode: "Project"
  },
  {
    value: "CS",
    label: "Consulting Services",
    short: "Consulting Services",
    mode: "Project"
  },
  {
    value: "PIC",
    label: "Property & Injury Claims (DO NOT USE)",
    short: "Property & Injury (DO NOT USE)",
    mode: "Project"
  },

  {
    value: "Overhead",
    label: "Standard Overhead Project",
    short: "Overhead",
    subtitle: "Used to manage a set of activities internally.",
    mode: "Overhead"
  },
  {
    value: "Overhead: Training Project",
    label: "Training Project",
    subtitle:
      "Behaves like a billable project without actually being billed, used for training purposes",
    short: "Overhead: Training Project",
    mode: "Overhead"
  }
];

export const AREAS = [
  "Area: AZ",
  "Area: CO",
  "Area: IE",
  "Area: LA",
  "Area: OC",
  "Area: Other (CA)",
  "Area: Other (Not CA)",
  "Area: NW",
  "Area: SD"
];

export const US_STATES = [
  ["Alabama", "AL"],
  ["Alaska", "AK"],
  ["Arizona", "AZ"],
  ["Arkansas", "AR"],
  ["California", "CA"],
  ["Colorado", "CO"],
  ["Connecticut", "CT"],
  ["Delaware", "DE"],
  ["District of Columbia", "DC"],
  ["Florida", "FL"],
  ["Georgia", "GA"],
  ["Hawaii", "HI"],
  ["Idaho", "ID"],
  ["Illinois", "IL"],
  ["Indiana", "IN"],
  ["Iowa", "IA"],
  ["Kansas", "KS"],
  ["Kentucky", "KY"],
  ["Louisiana", "LA"],
  ["Maine", "ME"],
  ["Maryland", "MD"],
  ["Massachusetts", "MA"],
  ["Michigan", "MI"],
  ["Minnesota", "MN"],
  ["Mississippi", "MS"],
  ["Missouri", "MO"],
  ["Montana", "MT"],
  ["Nebraska", "NE"],
  ["Nevada", "NV"],
  ["New Hampshire", "NH"],
  ["New Jersey", "NJ"],
  ["New Mexico", "NM"],
  ["New York", "NY"],
  ["North Carolina", "NC"],
  ["North Dakota", "ND"],
  ["Ohio", "OH"],
  ["Oklahoma", "OK"],
  ["Oregon", "OR"],
  ["Pennsylvania", "PA"],
  ["Puerto Rico", "PR"],
  ["Rhode Island", "RI"],
  ["South Carolina", "SC"],
  ["South Dakota", "SD"],
  ["Tennessee", "TN"],
  ["Texas", "TX"],
  ["Utah", "UT"],
  ["Vermont", "VT"],
  ["Virginia", "VA"],
  ["Washington", "WA"],
  ["West Virginia", "WV"],
  ["Wisconsin", "WI"],
  ["Wyoming", "WY"]
];

export const US_STATES_PRIORITIZED: {
  [key: string]: string[][];
  "Common States": string[][];
  "Other States": string[][];
} = {
  "Common States": [
    ["Arizona", "AZ"],
    ["California", "CA"],
    ["Florida", "FL"],
    ["Nevada", "NV"],
    ["Oregon", "OR"],
    ["Texas", "TX"]
  ],
  "Other States": US_STATES.filter(
    (s) => !["AZ", "CA", "FL", "NV", "OR", "TX"].includes(s[1])
  ).concat([["Other/Non-U.S.", "Other"]])
};

export const FileTOC = () => (
  <div className="slate-editor readonly">
    <ol className="pl-8">
      <li>Correspondence</li>
      <li>
        PFCS Work
        <ol>
          <li>PFCS Reports &amp; Issues Lists</li>
          <li>Estimates</li>
          <li>Investigation &amp; Testing</li>
          <li>Meetings, Planning, &amp; Consultant Information</li>
          <li>Analysis</li>
          <li>Research</li>
          <li>Invoices &amp; Payments</li>
          <li>Sample Work</li>
        </ol>
      </li>
      <li>
        Client / Insured
        <ol>
          <li>
            Job File Documents: Documents produced by this party (Contracts,
            Work Orders, Change Orders, etc.)
          </li>
          <li>
            Legal Information: Documents produced by this party&apos;s counsel
            (Demands, Complaints, Cross Complaints, CMO, Interrogatories, etc.)
          </li>
          <li>
            Expert Data: Documents produced by this party&apos;s experts,
            excluding PFCS (Reports, Defect Lists, Estimates, etc.)
          </li>
        </ol>
      </li>
      <li>
        Owner / Plaintiff / Occupant (If this is our client, refer to Section 3,
        this section empty)
        <ol>
          <li>
            Job File Documents: Documents produced by this party (Contracts,
            Work Orders, Change Orders, etc.).
          </li>
          <li>
            Legal Information: Documents produced by this party&apos;s counsel
            (Demands, Complaints, Cross Complaints, CMO, Interrogatories, etc.)
          </li>
          <li>
            Expert Data: Documents produced by this party&apos;s experts
            (Reports, Defect Lists, Estimates, etc.)
          </li>
        </ol>
      </li>
      <li>
        Builder / General Contractor / Developer (If this is our client, refer
        to section 3, this section empty)
        <ol>
          <li>
            Job File Documents: Documents produced by this party (Contracts,
            Work Orders, Change Orders, etc.)
          </li>
          <li>
            Legal Information: Documents produced by this party&apos;s counsel
            (Demands, Complaints, Cross Complaints, CMO, Interrogatories, etc.)
          </li>
          <li>
            Expert Data: Documents produced by this party&apos;s experts
            (Reports, Defect Lists, Estimates, etc.)
          </li>
        </ol>
      </li>
      <li>
        Other Parties including Designers, Trade Contractors (This section is
        organized by party. The first word of the company&apos;s name is used as
        the suffix to group these documents together. Example
        &quot;6-Aspen&quot; or &quot;6-Forensic&quot;.)
      </li>
      <li>
        Depositions (This section is organized by party. The deponent&apos;s
        last name is used as the suffix to group these documents together.
        Example &quot;7-Smith&quot;.)
      </li>
    </ol>
  </div>
);

export const SPECIFICATIONS_FIELDS = [
  "General",
  "Materials",
  "Execution",
  "Quality Assurance"
] as const;

export const IIACC_FIELDS = [
  "Issue",
  "Investigation",
  "Analysis",
  "Conclusion",
  "Costs"
] as const;

export const IIACC = {
  issue: (
    <>
      One sentence to one paragraph. Describe the issue in English, so everyone
      who needs to use the information to make a decision can understand.
      Describe the location.
      <br />
      1. What is the allegation?
      <br />
      2. How does it relate to our party?
      <br />
      <br />
      <em>
        Make sure to reference any source documents including page numbers
      </em>
    </>
  ),
  investigation: (
    <>
      What have we done to figure out how the assembly is performing? Use the
      dropdown for a sensible list. Data Collection: PLTF/Owner, DEV/GC, PFCS.
      <br />
      3. Where and how many times is it alleged to be an issue?
      <br />
      4. What do the parties say about the issue including cost?
      <br />
      5. Did we see the issue?
      <br />* Project specific documents, not codes, standards, and general
      industry information unless referred to by others.
      <br />* Consider copy and paste the exact text from reports by others,
      verbatim.
      <br />
      <br />
      <em>
        Make sure to reference any source documents including page numbers
      </em>
    </>
  ),
  analysis: (
    <>
      What should be considered? Codes; Standards; Design intent; Maintenance
      manual; Performance; Aesthetics.
      <br />
      Data Analysis: Information, what does the data mean?
      <br />
      Respond to the investigation section above, as necessary.
      <br />
      6. Is the assembly performing as the owner might hope?
      <br />
      7. Is the assembly performing as we might expect?
      <br />
      8. Is it a defect?
      <br />
      9. Is it causing damage?
      <br />
      11. What are the applicable standards?
      <br />
      <br />
      <em>
        Make sure to reference any source documents including page numbers
      </em>
    </>
  ),
  conclusion: (
    <>
      What do we think? Should we consider politics?
      <br />
      10. Is a repair required?
      <br />
      12. What is a reasonable repair?
      <br />
      14. Who is responsible?
      <br />
      <br />
      <em>
        Make sure to reference any source documents including page numbers
      </em>
    </>
  ),
  costs: (
    <>
      What do all of the parties think it is going to cost to fix?
      <br />
      13. How much will the repair cost?
      <br />
      <br />
      <em>
        Make sure to reference any source documents including page numbers
      </em>
    </>
  )
};

export const MACKAY_66 =
  "Mackay 66 Customer Profile:\nNickname: \nBirth Date: \nBirth Place: \nHometown: \nEducation: \nSpouse's Name: \nChildren Names/Ages: \nBusiness Background/Previous Employment: \nSpecial Interests: \nDo they drink, what, how much: \nHobbies and Recreation: \nConversational Interests: \n\nWhat are the key problems as the customer sees them? \nWhat are the priorities of the customer's management?";

export const IMAGE_TYPES = [
  "Area",
  "Vicinity",
  "Site",
  "Roof",
  "Elevation",
  "Plan",
  "Section",
  "Interior",
  "Detail",
  "Specification",
  "Other"
];

export const PRIORITIES = ["A", "B", "C"];

export const FEATURE_CATEGORIES = [
  "Calendar",
  "Campaigns",
  "Content & Collateral",
  "Client Access",
  "Contacts",
  "Dashboards",
  "Invoices",
  "iPhone App",
  "Kanban/Actions",
  "Library",
  "Management",
  "Marketing & Sales",
  "Notes",
  "Other",
  "PFCS.co",
  "Projects: Addresses/Locations",
  "Projects: Correspondence",
  "Projects: Dispatches",
  "Projects: Documents",
  "Projects: Elements",
  "Projects: General",
  "Projects: Images",
  "Projects: Issues",
  "Projects: Meetings & Interview",
  "Projects: Other",
  "Projects: Photos",
  "Projects: Planning",
  "Projects: Players",
  "Projects: Proposals",
  "Projects: Reports",
  "Projects: Test Locations",
  "Projects: Timelines",
  "Public Website",
  "Tasks",
  "Timesheets",
  "Training"
];

export const REPORT_STATUS_OPTIONS = [
  "In Progress",
  "Needs Peer Review",
  "Complete, Not Published",
  "Abandoned",
  "Published"
];

export const LICENSED_STATES = [
  ["California", "CA"],
  ["Florida", "FL"],
  ["Nevada", "NV"],
  ["Oregon", "OR"]
];

export const SOCIAL_MEDIA_POST_STATUSES = [
  ["Need Social Media for this account/day", "Need"],
  ["In Progress", "In Progress"],
  ["Ready for TC Review", "TC Review"],
  ["Needs Approval", "Needs Approval"],
  ["Ready to Post", "Ready"],
  ["Successfully Scheduled", "Scheduled"],
  ["Successfully Posted", "Done"],
  ["Didn't post anything in time", "Missed"]
];

export const ISSUE_STATUSES = ["Not Started", "In Process", "Complete"];

export const ANALYSIS_STATUSES = [
  "Started",
  "L1",
  "L2",
  "L3",
  "L4",
  "L5",
  "Not Started"
];

export const DEFAULT_INITIAL_INFO = [
  {
    type: "paragraph",
    children: [{ text: "Issue(s) Related to our Client (what happened):" }]
  },
  {
    type: "unordered-list",
    children: [
      {
        type: "list-item",
        children: [{ type: "list-item-child", children: [{ text: "" }] }]
      },
      {
        type: "list-item",
        children: [{ type: "list-item-child", children: [{ text: "" }] }]
      },
      {
        type: "list-item",
        children: [{ type: "list-item-child", children: [{ text: "" }] }]
      }
    ]
  },
  { type: "paragraph", children: [{ text: "Key Players:" }] },
  {
    type: "unordered-list",
    children: [
      {
        type: "list-item",
        children: [{ type: "list-item-child", children: [{ text: "" }] }]
      },
      {
        type: "list-item",
        children: [{ type: "list-item-child", children: [{ text: "" }] }]
      },
      {
        type: "list-item",
        children: [{ type: "list-item-child", children: [{ text: "" }] }]
      }
    ]
  },
  { type: "paragraph", children: [{ text: "Upcoming Dates:" }] },
  {
    type: "unordered-list",
    children: [
      {
        type: "list-item",
        children: [{ type: "list-item-child", children: [{ text: "" }] }]
      },
      {
        type: "list-item",
        children: [{ type: "list-item-child", children: [{ text: "" }] }]
      },
      {
        type: "list-item",
        children: [{ type: "list-item-child", children: [{ text: "" }] }]
      }
    ]
  }
];

export const DEFAULT_BILLING_SUMMARY =
  "- Invoices approved by: (NAME/COMPANY; File No.: ______)\n- Invoices paid by: (NAME/COMPANY; File No.: ______)\n- Notes:";

export const SALES_LOG_TYPES = [
  ["New Contacts", "New Contact"],
  ["Updated Contacts", "Updated Contact"],
  ["Promoted to Prospect", "Promoted to Prospect"]
];

export const MARKETING_SALES_POSITIONS = [
  "Account Executive (Sales)",
  "Marketing Manager",
  "Campaign Manager",
  "Campaign Coordinator",
  "Content Coordinator",
  "Contact Management Coordinator",
  "Digital Marketing Specialist"
];
