import clsx from "clsx";
import _ from "lodash";
import { Element } from "slate";
import type { CustomElement, CustomText } from "~/components/rich-editor";
import { formatNumber } from "~/utils/formatting";

interface Props {
  text?: string | (CustomElement | CustomText)[] | null;
  longWarning?: boolean;
  className?: string;
}

const parseSlate = (nodes: (CustomElement | CustomText)[]) => {
  const parts: string[] = [];
  for (const node of nodes) {
    if (Element.isElement(node)) {
      parts.push(parseSlate(node.children));
      parts.push(" ");
    } else {
      parts.push(node.text as string);
    }
  }
  return parts.join("");
};

export default function WordCounter({
  text: _text,
  longWarning = true,
  className = ""
}: Props) {
  const text = _.isString(_text)
    ? parseSlate(JSON.parse(_text) as CustomElement[])
    : !_text
      ? ""
      : parseSlate(_text);
  const count = (text || "")
    .trim()
    .split(/[\s.?]+(?!$)/)
    .filter(Boolean).length;
  return (
    <span
      className={clsx(className, count > 250 && longWarning && "text-danger")}
    >
      Word Count: {formatNumber(count, { zeroes: true, format: "0,0" })}
    </span>
  );
}
