import clsx from "clsx";
import { type ReactNode, useCallback, useMemo } from "react";
import { NumberedTableContext } from "~/contexts";

type Props = {
  className?: string;
  hover?: boolean;
  children: ReactNode;
  onSort?: (value: string, shift?: boolean) => void;
};

export default function Table({
  children,
  className,
  onSort,
  hover = true
}: Props) {
  const handler = useCallback(
    (value: string, shift?: boolean) => {
      onSort?.(value, shift);
    },
    [onSort]
  );

  const value = useMemo(() => ({ onSort: handler }), [handler]);

  return (
    <NumberedTableContext.Provider value={value}>
      <table
        className={clsx(
          "table-condensed table",
          hover && "table-hover",
          className
        )}
      >
        {children}
      </table>
    </NumberedTableContext.Provider>
  );
}
