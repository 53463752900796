import SparkMD5 from "spark-md5";
import xxhash from "xxhash-wasm";

export const getMD5Async = (
  file: File,
  progress?: (file: File, percent: number) => void
) => {
  return new Promise<string>((resolve) => {
    const chunkSize = 2097152;
    const chunks = Math.ceil(file.size / chunkSize);
    let currentChunk = 0;
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();
    let previousPercent = 0;

    const loadNext = (): void => {
      const start = currentChunk * chunkSize;
      const end =
        start + chunkSize >= file.size ? file.size : start + chunkSize;
      fileReader.readAsArrayBuffer(File.prototype.slice.call(file, start, end));
    };

    fileReader.onload = (e) => {
      if (progress) {
        let percent: number;
        if (chunks === currentChunk + 1) {
          percent = 100;
        } else {
          percent = (currentChunk * chunkSize) / file.size;
          percent = Math.ceil(percent * 100);
        }
        if (percent - previousPercent >= 10) {
          progress(file, percent);
          previousPercent = percent;
        }
      }

      spark.append(e.target!.result as ArrayBuffer);
      currentChunk++;
      if (currentChunk < chunks) {
        loadNext();
      } else {
        resolve(spark.end());
      }
    };

    loadNext();
  });
};

/**
 * Generates a hash using the xxHash algorith, which is an
 * order of magnitude faster than MD5 and SHA1.
 *
 * @param file File object to generate hash from
 * @returns Promise that resolves to the 32-bit hash
 */
export const getFileHash = async (file: File) => {
  // biome-ignore lint/suspicious/noAsyncPromiseExecutor: <explanation>
  return new Promise<string>(async (res) => {
    const chunkSize = 2097152;
    const chunks = Math.ceil(file.size / chunkSize);
    let currentChunk = 0;
    const { create64 } = await xxhash();
    const fileReader = new FileReader();
    const hash = create64();

    const loadNext = (): void => {
      const start = currentChunk * chunkSize;
      const end =
        start + chunkSize >= file.size ? file.size : start + chunkSize;
      fileReader.readAsArrayBuffer(File.prototype.slice.call(file, start, end));
    };

    fileReader.onload = (e) => {
      hash.update(new Uint8Array(e.target!.result as ArrayBuffer));
      currentChunk++;
      if (currentChunk < chunks) {
        loadNext();
      } else {
        res(hash.digest().toString(16));
      }
    };

    loadNext();
  });
};
