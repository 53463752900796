import type { ReactNode } from "react";
import type React from "react";
import { useEffect, useRef, useState } from "react";
import Button from "~/components/button";
import ButtonCloseModal from "~/components/button-close-modal";
import LibraryList from "~/components/documents/library-list";
import { IconCheck, IconStar } from "~/components/icons";
import InputGroup from "~/components/input-group";
import type { ModalProps } from "~/components/modal";
import Modal from "~/components/modal";
import type { DocumentRowFieldsFragment } from "~/types/api";

interface Props extends ModalProps {
  onChange?: (document: DocumentRowFieldsFragment) => void;
  thumbnails?: boolean;
  addedIds?: string[];
  selectedIds?: string[];
  setSelectedIds?: (ids: string[]) => void;
  children?: ReactNode;
}

export default function LibraryPicker({
  onClose,
  onChange,
  thumbnails,
  addedIds,
  selectedIds,
  setSelectedIds,
  children
}: Props) {
  const [query, setQuery] = useState("");
  const [fullQuery, setFullQuery] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const t = window.setTimeout(() => inputRef.current?.focus(), 50);
    return () => {
      t && window.clearTimeout(t);
    };
  }, []);

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setKey(false);
    setFullQuery(query);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };
  const [key, setKey] = useState(false);
  return (
    <Modal onClose={onClose} size="Large">
      <Modal.Header title="Add Library File" />
      <Modal.Body>
        <form onSubmit={submit} autoComplete="off">
          <div className="mb-4 flex items-center space-x-4">
            <InputGroup
              className="flex-1"
              buttons={<Button type="submit">Search</Button>}
            >
              <input
                type="text"
                ref={inputRef}
                value={query}
                onChange={handleChange}
                className="form-control"
                name="query"
              />
            </InputGroup>
            {fullQuery && (
              <Button onClick={() => setKey(!key)}>
                {key ? <IconCheck /> : <IconStar />} Key Files
              </Button>
            )}
          </div>
        </form>
      </Modal.Body>
      {fullQuery ? (
        <LibraryList
          addedIds={addedIds}
          thumbnails={thumbnails}
          onChange={onChange}
          keyFiles={key}
          query={fullQuery}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
        />
      ) : null}
      <Modal.Footer>
        <ButtonCloseModal />
        {children}
      </Modal.Footer>
    </Modal>
  );
}
