import Alert from "~/components/alert";
import Button from "~/components/button";
import {
  IconCheck,
  IconCheckedCircle,
  IconCircle,
  IconTrash
} from "~/components/icons";
import { Input } from "~/components/remix-form";
import Cell from "~/components/table-cell";
import Row from "~/components/table-row";
import { PRIORITIES } from "~/constants";
import type { TaskInput } from "~/types/api";

interface Props {
  tasks: TaskInput[];
  hideHours?: boolean;
  tight?: boolean;
  onDelete: (task: TaskInput) => void;
  onChange: (task: TaskInput, name: string, value: string) => void;
  onToggle: (task: TaskInput) => void;
}

export default function InlineTaskFieldsNext({
  tasks,
  onDelete,
  onChange,
  onToggle,
  hideHours,
  tight
}: Props) {
  return (
    <>
      {tasks.filter((t) => !t.delete).length === 0 ? (
        <Alert mode="warning" style={{ margin: "0 15px 9px" }}>
          No Actions
        </Alert>
      ) : (
        <>
          <div className="nested-flex-rows mb-4">
            <Row className="flex-row-header font-bold">
              <Cell size="40" className="text-center">
                <IconCheck />
              </Cell>
              <Cell>Description</Cell>
              <Cell className="text-center" size="160">
                Who
              </Cell>
              <Cell className="text-center" size="100">
                Due
              </Cell>
              <Cell className="text-center" size="80">
                Priority
              </Cell>
              <Cell className="text-center" size="60">
                Hours
              </Cell>
              <Cell size="60" />
            </Row>

            {tasks
              .filter((t) => !t.delete)
              .map((task, index) => (
                <TaskRow
                  task={task}
                  index={index}
                  key={task.uuid}
                  tight={tight}
                  hideHours={hideHours}
                  onDelete={onDelete}
                  onChange={onChange}
                  onToggle={onToggle}
                />
              ))}
          </div>
        </>
      )}
    </>
  );
}

type RowProps = {
  task: TaskInput;
  index: number;
  tight?: boolean;
  hideHours?: boolean;
  onDelete: (task: TaskInput) => void;
  onChange: (task: TaskInput, name: string, value: string) => void;
  onToggle: (task: TaskInput) => void;
};

const TaskRow = ({
  task,
  index,
  tight,
  hideHours,
  onChange,
  onDelete,
  onToggle
}: RowProps) => {
  const deliverableField = task.projectId ? (
    <Cell>
      <Input
        wrapperStyle={{ marginBottom: 0 }}
        name={`tasks.${index}.deliverableId`}
        value={task.deliverableId}
        onChange={(_name, value) => onChange(task, "deliverableId", value)}
        type="deliverable"
        projectId={task.projectId}
        noLabel
      />
    </Cell>
  ) : null;

  const cellClass = tight ? "flex-cell-field" : "";

  return (
    <Row>
      <Cell
        size="40"
        className="text-center"
        style={{ cursor: "pointer", paddingTop: 10 }}
        onClick={() => onToggle(task)}
      >
        {task.completed ? (
          <IconCheckedCircle large className="text-muted" />
        ) : (
          <IconCircle large />
        )}
      </Cell>
      <Cell className={cellClass}>
        <Input
          wrapperStyle={{ marginBottom: 0 }}
          name={`tasks.${index}.description`}
          value={task.description || ""}
          onChange={(e) => onChange(task, "description", e.target.value)}
          type="textarea"
          minRows={1}
          noLabel
        />
      </Cell>
      {deliverableField}
      <Cell className={cellClass} size="160">
        <Input
          wrapperStyle={{ marginBottom: 0 }}
          noLabel
          name={`tasks.${index}.userId`}
          type="user"
          value={task.userId}
          onChange={(_name, value) => onChange(task, "userId", value)}
          placeholder="Who?"
        />
      </Cell>
      <Cell className={cellClass} size="100">
        <Input
          wrapperStyle={{ marginBottom: 0 }}
          noLabel
          type="date"
          value={task.itemDate || ""}
          onChange={(_name, value) =>
            onChange(task, "itemDate", value as string)
          }
          name={`tasks.${index}.itemDate`}
          className="text-center"
        />
      </Cell>
      <Cell className={cellClass} size="80">
        <Input
          wrapperStyle={{ marginBottom: 0 }}
          name={`tasks.${index}.priority`}
          noLabel
          type="combo"
          options={PRIORITIES}
          value={task.priority}
          onChange={(_name, value) => onChange(task, "priority", value)}
          placeholder=""
          isClearable={false}
        />
      </Cell>
      {!hideHours && (
        <Cell className={cellClass} size="60">
          <Input
            wrapperStyle={{ marginBottom: 0 }}
            name={`tasks.${index}.hours`}
            value={task.hours || ""}
            onChange={(e) => onChange(task, "hours", e.target.value)}
            className="text-center"
            noLabel
          />
        </Cell>
      )}
      <Cell size="60" className="text-right">
        <Button mode="danger" onClick={() => onDelete(task)}>
          <IconTrash />
        </Button>
      </Cell>
    </Row>
  );
};
