import { AbilityBuilder, createMongoAbility } from "@casl/ability";
import type { CurrentUserFieldsFragment } from "~/types/api";

export function defineAbility(user: CurrentUserFieldsFragment) {
  const { can, cannot, build } = new AbilityBuilder(createMongoAbility);

  can("kick", "it");
  can("push", "it");
  if (user.login === "DM") {
    cannot("push", "it").because("it's only for kicking");
  }

  return build();
}
