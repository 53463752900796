import _ from "lodash";
import type { IssueFieldsFragment } from "~/types/api";
import type { Flattened } from "~/utils/bpa";
import { normalizedNumber } from "~/utils/formatting";

export default class IssueParser {
  static from = (list: IssueFieldsFragment[]) => {
    return new IssueParser(list);
  };

  list: IssueFieldsFragment[];

  constructor(list: IssueFieldsFragment[]) {
    this.list = list;
  }

  sortedList(): IssueFieldsFragment[] {
    return this.byIssue();
  }

  byIssue(): IssueFieldsFragment[] {
    const sorted = _.sortBy(
      this.list,
      (i) => normalizedNumber(i.number),
      "alternateNumber",
      "description"
    );
    return sorted;
  }

  byElement(): Record<string, Flattened<IssueFieldsFragment>[]> {
    const result: Record<string, Flattened<IssueFieldsFragment>[]> = {};
    _.sortBy(
      this.list,
      (i) => normalizedNumber(i.number),
      "alternateNumber",
      "description"
    ).forEach((issue) => {
      const element =
        issue.elements.length === 0
          ? "Issues Without Elements"
          : `${issue.elements[0].number} ${issue.elements[0].name}`;
      if (!result[element]) {
        result[element] = [];
      }
      result[element].push({ ...issue, indent: 0 });
    });
    return result;
  }
}
