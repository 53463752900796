import _ from "lodash";
import { useMemo } from "react";
import type { ComboBoxParentProps } from "~/components/combobox";
import ComboBox from "~/components/combobox";
import { useProject } from "~/contexts";
import type { PlayerGroupsQuery } from "~/types/api";
import { useFetcherData } from "~/utils/remix";

export default function PlayerGroupPicker(rest: ComboBoxParentProps) {
  const project = useProject();
  const fetcher = useFetcherData<PlayerGroupsQuery>(
    "/resources/players/group-picker",
    { projectId: project.id }
  );

  const options = useMemo(() => {
    return _.sortBy(fetcher.data?.project.playerGroups || [], "position").map(
      (pg) => ({
        value: pg.id,
        label: pg.name
      })
    );
  }, [fetcher.data]);

  return <ComboBox {...rest} options={options} />;
}
