import _ from "lodash";
import { useMemo } from "react";
import type {
  ComboBoxParentPropsMultiple,
  ComboBoxParentPropsSingle
} from "~/components/combobox";
import ComboBox from "~/components/combobox";
import { TAG_SECTIONS } from "~/components/tags/list";
import type { TagsQuery } from "~/types/api";
import { useFetcherData } from "~/utils/remix";

export type TagPickerSingleProps = ComboBoxParentPropsSingle & {
  sections?: string[];
  scope?: string;
};
export type TagPickerMultiProps = ComboBoxParentPropsMultiple & {
  sections?: string[];
  scope?: string;
};

export default function TagPicker({
  sections,
  scope,
  ...props
}: TagPickerSingleProps | TagPickerMultiProps) {
  const fetcher = useFetcherData<TagsQuery>("/resources/tags/picker", {
    scope
  });

  const options = useMemo(() => {
    let all = _.sortBy(fetcher.data?.tags || [], "category");
    all = sections?.length
      ? all.filter((t) => sections.includes(t.section))
      : all;

    const areaSections = _.groupBy(
      all.filter((t) => t.section === "Area"),
      (t) => t.subsection || "No Region Assigned"
    );
    const areas = Object.keys(areaSections).sort();
    const opts = areas.map((area) => ({
      label: area,
      options: areaSections[area].map((t) => ({
        value: t.id,
        label: t.category
      }))
    }));

    const tagSections = _.uniq([
      ..._.without(TAG_SECTIONS, "Area"),
      ...(fetcher.data?.tags || []).map((t) => t.section).sort()
    ]);

    return opts.concat(
      _.without(tagSections, "Area").map((section) => {
        return {
          label: section,
          options: all
            .filter((t) => t.section === section)
            .map((t) => ({
              value: t.id,
              label: t.category
            }))
        };
      })
    );
  }, [sections, fetcher.data]);

  return (
    <ComboBox
      placeholder={props.multiple ? "Select some Tags..." : "Select a Tag..."}
      {...props}
      options={options}
    />
  );
}
