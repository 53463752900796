import clsx from "clsx";
import { IconLoading } from "~/components/icons";
import ProgressBar from "~/components/progress-bar";
import type { PileFieldsFragment } from "~/types/api";
import { plural } from "~/utils/formatting";

type Props = { pile: PileFieldsFragment; tight?: boolean };

export default function PileProgress({ pile, tight }: Props) {
  const progress =
    (pile.fileCount - pile.pendingFileCount) / (pile.fileCount || 1);

  return (
    <div
      className={clsx(
        "flex flex-wrap items-center gap-2",
        tight ? "justify-between" : "justify-end"
      )}
    >
      {pile.legacy ? (
        <div>Moved from Z:\</div>
      ) : (
        <div className={tight ? "w-[100px]" : "w-[200px]"}>
          <ProgressBar percentage={progress * 100} />
        </div>
      )}
      <div>
        {plural("Folder", pile.folderCount, true)} •{" "}
        {plural("File", pile.fileCount, true)} •{" "}
        {plural("Page", pile.pageCount, true)}
        {pile.processingCount > 0 && (
          <>
            {" "}
            • <IconLoading /> Still Processing:{" "}
            {plural("File", pile.processingCount, true)}
          </>
        )}{" "}
        •{" "}
        {pile.legacy ? (
          <span>Progress Unknown</span>
        ) : (
          <span>
            {pile.fileCount - pile.pendingFileCount}/{pile.fileCount} Files
            Reviewed
          </span>
        )}
      </div>
    </div>
  );
}
