import { IconMap } from "~/components/icons";
import ListLink from "~/components/list-link";
import type {
  InvoiceDashProjectFieldsFragment,
  ShowContactFieldsFragment,
  TagFieldsFragment
} from "~/types/api";
import { formatDate } from "~/utils/dates";

type Props = {
  tag: TagFieldsFragment;
  onClick?: (tag: TagFieldsFragment) => void;
  selected?: boolean;
  parent?: ShowContactFieldsFragment | InvoiceDashProjectFieldsFragment;
};

export default function TagRow({ tag, parent, selected, onClick }: Props) {
  const openUrl = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    window.open(tag.url!, "_blank");
  };

  const tagging = parent?.taggings.find((ct) => ct.tag.id === tag.id);

  return (
    <ListLink
      match={selected}
      style={
        tag.color
          ? {
              backgroundColor: tag.color,
              color: "#333",
              fontWeight: "bold",
              display: "flex"
            }
          : { display: "flex" }
      }
      to={
        onClick
          ? () => onClick(tag)
          : parent && !onClick
            ? parent!.__typename === "Contact"
              ? `/contacts?filters=%5B%7B"criteria"%3A"Tagged%20One"%2C"values"%3A%5B"${tag.id}"%5D%7D%5D`
              : `/ar/receivables?tags=${tag.id}`
            : undefined
      }
      key={tag.id}
      title={tagging ? `Tagged on ${formatDate(tagging.createdAt)}` : undefined}
    >
      <div className="flex-1">
        {tag.category}
        {tag.description && (
          <div className="mt-2 text-gray-500">{tag.description}</div>
        )}
      </div>
      {tag.url && (
        <div className={selected ? "text-white" : ""} onClick={openUrl}>
          <IconMap /> Map
        </div>
      )}
    </ListLink>
  );
}
