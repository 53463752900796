import clsx from "clsx";
import type { CSSProperties, ReactNode } from "react";
import type { LinkProps } from "~/components/link";
import Link, { useMatch } from "~/components/link";

interface Props extends LinkProps {
  label?: ReactNode;
  exact?: boolean;
  match?: boolean;
  style?: CSSProperties;
  matchPath?: string;
}

export default function NavLink({
  label,
  children,
  className = "",
  match: forceMatch,
  exact = true,
  style = {},
  matchPath,
  to,
  ...rest
}: Props) {
  const match = useMatch(matchPath, to, exact);

  return (
    <li
      className={clsx((forceMatch ?? match) && "active", className)}
      style={style}
    >
      <Link {...rest} to={to}>
        {label || children}
      </Link>
    </li>
  );
}
