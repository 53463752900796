import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Transition
} from "@headlessui/react";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { useVirtualizer } from "@tanstack/react-virtual";
import clsx from "clsx";
import _ from "lodash";
import type { ReactNode } from "react";
import React, { Fragment, useCallback, useMemo, useRef, useState } from "react";
import invariant from "tiny-invariant";
import { IconLoading } from "~/components/icons";
import { useLocationPusher } from "~/components/link";

export type ComboBoxOption = {
  label: string;
  subtitle?: ReactNode;
  prefix?: string;
  value: string;
  creatable?: boolean;

  // Extra fields that get used sometimes
  count?: number;
  disabled?: boolean;
  extra?: unknown;
  indentLevel?: number;
  login?: string;
  name?: string;
  parentKey?: string;
  rightLabel?: ReactNode;
  rightLabelMode?: "success" | "warning" | "danger" | "default";
  section?: string;
};

export type GroupedComboBoxOption = {
  label: string;
  options: ComboBoxOption[];
};

interface ComboBoxPropsBase {
  autoFocus?: boolean;
  creatable?: boolean;
  disabled?: boolean;
  filterOptions?: (
    options: ComboBoxOption[],
    query: string
  ) => ComboBoxOption[];
  isClearable?: boolean;
  isLoading?: boolean;
  loadingMessage?: string;
  menuWidth?: number;
  name: string;
  noOptionsMessage?: string;
  onChangeQuery?: (query: string) => void;
  onCreateOption?: (value: string) => void;
  options: string[][] | string[] | ComboBoxOption[] | GroupedComboBoxOption[];
  placeholder?: string;
  renderOption?: (option: ComboBoxOption) => ReactNode;
  renderSingleValue?: (option: ComboBoxOption) => ReactNode;
  size?: "Small" | number;
  type?: string;
}

export interface ComboBoxPropsSingle extends ComboBoxPropsBase {
  defaultValue?: string;
  multiple?: false;
  onChange?: (name: string, value: string, extra?: unknown) => void;
  value?: string | null;
  redirect?: boolean;
  prefix?: string;
}

export interface ComboBoxPropsMultiple extends ComboBoxPropsBase {
  defaultValue?: string[];
  multiple: true;
  onChange?: (name: string, value: string[]) => void;
  value?: string[];
  redirect?: undefined;
  prefix?: undefined;
}

export type ComboBoxParentPropsSingle = Omit<ComboBoxPropsSingle, "options">;
export type ComboBoxParentPropsMultiple = Omit<
  ComboBoxPropsMultiple,
  "options"
>;
export type ComboBoxParentProps =
  | ComboBoxParentPropsSingle
  | ComboBoxParentPropsMultiple;

export type ComboBoxProps = ComboBoxPropsSingle | ComboBoxPropsMultiple;

export default React.memo(function ComboBox({
  autoFocus,
  creatable,
  defaultValue,
  disabled,
  filterOptions,
  isClearable = true,
  isLoading,
  loadingMessage,
  menuWidth,
  multiple,
  name,
  noOptionsMessage = "No Options...",
  onChange,
  onChangeQuery,
  onCreateOption,
  options,
  placeholder = "Select...",
  prefix,
  redirect,
  renderOption,
  renderSingleValue,
  size,
  value: controlledValue
}: ComboBoxProps) {
  const push = useLocationPusher();
  const [query, _setQuery] = useState("");
  const setQuery = useCallback(
    (q: string) => {
      _setQuery(q);
      onChangeQuery?.(q);
    },
    [onChangeQuery]
  );
  const [localSingleValue, setLocalSingleValue] = useState(
    multiple ? "" : defaultValue || ""
  );
  const [localMultiValue, setLocalMultiValue] = useState(
    multiple ? defaultValue || [] : []
  );
  const inputRef = useRef<HTMLInputElement>(null);

  const singleValue = multiple ? "" : (controlledValue ?? localSingleValue);
  const multiValue = useMemo(
    () => (multiple ? (controlledValue ?? localMultiValue) : []),
    [controlledValue, localMultiValue, multiple]
  );

  // Convert options to ComboBoxOption[]
  const grouped = _.isObject(options[0]) && "options" in options[0];
  const keys = useMemo(
    () =>
      grouped ? (options as GroupedComboBoxOption[]).map((o) => o.label) : [],
    [grouped, options]
  );
  const opts = useMemo(
    () =>
      typeof options[0] === "string"
        ? options.map((o) => ({ value: o, label: o }) as ComboBoxOption)
        : grouped
          ? (options as { label: string; options: ComboBoxOption[] }[]).flatMap(
              (o) => o.options.map((opt) => ({ ...opt, parentKey: o.label }))
            )
          : Array.isArray(options[0])
            ? (options as [string, string]).map(
                (o) => ({ label: o[0], value: o[1] }) as ComboBoxOption
              )
            : (options as ComboBoxOption[]),
    [options, grouped]
  );

  let filtered = query
    ? filterOptions?.(opts, query) ||
      opts.filter((option) => {
        return (
          option.value.toLowerCase().includes(query.toLowerCase()) ||
          option.label?.toString().toLowerCase().includes(query.toLowerCase())
        );
      })
    : opts;

  filtered = multiple
    ? filtered.filter((o) => !multiValue?.includes(o.value))
    : filtered;

  const isBlank =
    (multiple && !multiValue?.length) || (!multiple && !singleValue);

  const selectedOptions = useMemo(
    () =>
      multiple
        ? multiValue.length
          ? opts.filter((o) => multiValue.includes(o.value))
          : []
        : [opts.find((o) => o.value === singleValue)].filter(
            (o) => typeof o !== "undefined"
          ),
    [multiValue, multiple, opts, singleValue]
  );

  const canClear = !isBlank && isClearable && !disabled;

  const removeValue = useCallback(
    (val: string) => {
      invariant(multiple, "Cannot use removeValue change handler for single");
      const next = multiValue.filter((v) => v !== val);
      onChange?.(name, next);
      setLocalMultiValue(next);
    },
    [multiValue, multiple, name, onChange]
  );

  const content = useMemo(
    () => (
      <>
        <div
          className={clsx("relative text-gray-900", !size && "w-full")}
          style={size ? { width: size === "Small" ? 220 : size } : {}}
        >
          {multiple ? (
            <div className="flex w-full flex-wrap items-start gap-x-2 gap-y-1 rounded-md border border-gray-300 bg-white pl-2 pr-[60px] shadow-sm focus-within:border-blue-500 focus-within:outline-none focus-within:ring-1 focus-within:ring-blue-500 data-[open]:border-blue-500 data-[open]:outline-none data-[open]:ring-1 data-[open]:ring-blue-500">
              {multiValue.map((v) => {
                const opt = opts.find((o) => o.value === v);
                return (
                  <div
                    key={v}
                    className="mt-[3px] flex items-center gap-1.5 rounded border border-blue-100 bg-blue-50 px-2 text-gray-700"
                  >
                    <ComboboxButton as="span">{opt?.label}</ComboboxButton>
                    {!disabled && (
                      <svg
                        className="h-5 w-5 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={(e) => {
                          removeValue(v);
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    )}
                  </div>
                );
              })}
              <ComboboxButton
                as="div"
                className={clsx(
                  !!multiValue.length && "min-w-[25px]",
                  "flex-1 bg-transparent !p-0 focus:outline-none"
                )}
              >
                <ComboboxInput
                  autoFocus={autoFocus}
                  ref={inputRef}
                  autoComplete="off"
                  className="w-full cursor-pointer !pl-[1px] focus:outline-none"
                  onChange={(event) => {
                    setQuery(event.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (
                      !query &&
                      multiValue.length &&
                      (e.key === "Backspace" || e.key === "Delete")
                    ) {
                      onChange?.(name, multiValue.slice(0, -1));
                      setLocalMultiValue(multiValue.slice(0, -1));
                    }
                  }}
                  value={query}
                  placeholder={
                    multiValue.length
                      ? ""
                      : (isLoading && loadingMessage) || placeholder
                  }
                />
              </ComboboxButton>
            </div>
          ) : (
            <ComboboxButton
              className="w-full flex-1 cursor-pointer bg-transparent !p-0 focus:outline-none"
              as="div"
            >
              {/* Show a div with the current value, but not selectable. Cursor will sit at the front of it and this
            placeholder will disappear when we start typing */}
              {!query && (singleValue || !!selectedOptions.length) && (
                <div
                  className={clsx(
                    "absolute inset-0 px-[7px] py-[5px] text-left",
                    disabled ? "cursor-not-allowed" : "cursor-pointer"
                  )}
                >
                  {selectedOptions?.[0] ? (
                    renderSingleValue?.(selectedOptions[0]) || (
                      <div
                        className={clsx(
                          "truncate",
                          canClear ? "pr-16" : "pr-8"
                        )}
                      >
                        {prefix && `${prefix}: `}
                        {selectedOptions[0].prefix &&
                          `${selectedOptions[0].prefix}: `}
                        {selectedOptions[0].label}
                      </div>
                    )
                  ) : (
                    <span className="text-gray-500">Loading...</span>
                  )}
                </div>
              )}
              <ComboboxInput
                ref={inputRef}
                autoComplete="off"
                autoFocus={autoFocus}
                className="flex w-full cursor-pointer gap-2 rounded-md border border-gray-300 bg-white shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 data-[disabled]:cursor-not-allowed data-[open]:border-blue-500 data-[disabled]:bg-[#eee] data-[open]:outline-none data-[open]:ring-1 data-[open]:ring-blue-500"
                onKeyDown={(e) => {
                  if (
                    !query &&
                    canClear &&
                    (e.key === "Backspace" || e.key === "Delete")
                  ) {
                    onChange?.(name, "");
                    setLocalSingleValue("");
                  }
                }}
                onChange={(event) => {
                  setQuery(event.target.value);
                }}
                value={query}
                placeholder={
                  singleValue || selectedOptions.length
                    ? ""
                    : (isLoading && loadingMessage) || placeholder
                }
                displayValue={(value) => {
                  // Don't use Combobox's displayValue, we put a div overlay instead to act like react-select
                  return "";
                }}
              />
            </ComboboxButton>
          )}
          <ComboboxButton
            className={clsx(
              "x-w-full absolute inset-y-0 right-0 flex justify-end space-x-2 rounded-r-md bg-transparent px-2",
              disabled ? "cursor-not-allowed" : "cursor-pointer"
            )}
            as="div"
          >
            <div className="flex w-full items-center justify-end rounded-r-md bg-transparent !p-0 focus:outline-none">
              {isLoading ? (
                <IconLoading className="mr-2 text-gray-400" />
              ) : (
                <ChevronDownIcon
                  className="h-8 w-8 text-gray-400"
                  aria-hidden="true"
                />
              )}
            </div>
          </ComboboxButton>
          {canClear && (
            <div className="absolute inset-y-0 right-8 flex cursor-pointer space-x-2 rounded-r-md bg-transparent px-2">
              <button
                type="button"
                className="flex items-center rounded-r-md bg-transparent !p-0 focus:outline-none"
                tabIndex={-1}
                onClick={() => {
                  multiple ? onChange?.(name, []) : onChange?.(name, "");
                  setLocalMultiValue([]);
                  setLocalSingleValue("");
                  inputRef.current?.focus();
                  if (redirect) {
                    push({ [name]: undefined });
                  }
                }}
              >
                <XMarkIcon
                  className="h-8 w-8 text-gray-400"
                  aria-hidden="true"
                />
              </button>
            </div>
          )}
          <Transition afterLeave={() => setQuery("")}>
            {grouped ? (
              <ComboboxOptions
                static
                as="div"
                style={menuWidth ? { minWidth: menuWidth } : {}}
                className="absolute z-200 mt-[8px] max-h-[300px] w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                {keys.map((k) => {
                  const items = filtered.filter((i) => i.parentKey === k);
                  return !items.length ? null : (
                    <Fragment key={k}>
                      <div className="x-pb-1 x-pt-3 x-text-lg x-text-gray-400 relative select-none bg-gray-100 px-4 py-3 font-medium">
                        {k}
                      </div>

                      {items.map((option) => {
                        return (
                          <ComboboxOption
                            disabled={option.disabled}
                            key={`${option.value}-${option.label}`}
                            as="div"
                            value={option}
                            className="group relative cursor-default select-none px-4 py-2 text-gray-900 data-[focus]:bg-blue-100 data-[selected]:bg-blue-500 data-[disabled]:text-gray-900 data-[selected]:text-white"
                          >
                            {renderOption?.(option) || (
                              <>
                                <div
                                  className="flex items-center justify-between space-x-2"
                                  style={
                                    option.indentLevel && option.indentLevel > 0
                                      ? {
                                          marginLeft: option.indentLevel * 15
                                        }
                                      : {}
                                  }
                                >
                                  <span>{option.label}</span>
                                  {option.rightLabel}
                                </div>
                                {option.subtitle && (
                                  <div>
                                    <small className="text-gray-400 group-data-[selected]:text-white/70">
                                      {option.subtitle}
                                    </small>
                                  </div>
                                )}
                              </>
                            )}
                          </ComboboxOption>
                        );
                      })}
                    </Fragment>
                  );
                })}
                {creatable && query.length > 0 && (
                  <ComboboxOption
                    key="creatable"
                    value={{ value: query, label: query, creatable: true }}
                    as="div"
                    className="relative cursor-default select-none px-4 py-2 text-gray-900 data-[focus]:bg-blue-100"
                  >
                    <span>Create "{query}"</span>
                  </ComboboxOption>
                )}
                {!opts.length ? (
                  <div className="px-4 py-2 text-center text-gray-500">
                    {noOptionsMessage}
                  </div>
                ) : !filtered.length && (!creatable || !query.length) ? (
                  <div className="px-4 py-2 text-center text-gray-500">
                    No Results...
                  </div>
                ) : null}
              </ComboboxOptions>
            ) : (
              <ComboboxOptions static as="div" className="relative">
                {filtered.length >= 500 ? (
                  <VirtualizedList
                    items={filtered}
                    renderOption={renderOption}
                    width={menuWidth}
                  />
                ) : (
                  <div
                    style={menuWidth ? { minWidth: menuWidth } : undefined}
                    className="absolute z-200 mt-[8px] max-h-[300px] w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    {filtered.map((option) => {
                      return (
                        <ComboboxOption
                          disabled={option.disabled}
                          key={option.value}
                          as="div"
                          value={option}
                          className="group relative cursor-default select-none px-4 py-2 text-gray-900 data-[focus]:bg-blue-100 data-[selected]:bg-blue-500 data-[disabled]:text-gray-900 data-[selected]:text-white"
                        >
                          {renderOption?.(option) || (
                            <>
                              <div
                                className="flex items-center justify-between space-x-2"
                                style={
                                  option.indentLevel && option.indentLevel > 0
                                    ? {
                                        marginLeft: option.indentLevel * 15
                                      }
                                    : {}
                                }
                              >
                                <span>{option.label}</span>
                                {option.rightLabel}
                              </div>
                              {option.subtitle && (
                                <div>
                                  <small className="text-gray-400 group-data-[selected]:text-white/70">
                                    {option.subtitle}
                                  </small>
                                </div>
                              )}
                            </>
                          )}
                        </ComboboxOption>
                      );
                    })}

                    {creatable && query.length > 0 && (
                      <ComboboxOption
                        key="creatable"
                        value={{ value: query, label: query, creatable: true }}
                        as="div"
                        className="relative cursor-default select-none px-4 py-2 text-gray-900 data-[focus]:bg-blue-100"
                      >
                        <span>Create "{query}"</span>
                      </ComboboxOption>
                    )}
                    {!opts.length ? (
                      <div className="px-4 py-2 text-center text-gray-500">
                        {noOptionsMessage}
                      </div>
                    ) : !filtered.length && (!creatable || !query.length) ? (
                      <div className="px-4 py-2 text-center text-gray-500">
                        No Results...
                      </div>
                    ) : null}
                  </div>
                )}
              </ComboboxOptions>
            )}
          </Transition>
        </div>
        {multiple ? (
          <>
            {multiValue.length ? (
              multiValue.map((v) => (
                <input
                  type="hidden"
                  key={v}
                  name={name}
                  value={v}
                  data-1p-ignore
                />
              ))
            ) : (
              <input type="hidden" name={name} value={undefined} />
            )}
          </>
        ) : (
          <input type="hidden" name={name} value={singleValue} data-1p-ignore />
        )}
      </>
    ),
    [
      autoFocus,
      canClear,
      creatable,
      disabled,
      filtered,
      grouped,
      isLoading,
      keys,
      loadingMessage,
      menuWidth,
      multiValue,
      multiple,
      name,
      noOptionsMessage,
      onChange,
      opts,
      placeholder,
      prefix,
      push,
      query,
      redirect,
      removeValue,
      renderOption,
      renderSingleValue,
      selectedOptions,
      setQuery,
      singleValue,
      size
    ]
  );

  const handleSingleChange = (val: ComboBoxOption | null) => {
    invariant(!multiple, "Cannot use single change handler for multiple");
    onChange?.(name, val?.value || "", val?.extra);
    if (val?.creatable) {
      onCreateOption?.(val.value);
    }
    setLocalSingleValue(val?.value || "");
    if (redirect) {
      push({ [name]: val?.value || undefined });
    }
  };

  const handleMultipleChange = (val: ComboBoxOption[]) => {
    invariant(multiple, "Cannot use multiple change handler for single");
    const adding = val.length > multiValue.length;
    if (adding) {
      const newValues = val.filter((v) => !multiValue.includes(v.value));
      const next = [...multiValue, ...newValues.map((v) => v.value)];
      onChange?.(name, next);
      const created = val.find((v) => v.creatable);
      if (created) {
        onCreateOption?.(created.value);
      }
      setLocalMultiValue(next);
      setQuery("");
    }
    // This could theoretically fire for removing items,
    // however, we currently hide selected options so @headlessui's Combobox
    // should never do this.
  };

  // We don't pass name into Combobox because we handle the hidden fields ourselves.
  // @headlessui's Combobox tries to put *all* props into the input, which
  // causes stack depth issues trying to convert react element option props
  // into form values.
  if (multiple) {
    return (
      <Combobox
        disabled={disabled}
        onChange={handleMultipleChange}
        multiple
        // Added these with patch-package to make it behave more like react-select
        closeOnSelect
        selectOnTab
        value={opts.filter((o) => multiValue.includes(o.value))}
      >
        {content}
      </Combobox>
    );
  }

  return (
    <Combobox
      disabled={disabled}
      onChange={handleSingleChange}
      value={opts.find((o) => o.value === singleValue) || null}
    >
      {content}
    </Combobox>
  );
});

type VirtualizedListProps = {
  items: ComboBoxOption[];
  renderOption?: (option: ComboBoxOption) => ReactNode;
  width?: number;
};

const VirtualizedList = ({
  items: options,
  renderOption,
  width
}: VirtualizedListProps) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtualizer({
    count: options.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 29
  });

  const items = rowVirtualizer.getVirtualItems();
  return (
    <div
      ref={parentRef}
      //  className="max-h-[300px]"
      style={width ? { width } : undefined}
      className="absolute z-200 mt-[8px] max-h-[300px] w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
    >
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: "100%",
          position: "relative"
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            transform: `translateY(${items[0]?.start || 0}px)`
          }}
        >
          {items.map((virtualRow) => {
            const option = options[virtualRow.index];
            return (
              <ComboboxOption
                key={virtualRow.key}
                data-index={virtualRow.index}
                ref={rowVirtualizer.measureElement}
                // style={{
                //   position: "absolute",
                //   top: 0,
                //   left: 0,
                //   width: "100%",
                //   height: `${virtualRow.size}px`,
                //   transform: `translateY(${virtualRow.start}px)`
                // }}
                disabled={option.disabled}
                as="div"
                value={option}
                className="group relative cursor-default select-none px-4 py-2 text-gray-900 data-[focus]:bg-blue-100 data-[selected]:bg-blue-500 data-[disabled]:text-gray-900 data-[selected]:text-white"
              >
                {renderOption?.(option) || (
                  <>
                    <div
                      className="flex items-center justify-between space-x-2"
                      style={
                        option.indentLevel && option.indentLevel > 0
                          ? {
                              marginLeft: option.indentLevel * 15
                            }
                          : {}
                      }
                    >
                      <span>{option.label}</span>
                      {option.rightLabel}
                    </div>
                    {option.subtitle && (
                      <div>
                        <small className="text-gray-400 group-data-[selected]:text-white/70">
                          {option.subtitle}
                        </small>
                      </div>
                    )}
                  </>
                )}
              </ComboboxOption>
            );
          })}
        </div>
      </div>
    </div>
  );
};
