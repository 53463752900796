import { MenuItem } from "@headlessui/react";
import clsx from "clsx";
import type { CSSProperties, ForwardedRef } from "react";
import React from "react";
import type { LinkProps } from "~/components/link";
import Link, { useMatch } from "~/components/link";

interface Props extends LinkProps {
  label?: React.ReactNode;
  exact?: boolean;
  match?: boolean;
  style?: CSSProperties;
  matchPath?: string;
}

export default function DropdownLink({
  label,
  children,
  className = "",
  match: forceMatch,
  exact = true,
  style = {},
  matchPath,
  ...rest
}: Props) {
  const match = useMatch(matchPath, rest.to, exact);

  const Wrapper = ({
    active,
    forwardRef,
    ...rest
  }: {
    active?: boolean;
    forwardRef?: ForwardedRef<HTMLLIElement>;
  } & LinkProps) => (
    <li
      ref={forwardRef}
      className={clsx(
        (forceMatch ?? match) && "active",
        className,
        "focus:outline-none"
      )}
      style={style}
    >
      <Link
        {...rest}
        className={clsx(
          "focus:outline-none",
          active && "bg-gray-100",
          rest.disabled && "cursor-not-allowed !text-gray-400"
        )}
      >
        {label || children}
      </Link>
    </li>
  );
  const WrapperRef = React.forwardRef<
    HTMLLIElement,
    { active?: boolean } & LinkProps
  >((props, ref) => <Wrapper forwardRef={ref} {...props} />);

  return (
    <MenuItem>
      {({ focus }) => <WrapperRef active={focus} {...rest} />}
    </MenuItem>
  );
}
