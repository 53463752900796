import { useLoaderData } from "@remix-run/react";
import _ from "lodash";
import { useContext } from "react";
import { Chart } from "react-google-charts";
import ButtonLink from "~/components/button-link";
import Panel from "~/components/panel";
import { ScoreboardUserContext } from "~/contexts";
import type { ProjectMetricsQuery } from "~/types/api";
import { differenceInDays, endOfQuarter, parseDate } from "~/utils/dates";

interface Props {
  quarter: string;
  accountsReceivable?: boolean;
}

export default function QuarterlyMetrics({
  quarter,
  accountsReceivable
}: Props) {
  const user = useContext(ScoreboardUserContext)!;
  const {
    projectMetricsData: { audits, activeProjects, myAudits, ppms }
  } = useLoaderData<{ projectMetricsData: ProjectMetricsQuery }>();

  const items: (string | number | { role: string })[][] = [
    [
      "Label",
      "Actual",
      { role: "annotation" },
      { role: "style" },
      "Target",
      { role: "annotation" },
      { role: "style" },
      "Goal",
      { role: "annotation" },
      { role: "style" }
    ]
  ];

  const s = parseDate(quarter);
  const e = endOfQuarter(s);
  const days = differenceInDays(e, s) + 1;
  const current = differenceInDays(new Date(), s) + 1;
  const goal = accountsReceivable ? 2 : _.sumBy(activeProjects, "count");
  const auditGoal = accountsReceivable ? 15 : goal;
  const auditPum = _.sumBy(audits, "count");
  const audit = _.sumBy(myAudits, "count");
  const ppm = _.sumBy(ppms, "count");
  const target = Math.round((current / days) * goal);
  const auditTarget = Math.round((current / days) * auditGoal);

  items.push([
    accountsReceivable ? "Collections Audits" : "Audits (My Projects)",
    auditPum,
    `Actual: ${auditPum.toString()}`,
    auditPum < auditTarget ? "#d9534f" : "#5cb85c",
    auditTarget,
    `Target: ${auditTarget}`,
    "#f0ad4e",
    auditGoal,
    `Goal: ${auditGoal}`,
    "#0066cc"
  ]);

  if (!accountsReceivable) {
    items.push([
      "Audits (By Me)",
      audit,
      `Actual: ${audit.toString()}`,
      audit < auditTarget ? "#d9534f" : "#5cb85c",
      auditTarget,
      `Target: ${auditTarget}`,
      "#f0ad4e",
      auditGoal,
      `Goal: ${auditGoal}`,
      "#0066cc"
    ]);
  }

  items.push([
    "Project Meetings",
    ppm,
    `Actual: ${ppm.toString()}`,
    ppm < target ? "#d9534f" : "#5cb85c",
    target,
    `Target: ${target}`,
    "#f0ad4e",
    goal,
    `Goal: ${goal}`,
    "#0066cc"
  ]);
  const maxValue =
    _.sortBy(items.slice(1).flatMap((i) => [i[1], i[4], i[7]])).at(-1) || 0;

  return (
    <Panel className="mb-0">
      <Panel.Header
        className="text-center"
        title={accountsReceivable ? "Quarterly A/R QC" : "Quarterly Project QC"}
        floatButton
        button={
          <ButtonLink
            to={
              accountsReceivable
                ? "/projects/reports/audits?mode=All&template=4"
                : `/projects/reports/audits?user=${user.id}`
            }
            className="show-when-hover-parent"
            small
          >
            Audit List
          </ButtonLink>
        }
      />
      <Chart
        height="251px"
        width="auto"
        chartType="BarChart"
        data={items}
        options={{
          chartArea: {
            top: 15,
            right: 15,
            bottom: 15,
            left: 70,
            height: "100%",
            width: "100%"
          },
          bar: {
            groupWidth: "85%"
          },
          hAxis: {
            minValue: 0,
            viewWindow: {
              min: 0,
              max: maxValue
            },
            ticks: [],
            textPosition: "none"
          },
          legend: "none"
        }}
      />
      <Panel.Footer className="text-center">
        {accountsReceivable
          ? "Goal: 15 Collections Audits per Quarter and 2 PPMs per Quarter"
          : "Goal: 1 per active project per quarter"}
      </Panel.Footer>
    </Panel>
  );
}
