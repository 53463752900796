import _ from "lodash";
import { useMemo } from "react";
import type {
  ComboBoxOption,
  ComboBoxParentPropsSingle
} from "~/components/combobox";
import ComboBox from "~/components/combobox";
import type { ProjectMembershipsQuery } from "~/types/api";
import { useFetcherData } from "~/utils/remix";

export interface MembershipPickerProps extends ComboBoxParentPropsSingle {
  additionalOptions: ComboBoxOption[];
  project: { id: string };
}

export default function MembershipPicker({
  project: { id },
  additionalOptions,
  ...rest
}: MembershipPickerProps) {
  const fetcher = useFetcherData<ProjectMembershipsQuery>(
    "/resources/memberships/picker",
    { id }
  );

  const options = useMemo(() => {
    if (!fetcher.data) return [];
    const { project } = fetcher.data;
    const memberships = _.sortBy(project.memberships, (m) => !m.primaryContact);
    const primaryLabel = memberships
      .filter((m) => m.primaryContact)
      .map((m) => m.contact.informalDisplayAs)
      .join("; ");

    let options: ComboBoxOption[] = [
      { value: "", label: `Primary Contacts (${primaryLabel || "N/A"})` }
    ];
    options = options.concat(
      memberships.map((m) => ({
        value: m.id,
        label: m.contact.company
          ? `${m.contact.informalDisplayAs} (${m.contact.company.name})`
          : m.contact.informalDisplayAs
      }))
    );
    if (additionalOptions) {
      options = options.concat(additionalOptions);
    }
    return options;
  }, [additionalOptions, fetcher.data]);

  return <ComboBox options={options} {...rest} />;
}
