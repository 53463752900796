import async from "async";
import _ from "lodash";
import type { Dispatch, SetStateAction } from "react";
import { uploadAndNotify } from "~/components/file-uploader";
import type {
  CreateSourceFilesMutation,
  PileFieldsFragment
} from "~/types/api";
import type { FileUploadEntry } from "~/utils/use-batch-uploader";
import { FileState, parseFiles } from "~/utils/use-batch-uploader";

export type PileUploaderStatus = {
  status:
    | "choosing"
    | "ready"
    | "folders"
    | "placeholders"
    | "s3"
    | "done"
    | "redirecting";
  count: number;
  ready: number;
  uploading: number;
  uploadingRemote: number;
  done: number;
  error: number;
  current: FileUploadEntry[];
};

export const S3_CHUNK_SIZE = 100_000_000;

export default class PileUploader {
  files: FileUploadEntry[];
  pile: PileFieldsFragment;
  setState: Dispatch<SetStateAction<PileUploaderStatus>>;

  constructor(opts: {
    files: FileList | File | null;
    pile: PileFieldsFragment;
    setState: Dispatch<SetStateAction<PileUploaderStatus>>;
  }) {
    this.files = parseFiles(opts.files, undefined, FileState.Ready);
    this.pile = opts.pile;
    this.setState = opts.setState;
  }

  setFiles(files: FileList | File | null) {
    this.files = parseFiles(files, undefined, FileState.Ready);
    this.updateStatus(this.files.length ? "ready" : "choosing");
  }

  private updateStatus(status: PileUploaderStatus["status"]) {
    this.setState({
      status,
      count: this.files.length,
      ready: this.files.filter((f) => f.state === FileState.Ready).length,
      uploading: this.files.filter((f) => f.state === FileState.Uploading)
        .length,
      uploadingRemote: this.files.filter(
        (f) => f.state === FileState.ReadyRemote
      ).length,
      current: this.files.filter((f) => f.state === FileState.UploadingRemote),
      done: this.files.filter((f) => f.state === FileState.Done).length,
      error: this.files.filter((f) => f.state === FileState.Error).length
    });
  }

  async folders() {
    const paths = _.uniq(this.files.map((f) => f.folder)).filter(Boolean);
    if (paths.length) {
      this.updateStatus("folders");
      const fd = new FormData();
      fd.set("pileId", this.pile.id);
      for (const path of paths) {
        fd.append("paths", path);
      }
      console.log("creating folders");
      await fetch("/resources/piles/create-source-file-folders", {
        method: "post",
        body: fd
      });
    }
  }

  async placeholders() {
    this.files = this.files.map((f) => ({ ...f, state: FileState.Uploading }));
    this.updateStatus("placeholders");
    const chunks = _.chunk(this.files, 100);

    const q = async.queue<FileUploadEntry[]>(async (files, callback) => {
      console.log("chunk:", files);
      console.log("Creating", files.length, "placeholders");
      const fd = new FormData();
      fd.append("mode", "File");
      fd.append("pileId", this.pile.id);
      for (const entry of files) {
        fd.append("name", entry.file.name);
        fd.append("folder", entry.folder);
        fd.append("attachmentName", entry.file.name);
        fd.append("attachmentType", entry.file.type);
        fd.append("attachmentSize", entry.file.size.toString());
        fd.append("uuid", entry.uuid);
      }

      const json = (await fetch("/resources/piles/create-source-files", {
        method: "post",
        body: fd
      }).then((res) => res.json())) as CreateSourceFilesMutation;

      // TODO: Error handling when files already exist
      for (const result of json.createSourceFiles) {
        const af = _.first(result.attachedFiles);
        const match = this.files.find((f) => f.uuid === result.uuid);
        if (match && af?.upload) {
          match.uploadUrl = af.upload.presignedUrl;
          match.remoteId = af.upload.id;
          match.state = FileState.ReadyRemote;
          match.awsUploadId = af.upload.awsUploadId;
          if (match.awsUploadId) {
            console.log(match.fullPath, "has upload id", match.awsUploadId);
          }
        }
      }
      callback();
    }, 2);

    q.push(chunks, () => this.updateStatus("placeholders"));
    await q.drain();
  }

  async s3() {
    this.updateStatus("s3");

    const q = async.queue<FileUploadEntry>(async (entry, callback) => {
      if (entry.uploadUrl && entry.remoteId) {
        // Set uploading status
        const index = this.files.findIndex((f) => f.uuid === entry.uuid);
        this.files[index].state = FileState.UploadingRemote;
        this.updateStatus("s3");

        // Upload to S3
        await uploadAndNotify(entry.uploadUrl, entry.remoteId, entry.file, {
          submitHash: true,
          onProgress: (percent) => {
            this.files[index].progress = percent;
            this.updateStatus("s3");
          }
        });

        this.files[index].state = FileState.Done;
        this.updateStatus("s3");
      }

      callback();
    }, 6); // S3 transfer acceleration is http/1.1 only, so we can only upload 6 files at a time.
    q.push(
      this.files.filter((f) => f.state === FileState.ReadyRemote),
      () => this.updateStatus("s3")
    );
    await q.drain();
    this.updateStatus("done");
  }

  async start() {
    await this.folders();
    await this.placeholders();
    await this.s3();
  }
}
