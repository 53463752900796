import { useContext } from "react";
import {
  IconBook,
  IconComments,
  IconImage,
  IconPrint,
  IconStar
} from "~/components/icons";
import { HeaderCell } from "~/components/numbered-table";
import type { ProjectContextType } from "~/contexts";
import { ContentTopicContext } from "~/contexts";

interface Props {
  includeSource?: boolean;
  fileType?: boolean;
  hasBates?: boolean;
  hideSummary?: boolean;
  minimal?: boolean;
  includeThumb?: boolean;
  project?: ProjectContextType;
  onSelect?: (id: "all") => void;
  allSelected?: boolean;
  section?: boolean;
  sortable?: boolean;
}

export default function DocumentListHeader({
  includeSource,
  fileType,
  hasBates,
  hideSummary,
  minimal,
  includeThumb,
  project,
  onSelect,
  allSelected,
  section = true,
  sortable
}: Props) {
  const contentTopic = useContext(ContentTopicContext);
  return (
    <thead>
      <tr>
        <th />
        <th className="text-center">
          <IconStar />
        </th>
        {section && (
          <HeaderCell
            sort={sortable ? "section" : undefined}
            className="text-center"
          >
            Section
          </HeaderCell>
        )}
        <HeaderCell
          sort={sortable ? "date" : undefined}
          className="text-center"
        >
          Date
        </HeaderCell>
        <HeaderCell sort={sortable ? "author" : undefined}>Author</HeaderCell>
        <HeaderCell sort={sortable ? "description" : undefined}>
          Description
        </HeaderCell>
        {includeSource && <th>Source</th>}
        {fileType && <th>Type</th>}
        <HeaderCell
          sort={sortable ? "receivedDate" : undefined}
          className="text-center"
        >
          Received
        </HeaderCell>
        {hasBates && (
          <HeaderCell
            sort={sortable ? "batesStamp" : undefined}
            className="text-center"
          >
            Bates #
          </HeaderCell>
        )}
        <HeaderCell
          sort={sortable ? "pages" : undefined}
          className="text-center"
        >
          Pages
        </HeaderCell>
        {!hideSummary && !minimal && <th style={{ width: "35%" }}>Summary</th>}
        {includeThumb && (
          <th className="text-center">
            <IconImage />
          </th>
        )}
        {!minimal && (
          <>
            {project?.billable && (
              <th className="text-center">
                <IconPrint title="Printed" titleId="icon-printed" />
              </th>
            )}
            {project?.mode === "Overhead" && (
              <th className="text-center">
                <IconBook title="Shows up in library" titleId="icon-library" />
              </th>
            )}
            {
              <HeaderCell
                sort={sortable ? "analysis" : undefined}
                className="text-center"
              >
                <IconComments
                  title={
                    contentTopic ? "Social Media Copy/Analysis" : "Analysis"
                  }
                  titleId="icon-analysis"
                />
              </HeaderCell>
            }
          </>
        )}
        <th className="whitespace-nowrap text-right" style={{ width: 1 }}>
          {onSelect && (
            <input
              type="checkbox"
              onChange={() => onSelect("all")}
              checked={allSelected}
            />
          )}
        </th>
      </tr>
    </thead>
  );
}
