import { useLoaderData } from "@remix-run/react";
import _ from "lodash";
import { Fragment } from "react";
import invariant from "tiny-invariant";
import Alert from "~/components/alert";
import Link from "~/components/link";
import { ListGroup, ListGroupItem } from "~/components/list-group";
import Panel from "~/components/panel";
import QuarterPicker from "~/components/quarter-picker";
import MilestoneItem from "~/components/scoreboard/milestone-item";
import type { DashboardMetricFieldsFragment } from "~/types/api";

type Props = { editable?: boolean; name?: string; quarterPicker?: boolean };

export default function IndividualRocks({
  editable,
  name,
  quarterPicker
}: Props) {
  const {
    milestoneData: { metrics },
    quarter
  } = useLoaderData<{
    milestoneData: { metrics: DashboardMetricFieldsFragment[] };
    quarter?: string;
  }>();

  const filtered = metrics.filter((m) => m.category?.endsWith("Milestone"));
  const grouped = _.groupBy(_.sortBy(filtered, "rank"), (m) => {
    if (m.category === "Project Milestone") {
      invariant(m.project, "Project milestone without project");
      return `${m.project.number} ${m.project.name}`;
    }
    return m.category;
  });

  return (
    <Panel>
      <Panel.Header
        title="Big Rocks"
        button={
          quarterPicker ? (
            <QuarterPicker
              redirect
              name="quarter"
              isClearable={false}
              defaultValue={quarter}
            />
          ) : undefined
        }
      />
      {filtered.length === 0 ? (
        <Alert mode="warning">
          {name ? `${name} doesn't` : "You don't"} have any rocks for this
          quarter
        </Alert>
      ) : (
        <ListGroup small>
          {Object.keys(grouped).map((k) => (
            <Fragment key={k}>
              <ListGroupItem>
                <strong>
                  <Link to={linkFor(k, quarter)}>
                    {k.replace(" Milestone", "")}
                  </Link>
                </strong>
              </ListGroupItem>
              {grouped[k].map((m, i) => (
                <MilestoneItem
                  readOnly={!editable}
                  key={m.id}
                  metric={m}
                  row={i + 1}
                />
              ))}
            </Fragment>
          ))}
        </ListGroup>
      )}
    </Panel>
  );
}

const linkFor = (key: string, quarter?: string) => {
  const k = key.replace(" Milestone", "");
  const url =
    k === "Leadership"
      ? "/management/scoreboard"
      : k === "Accounts Receivable"
        ? "/ar"
        : k === "Management"
          ? "/management/manager-scoreboard"
          : k === "Marketing" || key === "Marketing & Sales"
            ? "/marketing/scoreboard"
            : k === "Sales"
              ? "/sales/scoreboard"
              : k === "Project Management Team"
                ? "/projects/project-scoreboard"
                : k === "Technical Team"
                  ? "/projects/technical-scoreboard"
                  : k.match(/(OH|\d\d)-\d+/)
                    ? `/projects/${k.replace(/\s.*/, "")}`
                    : k === "Strategic Targets"
                      ? "/sales/strategic-targets"
                      : null;
  invariant(url, `No link for category: ${k}`);

  return quarter ? `${url}?quarter=${quarter}` : url;
};
