import clsx from "clsx";
import type { MouseEvent, ReactNode } from "react";
import { useCallback, useContext, useId } from "react";
import { Tooltip } from "react-tooltip";
import { NumberedTableContext } from "~/contexts";
import { ClientOnly } from "~/utils/remix";

interface HeaderCellProps {
  sort?: string;
  tooltip?: ReactNode;
  className?: string;
  children: ReactNode;
}

export default function HeaderCell({
  sort,
  children,
  tooltip,
  className = "",
  ...rest
}: HeaderCellProps) {
  const ctx = useContext(NumberedTableContext);
  const doSort = useCallback(
    (e: MouseEvent) => ctx?.onSort?.(sort!, e.shiftKey),
    [ctx, sort]
  );
  const id = useId().replace(/:/g, "");
  const match = ctx?.sort?.find((s) => s.key === sort);

  return (
    <th
      className={clsx(
        sort && "cursor-pointer select-none",
        match?.direction === "asc"
          ? "shadow-sort-asc"
          : match?.direction === "desc"
            ? "shadow-sort-desc"
            : "",
        className
      )}
      onClick={sort ? doSort : undefined}
      {...rest}
      data-tooltip-id={id}
    >
      {children}
      {tooltip && (
        <ClientOnly>
          {() => (
            <Tooltip id={id} place="bottom">
              {tooltip}
            </Tooltip>
          )}
        </ClientOnly>
      )}
    </th>
  );
}
