import { useLoaderData } from "@remix-run/react";
import invariant from "tiny-invariant";
import IndividualBoard from "~/components/kanban/individual-board";
import SalesMetricsTable from "~/components/sales/metrics-table";
import Campaigns from "~/components/scoreboard/campaigns";
import ContentPages from "~/components/scoreboard/content-pages";
import IndividualRocks from "~/components/scoreboard/individual-rocks";
import ScoreboardProject from "~/components/scoreboard/project-counts";
import TaskCounts from "~/components/scoreboard/task-counts";
import type { loader } from "~/routes/_index/route";

export default function DashboardMS() {
  const loaderData = useLoaderData<typeof loader>();
  const { dashboard } = loaderData;
  invariant(dashboard === "M&S", "Dashboard data doesn't match");
  const { salesMetricsData } = loaderData;

  return (
    <div>
      <div className="flex flex-wrap gap-8">
        <div className="w-full space-y-8 lg:w-[350px]">
          <IndividualRocks />
          <TaskCounts />
          <ScoreboardProject mode="Campaigns" />
          <ContentPages />
        </div>

        <div className="min-w-[500px] flex-1">
          <Campaigns />
        </div>
      </div>
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-4">
          <IndividualBoard />
        </div>
      </div>
      <SalesMetricsTable
        panel
        defaultInterval="Monthly"
        salesMetrics={salesMetricsData.salesMetrics}
        user={salesMetricsData.user}
      />
    </div>
  );
}
