import type { ReactNode } from "react";
import Link from "~/components/link";
import { lockedProjectWarning } from "~/components/projects/team";
import type {
  ContentTopicListFieldsFragment,
  KanbanCardFieldsFragment
} from "~/types/api";

type Props = {
  card: KanbanCardFieldsFragment;
  format: "card" | "list";
  canView: boolean;
  hideDeliverable?: boolean;
  onEdit: (id: string) => void;
};

export const getKanbanTitle = (
  card: KanbanCardFieldsFragment | ContentTopicListFieldsFragment,
  format: "list" | "card"
) => {
  return card.__typename === "ContentTopic"
    ? card.title
    : card.feature && format === "list"
      ? `${card.feature.title}${card.title ? `: ${card.title}` : ""}`
      : card.subFeature && format === "list"
        ? `${card.subFeature.title}${card.title ? `: ${card.title}` : ""}`
        : card.deliverable
          ? `${card.deliverable.description}${card.title ? `: ${card.title}` : ""}`
          : card.title;
};

export default function KanbanTitle({
  card,
  format,
  canView,
  hideDeliverable,
  onEdit
}: Props) {
  let text: ReactNode = card.title;
  if (!canView) {
    return lockedProjectWarning;
  }
  if (hideDeliverable) {
    if (!text) {
      text = <em>No Description</em>;
    }
  } else {
    text = getKanbanTitle(card, format);
  }

  let blockText = null;
  if (card.blocked) {
    blockText = card.blockedReason
      ? `Blocked: ${card.blockedReason}`
      : "Blocked";
    blockText = <div className="text-danger font-bold">{blockText}</div>;
  }

  return !text && !blockText ? null : format === "card" ? (
    <div className="break-words">
      <Link className="hover:no-underline" to={() => onEdit(card.id)}>
        {text}
        {blockText}
      </Link>
    </div>
  ) : (
    <div className="break-words">
      {text}
      {blockText}
    </div>
  );
}
