import _ from "lodash";
import { useMemo } from "react";
import type {
  ComboBoxParentPropsMultiple,
  ComboBoxParentPropsSingle
} from "~/components/combobox";
import ComboBox from "~/components/combobox";
import type { PracticeAreasQuery } from "~/types/api";
import { useFetcherData } from "~/utils/remix";

export type PracticeAreaPickerSingleProps = ComboBoxParentPropsSingle & {
  mode?: "L1" | "Select Children" | "Select All";
};

export type PracticeAreaPickerMultiProps = ComboBoxParentPropsMultiple & {
  mode?: "L1" | "Select Children" | "Select All";
};

export type PracticeAreaPickerProps =
  | PracticeAreaPickerSingleProps
  | PracticeAreaPickerMultiProps;

export default function PracticeAreaPicker({
  placeholder = "Select a Practice Area...",
  mode = "Select Children",
  ...rest
}: PracticeAreaPickerProps) {
  const fetcher = useFetcherData<PracticeAreasQuery>(
    "/resources/practice-areas"
  );

  const options = useMemo(() => {
    const all = _.sortBy(fetcher.data?.practiceAreas || [], "rank");
    const top = all.filter((pa) => !pa.practiceArea);
    if (mode === "L1") {
      return top.map((o) => ({
        value: o.id,
        label: o.name,
        extra: o
      }));
    }
    if (mode === "Select All") {
      const opts = [];
      for (const t of top) {
        opts.push({
          value: t.id,
          label: t.name,
          extra: t
        });
        for (const a of all.filter((a) => a.practiceArea?.id === t.id)) {
          opts.push({
            value: a.id,
            label: a.name,
            prefix: t.name,
            indentLevel: 1,
            extra: a
          });
        }
      }
      return opts;
    }
    return top.map((t) => ({
      label: t.name,
      options: all
        .filter((a) => a.practiceArea?.id === t.id)
        .map((o) => ({
          value: o.id,
          label: o.name,
          prefix: t.name,
          extra: o
        }))
    }));
  }, [fetcher.data, mode]);

  return (
    <ComboBox
      {...rest}
      placeholder={placeholder}
      options={options}
      filterOptions={(options, query) =>
        options.filter((option) =>
          `${option.prefix || ""} ${option.label}`
            .toLowerCase()
            .includes(query.toLowerCase())
        )
      }
    />
  );
}
