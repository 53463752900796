import _ from "lodash";
import { useMemo } from "react";
import type { ComboBoxParentProps } from "~/components/combobox";
import ComboBox from "~/components/combobox";
import type { SkillsQuery } from "~/types/api";
import { useFetcherData } from "~/utils/remix";

export default function SkillPicker({ ...rest }: ComboBoxParentProps) {
  const fetcher = useFetcherData<SkillsQuery>("/resources/skills/picker");

  const options = useMemo(
    () =>
      _.sortBy(fetcher.data?.skills || [], "category", "name").map((o) => ({
        value: o.id,
        label: `${o.category}: ${o.name}`
      })),
    [fetcher.data]
  );

  return <ComboBox {...rest} options={options} />;
}
