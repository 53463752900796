import { useContext, useRef, useState } from "react";
import Alert from "~/components/alert";
import { IconBook, IconHide, IconPrint, IconStar } from "~/components/icons";
import Modal from "~/components/modal";
import { Buttons, Errors, Input, RemixForm } from "~/components/remix-form";
import { FileTOC } from "~/constants";
import {
  ContentTopicContext,
  RemixFormContext,
  useOptionalEmployee,
  useOptionalProject
} from "~/contexts";
import type {
  DocumentRowFieldsFragment,
  DocumentShowFieldsFragment,
  EditDocumentQuery,
  SaveDocumentMutation
} from "~/types/api";
import { useFetcherData } from "~/utils/remix";

interface Props {
  onClose(result?: DocumentRowFieldsFragment): void;
  parent?: { id: string; section?: string | null; mode: string };
  folder?: boolean;
  id?: string;
}

interface InnerProps extends Omit<Props, "document"> {
  source?: DocumentShowFieldsFragment;
}

export default function HeaderForm({ id, ...props }: Props) {
  const fetcher = useFetcherData<EditDocumentQuery>(
    id ? `/resources/documents/${id}` : undefined
  );
  return id && !fetcher.data ? null : (
    <Form source={fetcher.data?.document} {...props} />
  );
}

const Form = ({ onClose, source, parent, folder }: InnerProps) => {
  const referenceDoc = source?.referenceDocument;
  const project = useOptionalProject();
  const employee = useOptionalEmployee();
  const contentTopic = useContext(ContentTopicContext);
  const data = source
    ? { ...source, documentId: source.document?.id }
    : {
        id: "",
        documentId: parent?.id,
        section: parent?.section,
        header: true,
        mode: parent || folder ? "Folder" : "Header",
        projectId: project?.id,
        contentTopicId: contentTopic?.id,
        employeeId: employee?.id,
        employeeScope: employee?.scope
      };

  const [documentId, setDocumentId] = useState(data.documentId);
  const autoFocus =
    data.documentId || data.id || data.mode === "Folder" ? "author" : "section";

  const onSuccess = (result: SaveDocumentMutation) => {
    onClose(result.saveDocument.document);
  };
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Modal onExplicitClose={onClose} initialFocus={inputRef}>
      <RemixForm
        data={data}
        fetcher
        action="/resources/documents/save"
        onSuccess={onSuccess}
      >
        {!source && (
          <>
            <input type="hidden" name="header" value="1" />
            <input type="hidden" name="mode" value={data.mode} />
            <input type="hidden" name="projectId" value={project?.id} />
            <input
              type="hidden"
              name="contentTopicId"
              value={contentTopic?.id}
            />
            <input type="hidden" name="employeeId" value={employee?.id} />
            <input type="hidden" name="employeeScope" value={employee?.scope} />
          </>
        )}
        <Modal.Header
          title={
            source?.pile
              ? "Edit Pile"
              : `${source ? "Edit" : "New"} ${
                  data.mode === "Folder"
                    ? "Folder"
                    : data.mode === "Header"
                      ? "Section Header"
                      : "File"
                }`
          }
        />
        <Modal.Body>
          <Errors />
          <div className="grid grid-cols-3 gap-8">
            {data.mode === "Header" ? (
              <Input
                name="section"
                disabled={!!documentId}
                forwardRef={autoFocus === "section" ? inputRef : undefined}
              />
            ) : (
              <Input
                name="documentId"
                type="fileSection"
                value={documentId}
                onChange={(_name, value) => setDocumentId(value)}
                label="Parent Section/Folder"
                skip={data.id}
                isClearable={false}
                autoFocus
              />
            )}
            <div className="col-span-2">
              {data.mode === "File" ? (
                <Input name="description" label="Title/Short Description" />
              ) : (
                <Input
                  name="author"
                  label={
                    data.mode === "File" ? "Author" : "Author/Section Title"
                  }
                  forwardRef={autoFocus === "author" ? inputRef : undefined}
                />
              )}
            </div>
          </div>
          {data.mode === "File" && (
            <>
              <div className="grid grid-cols-2 gap-4">
                {project || contentTopic || employee ? (
                  <Input
                    name="author"
                    label="Author/Party (Role)"
                    type="fileAuthor"
                  />
                ) : (
                  <Input name="author" label="Author/Party (Role)" />
                )}
                {project ? (
                  <Input name="recipient" type="fileAuthor" />
                ) : (
                  <Input name="recipient" type="text" />
                )}
              </div>
              <div className="grid grid-cols-3 gap-4">
                <Input name="date" type="date" />
                <Input name="endDate" type="date" />
                <Input name="receivedDate" type="date" label="Date Received" />
              </div>
              <Input name="summary" type="textarea" />

              <div>
                <Input
                  name="key"
                  type="checkbox"
                  inline
                  label={
                    <>
                      <IconStar /> Key File
                    </>
                  }
                />

                {project ? (
                  <>
                    <Input
                      name="printed"
                      type="checkbox"
                      inline
                      label={
                        <>
                          <IconPrint /> Printed
                        </>
                      }
                    />
                    <Input
                      name="hidden"
                      type="checkbox"
                      inline
                      label={
                        <>
                          <IconHide /> Hide on Client Access
                        </>
                      }
                    />
                  </>
                ) : null}

                {project?.mode === "Overhead" && !referenceDoc && (
                  <Input
                    name="library"
                    type="checkbox"
                    inline
                    label={
                      <>
                        <IconBook /> Show in Library
                      </>
                    }
                  />
                )}
              </div>
            </>
          )}
          {source?.pile && <Input name="date" type="date" />}
          {data.mode === "Header" && project?.billable && (
            <Input
              name="hidden"
              type="checkbox"
              inline
              label="Hide Section on Client Access?"
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <RemixFormContext.Consumer>
            {(form) =>
              form &&
              form.state === "loading" &&
              source &&
              source.fileCount + source.folderCount >= 1000 &&
              (source.document?.id || "") !== documentId && (
                <Alert mode="info" className="text-left">
                  This folder has many nested entries. Saving may take several
                  seconds, please be patient.
                </Alert>
              )
            }
          </RemixFormContext.Consumer>

          <Buttons
            modal
            primaryLabel={source ? "Update" : "Add"}
            savingLabel="Updating..."
          />
          {data.mode === "Header" && project?.billable && (
            <div className="text-left">
              <hr />
              <h4>Standard Table of Contents</h4>
              <FileTOC />
            </div>
          )}
        </Modal.Footer>
      </RemixForm>
    </Modal>
  );
};
