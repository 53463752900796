import _ from "lodash";
import { useMemo } from "react";
import type { ComboBoxParentPropsSingle } from "~/components/combobox";
import ComboBox from "~/components/combobox";
import type { AuditTemplateQuery } from "~/types/api";
import { useFetcherData } from "~/utils/remix";

export type AuditTemplateItemPickerProps = ComboBoxParentPropsSingle & {
  auditTemplateId?: string;
};

export default function AuditTemplateItemPicker({
  auditTemplateId,
  ...rest
}: AuditTemplateItemPickerProps) {
  const fetcher = useFetcherData<AuditTemplateQuery>(
    auditTemplateId
      ? `/resources/audits/templates/${auditTemplateId}`
      : undefined
  );

  const options = useMemo(() => {
    const sorted = _.sortBy(
      fetcher.data?.auditTemplate.auditTemplateItems || [],
      "rank"
    );
    return [
      { value: "", label: "Average of All Sections" },
      ...sorted.map((item) => ({
        value: item.id,
        label: item.title || item.description!
      }))
    ];
  }, [fetcher.data]);

  return (
    <ComboBox
      placeholder="Select an Audit Section..."
      {...rest}
      options={options}
    />
  );
}
