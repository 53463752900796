import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Alert from "~/components/alert";
import ButtonCloseModal from "~/components/button-close-modal";
import { IconCheck } from "~/components/icons";
import Modal from "~/components/modal";
import { Buttons, Errors, Input, RemixForm } from "~/components/remix-form";
import { FEATURE_CATEGORIES, PRIORITIES } from "~/constants";
import type { ShowFeatureFieldsFragment } from "~/types/api";
import { useAllowed, useCurrentUser } from "~/utils/auth";
import { formatDate } from "~/utils/dates";

type Props = {
  source?: ShowFeatureFieldsFragment;
  modal?: boolean;
};

export const FEATURE_STATUSES = [
  "Not Started",
  "In Progress",
  "On Hold",
  "Needs Discussion",
  "Rejected",
  "Done"
];

export default function FeatureForm({ source, modal }: Props) {
  const currentUser = useCurrentUser();
  const [params] = useSearchParams();
  const [complete, setComplete] = useState(false);

  const data = source
    ? {
        ...source,
        featureId: source.feature?.id,
        hours: source.hours ? source.hours.toString() : "",
        userId: source.user.id,
        completedOn: formatDate(new Date(), { format: "YYYY-MM-DD" })
      }
    : {
        featureId: params.get("feature_id") || "",
        userId: currentUser.id,
        category: params.get("category") || "",
        status: "Not Started",
        completedOn: formatDate(new Date(), { format: "YYYY-MM-DD" })
      };

  const [status, setStatus] = useState(data.status);
  const isAdmin = useAllowed("Admin/Developer");

  const featurePicker = source?.features.length ? (
    <p className="help-block">
      You can&apos;t nest this feature, as it already has other features nested
      underneath it.
    </p>
  ) : (
    <Input name="featureId" type="feature" label="Parent Feature" />
  );

  const basicFields = (
    <>
      {isAdmin ? (
        <Input name="userId" type="user" label="Added By" isClearable={false} />
      ) : (
        <input type="hidden" name="userId" value={data.userId} />
      )}
      <Input
        name="category"
        type="combo"
        options={FEATURE_CATEGORIES}
        autoFocus
      />
      <Input
        name="featureType"
        type="button-select"
        options={["Bug", "Feature Request"]}
      />
      <Input name="priority" type="combo" options={PRIORITIES} />
      <Input name="title" />
      <Input name="descriptionSlate" type="slate" label="Description" />
      <Input name="uploadIds" type="upload" label="Attachments" multiple />
    </>
  );

  if (complete) {
    return (
      <>
        <Modal.Body>
          <Alert mode="success">
            <IconCheck /> Your request has been submitted!
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <ButtonCloseModal />
        </Modal.Footer>
      </>
    );
  }

  if (modal) {
    return (
      <RemixForm
        data={data}
        fetcher
        action="/resources/features/save"
        onSuccess={() => setComplete(true)}
      >
        <Modal.Body>
          <Errors />
          {basicFields}
        </Modal.Body>
        <Modal.Footer>
          <Buttons modal />
        </Modal.Footer>
      </RemixForm>
    );
  }
  return (
    <RemixForm data={data} redirect>
      <fieldset>
        <legend>{source ? "Edit" : "New"} Feature Request/Bug Report</legend>
        <Errors />
        <div className="row">
          <div className="col-md-6">
            {basicFields}
            {isAdmin && featurePicker}
          </div>
          <div className="col-md-6">
            {isAdmin && (
              <>
                <Input
                  name="scale"
                  type="button-select"
                  options={[
                    ["Small", "Small (Hours)"],
                    ["Medium", "Medium (Days)"],
                    ["Large", "Large (Weeks/Months)"]
                  ]}
                />
                <Input name="hours" />
                <Input
                  name="status"
                  value={status}
                  onChange={(_name, value) => setStatus(value)}
                  type="button-select"
                  options={[
                    "Not Started",
                    "In Progress",
                    "On Hold",
                    "Needs Discussion",
                    "Rejected",
                    "Done"
                  ]}
                />
                <Input name="closeNote" type="textarea" />
                {["Done", "Rejected"].includes(status) && (
                  <Input name="completedOn" type="date" />
                )}
                {status === "Done" && (
                  <Input
                    name="skipEmail"
                    type="checkbox"
                    label="Skip Email"
                    inline
                  />
                )}
              </>
            )}
          </div>
        </div>
        <Buttons
          footer
          redirectTo={source ? `/features/${source.id}` : "/features"}
        />
      </fieldset>
    </RemixForm>
  );
}
