import _ from "lodash";
import { useMemo } from "react";
import type { ComboBoxParentPropsSingle } from "~/components/combobox";
import ComboBox from "~/components/combobox";
import type { PayPeriodsQuery } from "~/types/api";
import { formatDate } from "~/utils/dates";
import { useFetcherData } from "~/utils/remix";

export type PayPeriodPickerProps = ComboBoxParentPropsSingle & {
  unprocessed?: boolean;
  oldestFirst?: boolean;
};

export default function PayPeriodPicker({
  placeholder = "Select a Pay Period...",
  oldestFirst,
  unprocessed,
  value,

  ...rest
}: PayPeriodPickerProps) {
  const fetcher = useFetcherData<PayPeriodsQuery>(
    "/resources/pay-periods/picker"
  );

  const payPeriods = useMemo(
    () => fetcher.data?.payPeriods || [],
    [fetcher.data]
  );

  const options = useMemo(
    () =>
      _.orderBy(
        payPeriods.filter((pp) => !unprocessed || !pp.processed),
        "endDate",
        oldestFirst ? "asc" : "desc"
      ).map((pp) => ({
        value: pp.id,
        label: formatDate(pp.endDate)
      })),
    [payPeriods, unprocessed, oldestFirst]
  );

  const val =
    value === "current" && payPeriods.length ? _.last(payPeriods)!.id : value;

  return (
    <ComboBox
      {...rest}
      value={val}
      placeholder={placeholder}
      options={options}
    />
  );
}
