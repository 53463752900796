import _ from "lodash";
import { useMemo } from "react";
import type { ComboBoxParentProps } from "~/components/combobox";
import ComboBox from "~/components/combobox";
import { useProject } from "~/contexts";
import type { DispatchesQuery } from "~/types/api";
import { formatDate } from "~/utils/dates";
import { useFetcherData } from "~/utils/remix";

export default function DispatchPicker(props: ComboBoxParentProps) {
  const project = useProject();
  const fetcher = useFetcherData<DispatchesQuery>(
    "/resources/dispatches/picker",
    { number: project.number }
  );

  const options = useMemo(
    () =>
      _.sortBy(fetcher.data?.dispatches || [], "date", "category").map((d) => ({
        value: d.id,
        label: `${formatDate(d.date)} - ${d.category} - ${d.attendee.fullname}`
      })),
    [fetcher.data]
  );

  return <ComboBox {...props} options={options} />;
}
