import type React from "react";
import { useEffect, useState } from "react";
import Button from "~/components/button";
import { IconSearch, IconX } from "~/components/icons";
import { useLocationPusher } from "~/components/link";
import { Input, RemixForm } from "~/components/remix-form";

type PropsBase = {
  autoFocus?: boolean;
  live?: boolean;
  initialValue?: string;
  placeholder?: string;
  width?: number | string;
  small?: boolean;
  name?: string;
};
type PropsRedirect = PropsBase & {
  redirect: true;
  onSearch?: undefined;
};

type PropsCallback = PropsBase & {
  onSearch: (filter: string) => void;
  redirect?: undefined;
};

export default function SearchFilter({
  onSearch,
  autoFocus,
  live,
  name = "search",
  small,
  width = 300,
  initialValue = "",
  placeholder = "Filter...",
  redirect
}: PropsRedirect | PropsCallback) {
  const [filter, setFilter] = useState(initialValue);
  const push = useLocationPusher();

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    redirect ? push({ [name]: filter }) : onSearch(filter.trim());
  };

  const clear = () => {
    setFilter("");
    redirect ? push({ [name]: undefined }) : onSearch("");
  };

  useEffect(() => {
    if (live) {
      onSearch?.(filter.trim());
    }
  }, [onSearch, live, filter]);

  useEffect(() => {
    setFilter(initialValue);
  }, [initialValue]);

  return (
    <RemixForm onSubmit={submit} prompt={false}>
      <Input
        name="filter"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        noLabel
        className={small ? "h-[24px]" : "h-[28px]"}
        placeholder={placeholder}
        wrapperStyle={{ width, marginBottom: 0 }}
        buttons={
          <>
            {!live && (
              <Button small={small} type="submit">
                <IconSearch />
              </Button>
            )}
            <Button small={small} onClick={clear}>
              <IconX />
            </Button>
          </>
        }
        autoFocus={autoFocus}
      />
    </RemixForm>
  );
}
