import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import Alert from "~/components/alert";
import Button from "~/components/button";
import { IconLoading } from "~/components/icons";
import InspectionPicker from "~/components/photos/inspection-picker";
import PhotoThumbnail from "~/components/photos/thumbnail";
import { useOptionalProject } from "~/contexts";
import type {
  PhotoViewerFieldsFragment,
  PhotoViewerQuery,
  ViewOnePhotoQuery
} from "~/types/api";
import { useFetcherData } from "~/utils/remix";

export interface PhotoPickerProps {
  value?: string;
  defaultValue?: string;
  name: string;
  onChange: (name: string, value: string) => void;
}

export default function PhotoPicker(props: PhotoPickerProps) {
  const val = Reflect.has(props, "value") ? props.value : props.defaultValue;
  const project = useOptionalProject();
  const fetcher = useFetcherData<ViewOnePhotoQuery>(
    val ? `/resources/photos/${val}/viewer-one` : undefined
  );

  if (!project) {
    return <span>No project</span>;
  }

  return <Inner {...props} value={val} photo={fetcher.data?.photo} />;
}

interface InnerProps extends Omit<PhotoPickerProps, "projectId"> {
  photo?: PhotoViewerFieldsFragment;
}

const Inner = ({ value, name, onChange, photo }: InnerProps) => {
  const [photosetId, setPhotosetId] = useState(photo ? photo.photoset.id : "");
  const [page, setPage] = useState(0);
  const fetcher = useFetcherData<PhotoViewerQuery>(
    photosetId ? "/resources/photos/photo-viewer" : undefined,
    {
      parentType: "photoset",
      parentId: photosetId
    }
  );
  const photos = useMemo(() => fetcher.data?.photos || [], [fetcher.data]);

  const sorted = useMemo(() => _.sortBy(photos, "imageNumber"), [photos]);
  const viewing = sorted.slice(page * 15, page * 15 + 15);
  useEffect(() => {
    if (sorted && value) {
      const index = sorted.findIndex((p) => p.id === value);
      if (index >= 0) {
        setPage(Math.floor(index / 15));
      }
    }
  }, [sorted, value]);
  return (
    <div>
      <InspectionPicker
        name="photosetId"
        value={photosetId}
        onChange={(name, id) => {
          setPage(0);
          setPhotosetId(id as string);
        }}
      />
      {photosetId && (
        <div className="mt-4">
          {fetcher.state === "loading" && !photos.length ? (
            <IconLoading />
          ) : !photos.length ? (
            <Alert mode="warning">
              The selected investigation has no photos!
            </Alert>
          ) : (
            <>
              <div className="photo-thumbs">
                {viewing.map((p) => (
                  <PhotoThumbnail
                    key={p.id}
                    photo={p}
                    selected={p.id === value}
                    onClick={(id) => onChange(name, id)}
                  />
                ))}
              </div>
              <div className="clearfix" />
              <hr className="tight" />
              <div className="flex justify-between">
                <em>
                  Photos {page * 15 + 1}-{page * 15 + viewing.length} of{" "}
                  {sorted.length}
                </em>
                <div>
                  <Button
                    disabled={page === 0}
                    onClick={() => setPage((page) => page - 1)}
                  >
                    Prev
                  </Button>
                  <Button
                    disabled={page === Math.ceil(photos.length / 15) - 1}
                    onClick={() => setPage((page) => page + 1)}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};
