import { categoricalColorSchemes } from "@nivo/colors";
import type { CartesianMarkerProps } from "@nivo/core";
import type {
  CustomLayerProps,
  Layer,
  LineSvgProps,
  Point,
  PointTooltip,
  Serie
} from "@nivo/line";
import { ResponsiveLine } from "@nivo/line";
import type { NumericValue, Scale } from "@nivo/scales";
import type { NumberValue } from "d3-scale";
import _ from "lodash";
import { mean, std } from "mathjs";
import React, { Fragment } from "react";
import { formatDate } from "~/utils/dates";
import type { KpiValueType } from "~/utils/kpis";
import { formatRawValue } from "~/utils/kpis";

export const GOOGLE_CHART_COLORS = {
  goal: "#337AB7",
  target: "#F0AD4E",
  actual: "#5CB85C",
  missed: "#D9534F"
} as const;

export type ChartScale = "Day" | "Week" | "Month" | "Year";
export type MyCustomLayerProps = Omit<CustomLayerProps, "xScale" | "yScale"> & {
  xScale: Scale<NumericValue, NumericValue>;
  yScale: Scale<NumericValue, NumericValue>;
};

type DataPoint = Point["data"] & {
  link?: string;
};

export interface MyDatum {
  x: Date | string;
  y: number | null;
  link?: string | null;
  startGroup?: boolean;
  format?: KpiValueType | null;
  extra?: string;
  xLabel?: string;
}

const calculateTrendline = (data: MyDatum[], months: number): MyDatum[] => {
  const trends = data.map((item, index): MyDatum => {
    const first = Math.max(index - months + 1, 0);
    const items = data.slice(first, index + 1);
    const count = items.length;
    const sum = _.sumBy(items, (i) => i.y || 0);
    const avg = sum / count;
    return {
      x: item.x,
      y: avg
    };
  }, []);

  return trends;
};

export const calculateControlLimits = (
  data: MyDatum[],
  maxPossible: number | undefined = undefined,
  standardDeviations = 3
): [number, number, number] => {
  const median = calculateMedian(data)!;
  const stdDev = std(...data.map((d) => d.y!));
  const ucl = maxPossible
    ? Math.min(median + stdDev * standardDeviations, maxPossible)
    : median + stdDev * standardDeviations;
  const lcl = Math.max(median - stdDev * standardDeviations, 0);
  return [lcl, ucl, median];
};

export const calculateMedian = (data: MyDatum[]): number | undefined =>
  !data.length ? undefined : mean(data.map((i) => i.y || 0));

type BaseProps = {
  data: MyDatum[];
  additionalData?: Array<{ id: string; data: MyDatum[] }>;
  ucl?: number;
  standardDeviations?: number;
  maxUcl?: number;
  minUcl?: number;
  controlLimits?: boolean;
  median?: boolean;
  controlGroups?: number;
  tooltip?: PointTooltip;
  prefix?: string;
  legends?: boolean;
  primaryDataId?: string;
};

type TimeProps = BaseProps & {
  type: "Time";
  scale: ChartScale;
  trendMonths?: number;
};

type PointsProps = BaseProps & {
  type: "Points";
};

export default React.memo(function Chart(props: TimeProps | PointsProps) {
  const {
    data,
    additionalData,
    maxUcl,
    ucl,
    controlGroups,
    tooltip,
    controlLimits,
    standardDeviations = 3,
    median: showMedian,
    prefix,
    legends,
    primaryDataId
  } = props;
  const median = data.map((d) => d.y!);
  const format = data[0].format;
  const values: Serie[] = [
    { id: primaryDataId || "data", data },
    ...(additionalData || [])
  ];
  const all = values.flatMap(
    (v) => _.maxBy(v.data as MyDatum[], (d) => d.y || 0)?.y || 0
  );
  const maxValue = (_.max(all) || 0) * 1.1;

  if (props.type === "Time" && props.trendMonths) {
    values.push({
      id: "trends",
      data: calculateTrendline(data, props.trendMonths)
    });
  }

  const markers: CartesianMarkerProps[] = [];

  const CustomLayer = (props: MyCustomLayerProps) => {
    if (!controlLimits) return null;
    let chunks = [data];
    if (controlGroups) {
      chunks = _.chunk(data, Math.ceil(data.length / controlGroups));
    } else if (data.some((d) => d.startGroup)) {
      chunks = [];
      for (const datum of data) {
        if (datum.startGroup) {
          chunks.push([datum]);
        } else {
          _.last(chunks)!.push(datum);
        }
      }
    }
    return (
      <>
        {chunks.map((chunk, i) => {
          const first = _.first(chunk)!;
          const last = _.last(chunk)!;
          const [l, u, median] = calculateControlLimits(
            chunk,
            maxUcl,
            standardDeviations
          );
          const prevUclPoint =
            i === 0
              ? undefined
              : {
                  x: props.xScale(
                    _.last(chunks[i - 1])!.x as NumberValue
                  ) as number,
                  y: props.yScale(
                    calculateControlLimits(
                      chunks[i - 1],
                      maxUcl,
                      standardDeviations
                    )[1]
                  ) as number
                };
          const prevMedian =
            i === 0
              ? undefined
              : {
                  x: props.xScale(
                    _.last(chunks[i - 1])!.x as NumberValue
                  ) as number,
                  y: props.yScale(
                    calculateControlLimits(
                      chunks[i - 1],
                      maxUcl,
                      standardDeviations
                    )[2]
                  ) as number
                };
          const prevLclPoint =
            i === 0
              ? undefined
              : {
                  x: props.xScale(
                    _.last(chunks[i - 1])!.x as NumberValue
                  ) as number,
                  y: props.yScale(
                    calculateControlLimits(
                      chunks[i - 1],
                      maxUcl,
                      standardDeviations
                    )[0]
                  ) as number,
                  median: props.yScale(median)
                };

          return (
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            <Fragment key={i}>
              {prevUclPoint && (
                <path
                  d={
                    props.lineGenerator([
                      prevUclPoint,
                      {
                        x: props.xScale(first.x as NumberValue) as number,
                        y: props.yScale(u) as number
                      }
                    ]) as string
                  }
                  fill="none"
                  stroke="#FF0000"
                  strokeWidth={2}
                />
              )}
              {prevMedian && (
                <path
                  d={
                    props.lineGenerator([
                      prevMedian,
                      {
                        x: props.xScale(first.x as NumberValue) as number,
                        y: props.yScale(median) as number
                      }
                    ]) as string
                  }
                  fill="none"
                  stroke="green"
                  strokeWidth={2}
                />
              )}
              {prevLclPoint && (
                <path
                  d={
                    props.lineGenerator([
                      prevLclPoint,
                      {
                        x: props.xScale(first.x as NumberValue) as number,
                        y: props.yScale(l) as number
                      }
                    ]) as string
                  }
                  fill="none"
                  stroke="#FF0000"
                  strokeWidth={2}
                />
              )}
              <path
                d={
                  props.lineGenerator([
                    {
                      x: props.xScale(first.x as NumberValue) as number,
                      y: props.yScale(u) as number
                    },
                    {
                      x: props.xScale(last.x as NumberValue) as number,
                      y: props.yScale(u) as number
                    }
                  ]) as string
                }
                fill="none"
                stroke="#FF0000"
                strokeWidth={2}
              />
              <path
                d={
                  props.lineGenerator([
                    {
                      x: props.xScale(first.x as NumberValue) as number,
                      y: props.yScale(l) as number
                    },
                    {
                      x: props.xScale(last.x as NumberValue) as number,
                      y: props.yScale(l) as number
                    }
                  ]) as string
                }
                fill="none"
                stroke="#FF0000"
                strokeWidth={2}
              />
              <path
                d={
                  props.lineGenerator([
                    {
                      x: props.xScale(first.x as NumberValue) as number,
                      y: props.yScale(median) as number
                    },
                    {
                      x: props.xScale(last.x as NumberValue) as number,
                      y: props.yScale(median) as number
                    }
                  ]) as string
                }
                fill="none"
                stroke="green"
                strokeWidth={2}
              />
            </Fragment>
          );
        })}
      </>
    );
  };

  if (median && showMedian && !controlLimits) {
    const value = calculateMedian(data);
    if (value !== undefined) {
      markers.push({
        axis: "y",
        value,
        lineStyle: {
          strokeWidth: 2,
          stroke: "green"
        }
      });
    }
  }

  let max: number | "auto" = "auto";

  if (data.length) {
    const values: number[] = [];
    values.push(maxValue);
    // values.push(_.maxBy(data, (i) => i.y || 0)!.y! * 1.1);
    if (ucl) {
      values.push(ucl);
    }
    if (maxUcl) {
      values.push(maxUcl);
    }
    max = Math.max(...values);
  }

  const colors = categoricalColorSchemes.category10;

  const commonProps: LineSvgProps = {
    data: values,
    colors: { scheme: "category10" },
    margin: {
      top: 10,
      right: legends ? 110 : 5,
      bottom: 50,
      left: maxValue >= 1_000_000 ? 80 : 60
    },
    yScale: {
      type: "linear",
      max,
      // max: "auto",
      stacked: false,
      reverse: false
    },
    markers,
    onMouseEnter: (_p, e) => {
      (e.currentTarget as HTMLElement).style.cursor = "pointer";
    },
    pointSize: 3,
    pointColor: { theme: "background" },
    pointBorderWidth: 2,
    pointBorderColor: { from: "serieColor" },
    pointLabelYOffset: -12,
    enableGridX: false,
    useMesh: true,
    enableCrosshair: true,
    axisLeft: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0
    },
    axisTop: null,
    axisRight: null,
    onClick: (x) => {
      const link = (x.data as DataPoint).link;
      if (link) {
        window.open(link, "_blank");
      }
    },
    layers: [
      "grid",
      "markers",
      "axes",
      "areas",
      "crosshair",
      "lines",
      CustomLayer as unknown as Layer,
      "points",
      "slices",
      "mesh",
      "legends"
    ],
    legends: legends
      ? [
          {
            anchor: "top-right",
            direction: "column",
            justify: false,
            translateX: 110,
            data: values.map((d, i) => ({
              id: d.id,
              label: d.id,
              color: colors[i]
            })),
            // translateY: 50,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 100,
            itemHeight: 20,
            itemOpacity: 0.75,
            toggleSerie: true,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)"
          }
        ]
      : undefined
  };

  if (props.type === "Time") {
    return (
      <ResponsiveLine
        {...commonProps}
        xScale={{ type: "time", format: "native", useUTC: false }}
        tooltip={(x) => {
          return (
            <div className="border border-gray-300 bg-white p-4 shadow-md">
              {props.scale === "Week" && "Week of "}
              {x.point.serieId === "trends" && "Average as of "}
              {x.point.serieId === "data" && prefix && `${prefix} - `}
              {!["data", "trends"].includes(x.point.serieId as string) &&
                `${x.point.serieId} - `}
              {(x.point.data as MyDatum).xLabel ||
                formatDate(x.point.data.x, {
                  format:
                    props.scale === "Year"
                      ? "YYYY"
                      : props.scale === "Month"
                        ? "MMM YYYY"
                        : "L"
                })}
              :{" "}
              <strong>
                {formatRawValue(x.point.data.y as number, format, {
                  zeroes: true
                })}
              </strong>
            </div>
          );
        }}
        xFormat="time:%Y-%m-%d"
        axisBottom={{
          format: "%b %Y",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: 36
        }}
      />
    );
  }

  return (
    <ResponsiveLine
      {...commonProps}
      xScale={{ type: "point" }}
      tooltip={tooltip}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -90,
        tickValues: [],
        legendOffset: 36
      }}
    />
  );
});
