import clsx from "clsx";
import type { CSSProperties, ReactNode } from "react";
import type React from "react";
import type { ConnectDropTarget } from "react-dnd";
import Body from "~/components/panel-body";
import Footer from "~/components/panel-footer";
import Header from "~/components/panel-header";

export type PanelMode = "default" | "info" | "warning" | "danger" | "success";

type PanelProps = {
  className?: string;
  style?: CSSProperties;
  title?: React.ReactNode;
  mode?: PanelMode;
  innerRef?: React.RefObject<HTMLDivElement> | ConnectDropTarget;
  children?: ReactNode;
};

const Panel = ({
  children,
  className,
  style,
  mode = "default",
  innerRef,
  title
}: PanelProps) => (
  <div
    className={clsx(`panel panel-${mode}`, className)}
    style={style}
    ref={innerRef}
  >
    {title && <Header title={title} />}
    {children}
  </div>
);

Panel.Header = Header;
Panel.Body = Body;
Panel.Footer = Footer;

export default Panel;
