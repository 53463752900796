import { useState } from "react";
import type { ComboBoxParentPropsSingle } from "~/components/combobox";
import Combobox from "~/components/combobox";

type Props = ComboBoxParentPropsSingle;

const JOB_TITLES = [
  "Associate",
  "Attorney",
  "Founder",
  "Managing Partner",
  "Member",
  "Named Partner",
  "Of Counsel",
  "Paralegal",
  "Partner",
  "Senior Counsel",
  "Shareholder"
];

export default function JobTitlePicker({ value, ...rest }: Props) {
  const [newOptions, setNewOptions] = useState<string[]>([
    ...JOB_TITLES,
    ...(value && !JOB_TITLES.includes(value) ? [value] : [])
  ]);
  const handleCreate = (option: string) => {
    if (option && !newOptions.includes(option)) {
      setNewOptions([...newOptions, option]);
    }
  };
  return (
    <Combobox
      options={newOptions}
      placeholder="Choose or add a new title..."
      creatable
      onCreateOption={handleCreate}
      {...rest}
    />
  );
}
