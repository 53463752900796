import DocumentList from "~/components/documents/list";
import { IconLoading } from "~/components/icons";
import type {
  DocumentRowFieldsFragment,
  LibrarySearchQuery
} from "~/types/api";
import { useFetcherData } from "~/utils/remix";

interface Props {
  query: string;
  onChange?: (document: DocumentRowFieldsFragment) => void;
  thumbnails?: boolean;
  addedIds?: string[];
  keyFiles?: boolean;
  selectedIds?: string[];
  setSelectedIds?: (ids: string[]) => void;
}

export default function LibraryList({
  query,
  onChange,
  addedIds,
  keyFiles,
  selectedIds,
  setSelectedIds
}: Props) {
  const fetcher = useFetcherData<LibrarySearchQuery>(
    "/resources/documents/library",
    { query, key: keyFiles }
  );

  return fetcher.state !== "idle" || !fetcher.data ? (
    <div style={{ margin: "0 15px 15px 15px" }}>
      <IconLoading />
    </div>
  ) : (
    <>
      <DocumentList
        ordered
        documents={fetcher.data.librarySearch.results}
        minimal
        viewOptions={{ includeSource: true, hideSections: true }}
        onAdd={onChange}
        disabledIds={addedIds}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
      />
    </>
  );
}
