import type { Fetcher } from "@remix-run/react";
import type { GraphQLError } from "graphql";
import _ from "lodash";
import type React from "react";
import { useContext, useEffect, useRef } from "react";
import Alert from "~/components/alert";
import { RemixFormContext } from "~/contexts";

export type ChangesetErrors = {
  action: string;
  errors: { [key: string]: string[] };
};

export type ErrorsWithChangeset = Array<
  Partial<GraphQLError> & { changeset?: ChangesetErrors }
>;

export const Errors = (props: {
  className?: string;
  errors?: ErrorsWithChangeset | string | null;
}) => {
  const divRef = useRef<HTMLDivElement | null>(null);
  const remixForm = useContext(RemixFormContext);
  const errors = props.errors || remixForm?.errors;
  useEffect(() => {
    if (
      errors &&
      divRef.current &&
      divRef.current.getBoundingClientRect().top < 0
    ) {
      divRef.current.scrollIntoView();
    }
  }, [errors]);

  const traverseErrors = (
    errors: { [key: string]: string[] | { [key: string]: string[] } },
    prefix = ""
  ): React.ReactNode[] => {
    let result: React.ReactNode[] = [];
    _.each(errors, (value, key) => {
      if (_.isArray(value)) {
        value.forEach((subValue, index) => {
          if (_.isString(subValue)) {
            const listKey = `${prefix}${key}${index}`;
            result.push(
              <li key={listKey}>
                <strong>
                  {prefix ? `${prefix}: ` : ""}
                  {getKey(key)}
                </strong>{" "}
                {subValue}
              </li>
            );
          } else {
            result = result.concat(
              traverseErrors(subValue, `${_.startCase(key)} (Row ${index + 1})`)
            );
          }
        });
      } else {
        result = result.concat(traverseErrors(value, _.startCase(key)));
      }
    });

    return result;
  };

  if (!errors) {
    divRef.current = null;
    return null;
  }

  if (_.isString(errors)) {
    return (
      <Alert mode="danger" ref={divRef} className={props.className}>
        <h4>Unhandled Error</h4>
        <p>{errors}</p>
        <p>Please contact Dylan if this error persists.</p>
      </Alert>
    );
  }

  if (errors.length > 0) {
    const match = errors.find((x) => x.changeset);
    if (match) {
      const allErrors = traverseErrors(match.changeset!.errors);
      const deleting = match.changeset!.action === "delete";

      const errorCount = allErrors.length;
      return (
        <Alert mode="danger" ref={divRef} className={props.className}>
          <h4>
            {errorCount === 1 ? "1 error" : `${errorCount} errors`} prevented{" "}
            {deleting
              ? "this item from being deleted:"
              : "your changes from being saved:"}
          </h4>
          <ul>{allErrors}</ul>
        </Alert>
      );
    }
    return (
      <Alert mode="danger" ref={divRef} className={props.className}>
        <h4>Error</h4>

        {errors.length === 1 ? (
          <p>{errors[0].message}</p>
        ) : (
          <ul>
            {errors.map((e) => (
              <li key={e.message}>{e.message}</li>
            ))}
          </ul>
        )}
      </Alert>
    );
  }

  return null;
};

export const useErrorAlert = (
  fetcher: Fetcher,
  message = "There was an error saving your changes"
) => {
  useEffect(() => {
    if (fetcher.state === "idle" && fetcher.data?.errors) {
      console.log(fetcher.data?.errors);
      window.alert(message);
    }
  }, [fetcher.data?.errors, fetcher.state, message]);
};

const getKey = (key: string): string => {
  switch (key) {
    case "base":
      return "";
    case "milestone_parent_id":
      return "Plan Level";
    default:
      return _.startCase(key.replace(/_(id|slate)$/, ""));
  }
};

export type MyError = {
  graphQLErrors?: ErrorsWithChangeset;
};
