import { useLoaderData } from "@remix-run/react";
import { useMemo } from "react";
import Badge from "~/components/badge";
import type {
  ComboBoxOption,
  ComboBoxParentPropsMultiple,
  ComboBoxParentPropsSingle
} from "~/components/combobox";
import ComboBox from "~/components/combobox";
import { useProject } from "~/contexts";
import type { LocationsPickerQuery } from "~/types/api";
import { flattenLocations } from "~/utils/bpa";
import DisplayNames from "~/utils/display-names";
import { plural } from "~/utils/formatting";
import { useFetcherData } from "~/utils/remix";

export type LocationPickerPropsSingle = ComboBoxParentPropsSingle & {
  disableNoPhotos?: boolean;
  untagged?: boolean;
  keyPhotoCount?: boolean;
  excludeId?: string;
  topLevelOnly?: boolean;
};
export type LocationPickerPropsMulti = ComboBoxParentPropsMultiple & {
  disableNoPhotos?: boolean;
  untagged?: boolean;
  keyPhotoCount?: boolean;
  excludeId?: string;
  topLevelOnly?: boolean;
};

export default function LocationPicker({
  untagged,
  disableNoPhotos,
  keyPhotoCount,
  excludeId,
  topLevelOnly,
  ...rest
}: LocationPickerPropsSingle | LocationPickerPropsMulti) {
  const project = useProject();
  const loader = useLoaderData<{
    locationPicker?: LocationsPickerQuery;
  } | null>();
  const fetcher = useFetcherData<LocationsPickerQuery>(
    loader?.locationPicker ? undefined : "/resources/locations/picker",
    { projectId: project.id }
  );

  const options = useMemo(() => {
    let options: ComboBoxOption[] = flattenLocations(
      loader?.locationPicker?.locations || fetcher.data?.locations || []
    )
      .filter((l) => l.id !== excludeId)
      .map((l) => {
        const count = l.pfcsPhotoCount + l.otherPhotoCount;
        const disabled = disableNoPhotos && count === 0;
        return {
          value: l.id,
          label: `${DisplayNames.location(l)}${
            disabled && disableNoPhotos ? " (No Photos)" : ""
          }`,
          disabled: disabled,
          rightLabel:
            keyPhotoCount && l.pfcsKeyPhotoCount + l.otherKeyPhotoCount ? (
              <Badge
                mode="primary"
                label={plural(
                  "Key Photo",
                  l.pfcsKeyPhotoCount + l.otherKeyPhotoCount,
                  true
                )}
              />
            ) : undefined,
          indentLevel: l.indent
        };
      });
    if (topLevelOnly) {
      options = options.filter((o) => o.indentLevel === 0);
    }

    if (untagged) {
      options.push({
        value: "untagged",
        label: "*** Without Locations ***"
      });
    }
    return options;
  }, [
    loader?.locationPicker?.locations,
    fetcher.data?.locations,
    topLevelOnly,
    untagged,
    excludeId,
    disableNoPhotos,
    keyPhotoCount
  ]);

  return (
    <ComboBox placeholder="Select a Location..." {...rest} options={options} />
  );
}
