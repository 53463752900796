import _ from "lodash";
import { useCallback, useMemo } from "react";
import invariant from "tiny-invariant";
import type { ComboBoxParentPropsSingle } from "~/components/combobox";
import ComboBox from "~/components/combobox";
import type { KpiSetPickerQuery } from "~/types/api";
import { useFetcherData } from "~/utils/remix";

export default function KpiSetPicker({
  placeholder = "No KPIs",
  ...props
}: ComboBoxParentPropsSingle) {
  const fetcher = useFetcherData<KpiSetPickerQuery>(
    "/resources/kpis/set-picker"
  );

  const options = useMemo(() => {
    return _.sortBy(
      (fetcher.data?.kpiSets || []).filter((s) => !s.globalSet),
      "name"
    ).map((ks) => ({
      value: ks.id,
      label: ks.name
    }));
  }, [fetcher.data]);

  const localOnChange = useCallback(
    (name: string, value: string) => {
      invariant(!props.multiple, "Cannot use single mode with multiple");
      const set = fetcher.data?.kpiSets.find((s) => s.id === value);
      props.onChange?.(name, value, set);
    },
    [fetcher.data, props.onChange, props.multiple]
  );

  return (
    <ComboBox
      options={options}
      placeholder={placeholder}
      {...props}
      onChange={localOnChange}
    />
  );
}
