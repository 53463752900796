import { useFetcher } from "@remix-run/react";
import { useEffect, useRef, useState } from "react";
import type { ModalProps } from "~/components/modal";
import Modal from "~/components/modal";
import {
  Buttons,
  Errors,
  Input,
  RemixForm,
  fetcherSucceeded
} from "~/components/remix-form";
import type { MilestoneModes } from "~/components/scoreboard/milestones";
import type {
  DashboardMetricFieldsFragment,
  EditMetricQuery
} from "~/types/api";
import { formatDate } from "~/utils/dates";
import { useFetcherData } from "~/utils/remix";

interface FormProps extends ModalProps {
  id?: string;
  quarter?: string;
  mode?: MilestoneModes;
  source?: DashboardMetricFieldsFragment;
  project?: { id: string };
}

export default function DashboardMilestoneForm({ id, ...props }: FormProps) {
  const fetcher = useFetcherData<EditMetricQuery>(
    id ? `/resources/metrics/${id}` : undefined
  );
  if (id && !fetcher.data) {
    return null;
  }
  return <Form source={id ? fetcher.data?.metric : undefined} {...props} />;
}

const Form = ({ source, quarter, project, onClose, mode }: FormProps) => {
  const fetcher = useFetcher<unknown>();
  const data = source
    ? {
        id: source.id,
        userId: source.user?.id
      }
    : {
        projectId: project?.id,
        captureDate: formatDate(quarter, { format: "YYYY-MM-DD" }),
        category: `${mode} Milestone`
      };

  const [description, setDescription] = useState(
    source?.data.description || ""
  );
  const [value, setValue] = useState(
    source
      ? typeof source.data.value === "number"
        ? source.data.value.toString()
        : ""
      : "0"
  );

  const confirmDelete = () => {
    if (window.confirm("Are you sure you want to delete this milestone?")) {
      source &&
        fetcher.submit(
          { id: source.id },
          { action: "/resources/metrics/delete", method: "post" }
        );
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    fetcherSucceeded(fetcher) && onClose();
  }, [fetcher.state, onClose]);
  const ref = useRef<HTMLInputElement>(null);

  return (
    <Modal onExplicitClose={onClose} initialFocus={ref}>
      <RemixForm
        data={data}
        fetcher
        action="/resources/metrics/save"
        onSuccess={onClose}
      >
        {!source && (
          <>
            {data.projectId && (
              <input type="hidden" name="projectId" value={data.projectId} />
            )}
            <input type="hidden" name="captureDate" value={data.captureDate} />
            <input type="hidden" name="category" value={data.category} />
          </>
        )}
        <Modal.Header title="Big Rock/Milestone" />
        <Modal.Body>
          <Errors />
          <Input
            name="userId"
            type="user"
            label="Who"
            placeholder="Pick someone (or leave blank for Company Level)..."
          />
          <input
            type="hidden"
            name="data"
            value={JSON.stringify({ description, value })}
          />
          <Input
            name="data.description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            forwardRef={source ? undefined : ref}
            label="Big Rock"
          />
          <Input
            name="data.value"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            label="Percent Complete"
            forwardRef={source ? ref : undefined}
          />
        </Modal.Body>
        <Modal.Footer>
          <Buttons
            modal
            disabled={!description}
            onDanger={confirmDelete}
            dangerLabel={source ? "Delete" : undefined}
          />
        </Modal.Footer>
      </RemixForm>
    </Modal>
  );
};
