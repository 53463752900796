import { DialogTitle } from "@headlessui/react";
import type { ReactNode } from "react";
import ButtonCloseModal from "~/components/button-close-modal";

export interface ModalHeaderProps {
  title?: ReactNode;
}

export default function Header({ title }: ModalHeaderProps) {
  return (
    <DialogTitle as="div" className="modal-header">
      <ButtonCloseModal plain className="close">
        ×
      </ButtonCloseModal>

      <h4 className="modal-title">{title}</h4>
    </DialogTitle>
  );
}
