import { useLoaderData } from "@remix-run/react";
import { useMemo } from "react";
import Badge from "~/components/badge";
import type {
  ComboBoxOption,
  ComboBoxParentPropsMultiple,
  ComboBoxParentPropsSingle
} from "~/components/combobox";
import ComboBox from "~/components/combobox";
import { useProject } from "~/contexts";
import type { AddressesQuery } from "~/types/api";
import { flattenAddresses } from "~/utils/bpa";
import DisplayNames from "~/utils/display-names";
import { plural } from "~/utils/formatting";
import { useFetcherData } from "~/utils/remix";

export type AddressPickerSingleProps = ComboBoxParentPropsSingle & {
  keyPhotoCount?: boolean;
  untagged?: boolean;
  excludeId?: string;
  disableNoPhotos?: boolean;
};
export type AddressPickerMultiProps = ComboBoxParentPropsMultiple & {
  keyPhotoCount?: boolean;
  untagged?: boolean;
  excludeId?: string;
  disableNoPhotos?: boolean;
};

export default function AddressPicker({
  keyPhotoCount,
  untagged,
  excludeId,
  disableNoPhotos,
  ...rest
}: AddressPickerMultiProps | AddressPickerSingleProps) {
  const project = useProject();
  const loader = useLoaderData<{ addressPicker?: AddressesQuery } | null>();
  const fetcher = useFetcherData<AddressesQuery>(
    loader?.addressPicker ? undefined : "/resources/addresses/picker",
    { id: project.id }
  );

  const options = useMemo(() => {
    const options: ComboBoxOption[] = flattenAddresses(
      loader?.addressPicker?.project.addresses ||
        fetcher.data?.project.addresses ||
        []
    )
      .filter((a) => a.id !== excludeId)
      .map((a) => {
        const count = a.pfcsPhotoCount + a.otherPhotoCount;
        const disabled = disableNoPhotos && count === 0;
        return {
          value: a.id,
          rightLabel:
            keyPhotoCount && a.pfcsKeyPhotoCount + a.otherKeyPhotoCount ? (
              <Badge
                mode="primary"
                label={plural(
                  "Key Photo",
                  a.pfcsKeyPhotoCount + a.otherKeyPhotoCount,
                  true
                )}
              />
            ) : undefined,
          label: `${DisplayNames.address(a)}${
            disabled && disableNoPhotos ? " (No Photos)" : ""
          }`,
          disabled: disabled,
          indentLevel: a.indent
        };
      });

    if (untagged) {
      options.push({
        value: "untagged",
        label: "*** Without Addresses ***"
      });
    }
    return options;
  }, [
    fetcher.data,
    disableNoPhotos,
    excludeId,
    keyPhotoCount,
    untagged,
    loader?.addressPicker
  ]);

  return (
    <ComboBox placeholder="Select an Address..." {...rest} options={options} />
  );
}
