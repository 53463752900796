import * as Sentry from "@sentry/react";
import _ from "lodash";
import type { Node, Text } from "slate";
import { Element, Transforms } from "slate";
import { jsx } from "slate-hyperscript";
import type {
  CustomElement,
  CustomText,
  LinkElement,
  ListElement,
  MyEditor
} from "~/components/rich-editor";
import { insertSlateFragment } from "~/components/rich-editor/normalization";

const ELEMENT_TAGS: {
  [key: string]: (el?: HTMLElement) => Partial<CustomElement>;
} = {
  A: (el) => ({ type: "link", url: el!.getAttribute("href") || undefined }),
  H1: () => ({ type: "h1" }),
  H2: () => ({ type: "h2" }),
  H3: () => ({ type: "h3" }),
  H4: () => ({ type: "h4" }),
  H5: () => ({ type: "h5" }),
  LI: () => ({ type: "list-item" }),
  OL: () => ({ type: "ordered-list" }),
  P: () => ({ type: "paragraph" }),
  UL: () => ({ type: "unordered-list" }),
  TR: () => ({ type: "paragraph" })
};

const FORMAT_TAGS: {
  [key: string]: (el?: HTMLElement) => Partial<CustomText>;
} = {
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  STRONG: () => ({ bold: true }),
  B: () => ({ bold: true }),
  U: () => ({ underline: true })
};

const formatTagChildren = (
  children: (string | CustomText | CustomElement | null)[],
  attrs: Record<string, unknown>
) =>
  children.map((child) =>
    _.isObject(child) && "children" in child && child.children
      ? jsx(
          "element",
          _.omit(child, "children"),
          (child.children as Node[]).map((c) => ({ ...c, ...attrs }))
        )
      : jsx("text", attrs, child)
  );

const deserialize = (el: HTMLElement) => {
  if (el.nodeType === 3) {
    return el.textContent;
  }
  if (el.nodeType !== 1) {
    return null;
  }
  if (el.nodeName === "BR") {
    return "\n";
  }
  const { nodeName } = el;
  let parent = el;

  if (
    el.nodeName === "PRE" &&
    el.childNodes[0] &&
    el.childNodes[0].nodeName === "CODE"
  ) {
    parent = el.childNodes[0] as HTMLElement;
  }

  const children: (CustomElement | CustomText | null | string)[] = Array.from(
    parent.childNodes
  )
    .map((node) => deserialize(node as HTMLElement))
    .filter((node) => node !== null)
    .flat();
  // .map((node) => (_.isString(node) ? { text: node } : node));

  let childrenOrText = children.length ? children : [{ text: "" }];

  // Ignore whitespace in these element-only parents
  if (["UL", "OL"].includes(nodeName)) {
    childrenOrText = childrenOrText.filter(
      (c) => _.isObject(c) && !Object.hasOwnProperty.call(c, "text")
    );
    if (!childrenOrText.length) {
      return null;
    }
  }

  if (el.nodeName === "BODY") {
    return jsx("fragment", {}, children);
  }

  if (nodeName === "LI") {
    const isNested =
      children.length > 1 &&
      ["ordered-list", "unordered-list"].includes(
        (children[children.length - 1] as CustomElement).type
      );
    const adjustedChildren = childrenOrText.map((c) =>
      _.isObject(c) && c && "text" in c && c.text === "\n"
        ? { ...c, text: "" }
        : c
    );
    if (!adjustedChildren.length) {
      return null;
    }
    return jsx(
      "element",
      {
        type: "list-item"
      },
      [
        jsx(
          "element",
          { type: "list-item-child" },
          isNested
            ? adjustedChildren.slice(0, adjustedChildren.length - 1)
            : adjustedChildren
        ),
        isNested ? adjustedChildren[adjustedChildren.length - 1] : undefined
      ]
    );
  }

  if (nodeName === "DIV" && el.classList.contains("page-break")) {
    return jsx(
      "element",
      {
        type: "page-break"
      },
      [{ text: "" }]
    );
  }

  if (nodeName === "STRONG" && el.classList.contains("highlight")) {
    return formatTagChildren(children, { highlight: true });
  }

  if (nodeName === "P" && el.classList.contains("hard-break-true")) {
    return jsx(
      "element",
      { type: "paragraph", softBreak: true },
      childrenOrText
    );
  }
  if (nodeName === "DIV" && el.classList.contains("pfcs-slate-photo")) {
    return jsx("element", { type: "pfcs-photo", id: el.dataset.id }, [
      { text: "" }
    ]);
  }
  if (nodeName === "TR") {
    const _children = childrenOrText.flatMap((child, index) =>
      index === 0 ? child : [{ text: " " }, child]
    );
    return jsx("element", { type: "paragraph" }, _children);
  }
  if (nodeName === "IMG") {
    return jsx(
      "element",
      { type: "image", url: (el as HTMLImageElement).src },
      [{ text: "" }]
    );
  }
  if (ELEMENT_TAGS[nodeName]) {
    const attrs = ELEMENT_TAGS[nodeName](el);
    if (nodeName === "OL" && (el as HTMLOListElement).getAttribute("start")) {
      (attrs as Partial<ListElement>).start =
        el.getAttribute("start") || undefined;
    }
    return jsx("element", attrs, childrenOrText);
  }

  if (FORMAT_TAGS[nodeName]) {
    const attrs = FORMAT_TAGS[nodeName](el);
    return formatTagChildren(children, attrs);
  }

  return children;
};

const cleanup = (fragment: Node[]) => {
  let adjusted = fragment;
  // let adjusted = fragment.map((node) =>
  //   node.type && node.type !== "link"
  //     ? node
  //     : { type: "paragraph", children: [node] }
  // );
  const last = _.last(adjusted);

  if (
    last &&
    Element.isElement(last) &&
    last.type === "paragraph" &&
    last.children.length === 1 &&
    "text" in last.children[0] &&
    last.children[0].text === "\n"
  ) {
    adjusted = adjusted.slice(0, adjusted.length - 1);
  }

  const final: (Element | Text)[] = [];
  let parentless: Node[] = [];
  for (const node of adjusted) {
    if (Element.isElement(node) && node.type !== "link") {
      if (parentless.length) {
        final.push({
          type: "paragraph",
          children: parentless as (CustomText | LinkElement)[]
        });
        parentless = [];
      }
      final.push(node);
    } else {
      parentless.push(node);
    }
  }
  if (parentless.length) {
    final.push({
      type: "paragraph",
      children: parentless as (CustomText | LinkElement)[]
    });
  }

  return final;
};

export const convertHTMLToFragment = (html: string) => {
  const cleaned = html
    .replace(/\r\n/g, "\n")
    .replace(/\r/g, "\n")
    .replace(/\n/g, "");
  const parsed = new DOMParser().parseFromString(cleaned, "text/html");
  return cleanup(deserialize(parsed.body) as Node[]);
};

export const withPaste = (editor: MyEditor) => {
  const { insertData } = editor;

  editor.insertData = (data) => {
    const slateFragment = data.getData("application/x-slate-fragment");
    const html = data.getData("text/html");
    const text = data.getData("text/plain");
    Sentry.addBreadcrumb({
      category: "paste",
      message: "user pasted data",
      data: { html: JSON.stringify(html) },
      level: "info"
    });

    Sentry.addBreadcrumb({
      category: "paste",
      message: "user pasted plain text",
      data: { text },
      level: "info"
    });
    Sentry.addBreadcrumb({
      category: "paste",
      message: "current editor state",
      data: { editor: JSON.stringify(editor.children) },
      level: "info"
    });
    Sentry.addBreadcrumb({
      category: "paste",
      message: "current editor selection",
      data: { editor: JSON.stringify(editor.selection) },
      level: "info"
    });

    // If pasting an image, just insert the data.
    if (
      data.files.length &&
      !text &&
      (!html || html.match(/<\s*img\b/gi)?.length === 1)
    ) {
      insertData(data);
      return;
      // If we are pasting another Slate fragment, directly decode the fragment instead of parsing html
    }

    if (slateFragment) {
      editor.savePaste({ slateFragment });
      Sentry.addBreadcrumb({
        category: "paste",
        message: "user pasted fragment",
        data: { frag: slateFragment },
        level: "info"
      });
      const decoded = decodeURIComponent(window.atob(slateFragment));
      const parsed = JSON.parse(decoded) as Node[];
      insertSlateFragment(editor, parsed);
    } else if (html) {
      // Otherwise, parse the HTML and convert to a fragment
      const fragment = convertHTMLToFragment(html);
      editor.savePaste({ html, fragment: JSON.stringify(fragment) });
      insertSlateFragment(editor, fragment);
      return;
    } else if (text) {
      editor.savePaste({ plain: text });

      const re =
        /\b((?:pfcs\.co|www\.|[A-Za-z0-9-.]+\.(?:com|net|org)|\w+:\/\/)(?:[.)](?![\s.)]|$)|[^\s.)])*)/;
      const lines = text.split(/\r\n|\r|\n/);
      let split = false;

      for (const line of lines) {
        if (split) {
          Transforms.splitNodes(editor, { always: true });
        }

        // Split out URL parts and convert to links
        const parts = line.split(re);
        for (const part of parts) {
          const url = part.match(/^\w+:\/\/.*$/)
            ? part
            : part.match(/^(pfcs\.co|www\.|[A-Za-z0-9-]+\.(?:com|net|org))/)
              ? `http://${part}`
              : null;
          if (url) {
            editor.insertNode({
              type: "link",
              url,
              children: [{ text: part }]
            });
          } else {
            editor.insertText(part);
          }
        }

        split = true;
      }
    } else {
      insertData(data);
    }
  };

  return editor;
};
