import { useState } from "react";
import type { ModalProps } from "~/components/modal";
import Modal from "~/components/modal";
import { Buttons, Errors, Input, RemixForm } from "~/components/remix-form";
import { formatDate } from "~/utils/dates";

interface Props extends ModalProps {
  name: string;
  goal?: number;
  quarter: string;
}

export default function EditGoal({ name, goal, onClose, quarter }: Props) {
  const data = { value: goal?.toString() || "" };
  const [value, setValue] = useState(data.value);

  return (
    <Modal onExplicitClose={onClose}>
      <RemixForm
        data={data}
        fetcher
        action="/resources/goals/save"
        onSuccess={onClose}
      >
        <input type="hidden" name="name" value={name} />
        <input
          type="hidden"
          name="date"
          value={formatDate(quarter, { format: "YYYY-MM-DD" })}
        />
        <input type="hidden" name="period" value="Quarter" />
        <Modal.Header title={`${name}: Update Goal`} />
        <Modal.Body>
          <Errors />
          <Input
            name="value"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            autoFocus
          />
        </Modal.Body>
        <Modal.Footer>
          <Buttons modal primaryLabel="Update Goal" disabled={!value} />
        </Modal.Footer>
      </RemixForm>
    </Modal>
  );
}
