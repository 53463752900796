import type { CSSProperties } from "react";
import { useEffect, useState } from "react";
import { IconX } from "~/components/icons";

declare module "react" {
  interface InputHTMLAttributes<T> extends HTMLAttributes<T> {
    webkitdirectory?: string;
  }
}

export interface FilePickerProps {
  placeholder?: string;
  className?: string;
  width?: string | number;
  directory?: boolean;
  onChange?: (name: string, value: FileList | File | null) => void;
  accept?: string;
  name: string;
  disabled?: boolean;
  value?: File | null;
  isClearable?: boolean;
}

export default function FilePicker({
  className = "form-control",
  width = "100%",
  placeholder = "Add an attachment...",
  directory,
  disabled,
  accept,
  name,
  value: _value,
  isClearable,
  onChange
}: FilePickerProps) {
  const [key, setKey] = useState(0);
  const [value, setValue] = useState(_value ? _value.name : "");
  useEffect(() => {
    if (!_value) {
      setValue("");
      setKey((key) => key + 1);
    }
  }, [_value]);
  const styles: Record<string, CSSProperties> = {
    parent: {
      position: "relative",
      cursor: "pointer",
      width
    },
    file: {
      position: "absolute",
      top: 0,
      left: 0,
      opacity: 0,
      width: "100%",
      zIndex: 100,
      cursor: "pointer"
    },
    text: {
      position: "relative",
      zIndex: 50,
      cursor: "pointer",
      width: "100%"
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (directory) {
      if (e.target.files!.length) {
        const file = e.target.files![0] as File;
        setValue(file.webkitRelativePath.split("/")[0]);
      } else {
        setValue("Empty Folder Selected");
      }
    } else {
      setValue(e.target.value.split(/(\\|\/)/g).pop()!);
    }
    if (onChange) {
      onChange(name, directory ? e.target.files : (e.target.files || [])[0]);
    }
  };

  return (
    <div style={styles.parent}>
      <input
        type="text"
        tabIndex={-1}
        name={`${name}_filename`}
        value={value}
        onChange={() => {}}
        className={className}
        placeholder={placeholder}
        disabled={disabled}
        style={styles.text}
      />
      {isClearable && (
        <div
          className="absolute inset-y-0 right-0 z-200 flex cursor-pointer items-center pr-3 text-gray-400"
          onClick={() => {
            onChange?.(name, null);
          }}
        >
          <IconX />
        </div>
      )}
      <input
        type="file"
        key={key}
        name={name}
        className={className}
        onChange={handleChange}
        disabled={disabled}
        accept={accept}
        webkitdirectory={directory ? "webkitdirectory" : undefined}
        style={styles.file}
      />
    </div>
  );
}
