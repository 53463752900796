import { useMemo, useState } from "react";
import type { ModalProps } from "~/components/modal";
import InlineForm from "~/components/tasks/inline-form";
import ModalForm from "~/components/tasks/modal-form";
import { useOptionalProject } from "~/contexts";
import type { EditTaskQuery, TaskFieldsFragment, TaskInput } from "~/types/api";
import { useCurrentUser } from "~/utils/auth";
import { useFetcherData } from "~/utils/remix";

interface Props extends ModalProps {
  id?: string;
  source?: TaskFieldsFragment;
  parentId?: string;
  parentType?: string;
  global?: boolean;
  category?: string;
  inline?: boolean;
  autoFocus?: boolean;
}

export default function TaskForm({ id, ...props }: Props) {
  const fetcher = useFetcherData<EditTaskQuery>(
    id ? `/resources/tasks/${id}` : undefined
  );
  if (id && !fetcher.data) {
    return null;
  }
  return <Form source={id ? fetcher.data?.task : undefined} {...props} />;
}

const Form = ({
  source,
  parentId,
  parentType,
  global,
  onClose,
  category,
  inline,
  autoFocus
}: Props) => {
  const project = useOptionalProject();
  const currentUser = useCurrentUser();
  const defaultTask: TaskInput = {
    priority: "C",
    category: category || "",
    creatorId: currentUser.id,
    itemDate: "",
    userId: currentUser.id,
    projectId: project ? project.id : "",
    repeat: "No",
    [`${parentType}Id`]: parentId
  };

  const data = source || defaultTask;

  const [key, setKey] = useState(1);
  const shouldShowCategory = useMemo(() => {
    if (source?.contact || parentType === "contact") {
      return true;
    }

    return false;
  }, [parentType, source]);

  return inline ? (
    <InlineForm
      autoFocus={autoFocus}
      data={data}
      onClose={() => setKey((key) => key + 1)}
      key={key}
      parentId={parentId}
      parentType={parentType}
    />
  ) : (
    <ModalForm
      data={data}
      showCategory={shouldShowCategory}
      onClose={onClose}
      global={global}
      parentId={parentId}
      parentType={parentType}
    />
  );
};
