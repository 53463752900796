import clsx from "clsx";
import _ from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { IconLoading } from "~/components/icons";
import { useCurrentUser } from "~/utils/auth";

interface Props {
  value: unknown;
  name: string;
  slate?: boolean;
  inline?: boolean;
}

export default function Autosave({ value, name, slate, inline }: Props) {
  const [params] = useSearchParams();
  const [saving, setSaving] = useState(false);
  const firstSave = useRef(true);
  const location = useLocation();
  const currentUser = useCurrentUser();
  const duration = params.get("debug") ? 5_000 : 15_000;
  const timerRef = useRef<number>();

  // Auto-save data every 15 seconds
  const throttledSave = useMemo(
    () =>
      _.throttle(
        (value) => {
          const url = `${location.pathname}${location.search}`;
          const data = (
            _.isString(value) ? value : JSON.stringify(value)
          ).trim();
          if (data) {
            setSaving(true);
            const x = {
              url,
              slate: slate ? "1" : "0",
              name,
              data,
              userId: currentUser.id
            };
            // create formdata  from x
            const formData = new FormData();
            for (const key in x) {
              formData.append(key, x[key as keyof typeof x]);
            }
            fetch("/resources/auto-saves/save", {
              method: "post",
              body: formData
            }).then(() => {
              timerRef.current = window.setTimeout(() => setSaving(false), 500);
            });
          }
        },
        duration,
        // Don't save immediately since the data hasn't really changed yet.
        { leading: false }
      ),
    // Don't track fetcher, its state causes a loop
    [location.pathname, location.search, slate, currentUser.id, name, duration]
  );

  useEffect(() => {
    if (firstSave.current) {
      firstSave.current = false;
      return;
    }
    throttledSave(value);
  }, [throttledSave, value]);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        window.clearTimeout(timerRef.current);
      }
    };
  }, []);

  return (
    <div
      className={clsx(
        "pointer-events-none transition-opacity duration-500",
        saving ? "opacity-100" : "opacity-0",
        !inline &&
          "absolute bottom-4 right-4 rounded-lg border border-gray-300 bg-white bg-opacity-95 px-4 py-2 italic text-gray-500"
      )}
    >
      <IconLoading /> Auto saving...
    </div>
  );
}
