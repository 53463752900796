import _ from "lodash";
import { useMemo } from "react";
import type { ComboBoxParentProps } from "~/components/combobox";
import ComboBox from "~/components/combobox";
import type { DepositPickerQuery } from "~/types/api";
import { formatDate } from "~/utils/dates";
import { formatMoney } from "~/utils/formatting";
import { useFetcherData } from "~/utils/remix";

export default function DepositPicker(props: ComboBoxParentProps) {
  const fetcher = useFetcherData<DepositPickerQuery>(
    "/resources/deposits/picker",
    {
      includeId:
        (props.value as string) || (props.defaultValue as string) || undefined
    }
  );

  const options = useMemo(
    () =>
      _.sortBy(fetcher.data?.deposits, "date").map((d) => ({
        value: d.id,
        label: `${formatDate(d.date)}: ${d.account} - ${formatMoney(d.amount)}`
      })),
    [fetcher.data]
  );

  return (
    <ComboBox
      placeholder="Select a Deposit..."
      {...props}
      options={options}
      noOptionsMessage="No Available Deposits, create one on the A/R page"
    />
  );
}
