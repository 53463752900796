import clsx from "clsx";
import type { PropsWithChildren } from "react";
import type React from "react";
import type { ConnectDropTarget } from "react-dnd";

export interface PanelBodyProps
  extends PropsWithChildren<unknown>,
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    > {
  innerRef?: React.RefObject<HTMLDivElement> | ConnectDropTarget;
}

export default function Body({
  style,
  children,
  innerRef,
  className = "",
  ...rest
}: PanelBodyProps) {
  return (
    <div
      className={clsx("panel-body", className)}
      style={style}
      ref={innerRef}
      {...rest}
    >
      {children}
    </div>
  );
}
