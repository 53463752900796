import clsx from "clsx";
import type { RenderElementProps, RenderLeafProps } from "slate-react";
import { DefaultElement, useSelected } from "slate-react";
import { Image, PFCSPhoto } from "~/components/rich-editor/images";
import { Link } from "~/components/rich-editor/links";
import { OrderedList } from "~/components/rich-editor/lists";

const PageBreakElement = (props: RenderElementProps) => {
  const { attributes, children } = props;

  const selected = useSelected();
  return (
    <div {...attributes} className="page-break" contentEditable={false}>
      <div
        className={clsx(
          "my-7 border-b text-center leading-[0.1em]",
          selected
            ? "border-b-2 border-blue-600 text-blue-600"
            : "border-gray-500"
        )}
      >
        <span className="bg-white px-4 py-0 italic">Page Break</span>
      </div>
      {children}
    </div>
  );
};

export const renderElement = (props: RenderElementProps) => {
  const { attributes, children, element } = props;
  switch (element.type) {
    case "h1":
      return <h1 {...attributes}>{children}</h1>;
    case "h2":
      return <h2 {...attributes}>{children}</h2>;
    case "h3":
      return <h3 {...attributes}>{children}</h3>;
    case "h4":
      return <h4 {...attributes}>{children}</h4>;
    case "h5":
      return <h5 {...attributes}>{children}</h5>;
    case "ordered-list":
      return <OrderedList {...props} />;

    case "unordered-list":
      return <ul {...attributes}>{children}</ul>;
    case "list-item":
      return <li {...attributes}>{children}</li>;
    case "list-item-child":
      return <span {...attributes}>{children}</span>;
    case "paragraph":
      return (
        <p
          {...attributes}
          className={element.softBreak ? "hard-break-true" : undefined}
        >
          {children}
        </p>
      );
    case "page-break":
      return <PageBreakElement {...props} />;
    case "link":
      return <Link {...props} />;
    case "image":
      return <Image {...props} />;
    case "pfcs-photo":
      return <PFCSPhoto {...props} />;
    default:
      return <DefaultElement {...props} />;
  }
};

export const renderLeaf = (props: RenderLeafProps) => {
  const { leaf, attributes, children } = props;
  let wrapped = children;
  if (leaf.bold) wrapped = <strong>{wrapped}</strong>;
  if (leaf.highlight)
    wrapped = (
      <strong className="highlight text-red-600 underline">{wrapped}</strong>
    );
  if (leaf.code) wrapped = <code>{wrapped}</code>;
  if (leaf.italic) wrapped = <em>{wrapped}</em>;
  if (leaf.underline) wrapped = <u>{wrapped}</u>;
  return <span {...attributes}>{wrapped}</span>;
};
