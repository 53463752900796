import type { KpiFieldsFragment } from "~/types/api";
import { formatMoney, formatNumber } from "~/utils/formatting";

export type KpiValueType =
  | "Percentage"
  | "Percentage (Higher Precision)"
  | "Hours"
  | "Number"
  | "Accounting"
  | "Accounting (Rounded)";

export const formatRawValue = (
  value: number | string,
  format: KpiValueType | null | undefined,
  opts: { zeroes?: boolean; decorator?: boolean } = {}
) => {
  if (format === "Percentage") {
    return formatNumber(value, { format: "0.0%", zeroes: opts.zeroes });
  }
  if (format === "Percentage (Higher Precision)") {
    return formatNumber(value, { format: "0.000%", zeroes: opts.zeroes });
  }
  if (format === "Hours") {
    return formatNumber(value, { zeroes: opts.zeroes });
  }
  if (format === "Number") {
    return formatNumber(value, { format: "0,0", zeroes: opts.zeroes });
  }
  if (format?.startsWith("Accounting")) {
    return formatMoney(value, {
      decorator: opts.decorator === false ? undefined : true,
      zeroes: opts.zeroes ? undefined : false,
      round: format === "Accounting (Rounded)"
    });
  }
  return formatNumber(value, { zeroes: opts.zeroes });
};

export const formatValue = (item: KpiFieldsFragment) => {
  if (item?.notApplicable) {
    return "N/A";
  }
  if (!item || !item.value) {
    return null;
  }
  if (Number.parseFloat(item.value) === 0) {
    return "-";
  }

  const { value } = item;
  const format = item.kpiType.valueType as KpiValueType;
  return formatRawValue(value, format);
};

export default {
  formatValue,
  formatRawValue
};
