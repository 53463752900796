import Button from "~/components/button";
import { IconAdd } from "~/components/icons";
import { Errors, Input, RemixForm } from "~/components/remix-form";
import Cell from "~/components/table-cell";
import Row from "~/components/table-row";
import { PRIORITIES } from "~/constants";
import { RemixFormContext } from "~/contexts";
import type { TaskFieldsFragment, TaskInput } from "~/types/api";

interface Props {
  onClose: () => void;
  parentType?: string;
  parentId?: string;
  data: TaskFieldsFragment | TaskInput;
  autoFocus?: boolean;
}

export default function InlineForm({
  data,
  onClose,
  parentType,
  parentId,
  autoFocus
}: Props) {
  return (
    <div>
      <RemixForm
        data={data}
        fetcher
        action="/resources/tasks/save"
        onSuccess={onClose}
      >
        {!("__typename" in data) && (
          <>
            <input
              type="hidden"
              name="projectId"
              value={data.projectId || undefined}
            />
            <input
              type="hidden"
              name="repeat"
              value={data.repeat || undefined}
            />
            <input
              type="hidden"
              name="creatorId"
              value={data.creatorId || undefined}
            />
            <input
              type="hidden"
              name="category"
              value={data.category || undefined}
            />
            {parentType && parentId && (
              <input type="hidden" name={`${parentType}Id`} value={parentId} />
            )}
          </>
        )}
        <RemixFormContext.Consumer>
          {(form) =>
            form?.errors && (
              <Row style={{ marginBottom: -18 }}>
                <Cell size="75" />
                <Cell>
                  <Errors />
                </Cell>
              </Row>
            )
          }
        </RemixFormContext.Consumer>
        <Row>
          <Cell size="40" className="text-center" style={{ paddingTop: 10 }}>
            <IconAdd />
          </Cell>
          <Cell size="30" />
          <Cell>
            <Input
              wrapperStyle={{ marginBottom: 0 }}
              name="description"
              noLabel
              autoFocus={autoFocus}
              type="textarea"
              minRows={1}
            />
          </Cell>
          {data.projectId ? (
            <Cell>
              <Input
                wrapperStyle={{ marginBottom: 0 }}
                name="deliverableId"
                type="deliverable"
                noLabel
                projectId={data.projectId}
              />
            </Cell>
          ) : (
            <input type="hidden" name="deliverableId" value="" />
          )}
          <Cell size="140">
            <Input
              wrapperStyle={{ marginBottom: 0 }}
              name="userId"
              type="user"
              placeholder="Who?"
              noLabel
            />
          </Cell>
          <Cell size="100">
            <Input
              wrapperStyle={{ marginBottom: 0 }}
              name="itemDate"
              type="date"
              noLabel
              className="text-center"
            />
          </Cell>
          <Cell size="80">
            <Input
              wrapperStyle={{ marginBottom: 0 }}
              name="priority"
              type="combo"
              options={PRIORITIES}
              noLabel
              isClearable={false}
            />
          </Cell>
          <Cell size="80">
            <Input
              wrapperStyle={{ marginBottom: 0 }}
              name="hours"
              className="text-center"
              noLabel
            />
          </Cell>
          <Cell size="80" className="text-right">
            <Button type="submit" mode="primary">
              Save
            </Button>
          </Cell>
        </Row>
      </RemixForm>
    </div>
  );
}
