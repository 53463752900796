import _ from "lodash";
import { useMemo } from "react";
import Badge from "~/components/badge";
import type { ComboBoxParentPropsSingle } from "~/components/combobox";
import ComboBox from "~/components/combobox";
import type { DeliverablesQuery } from "~/types/api";
import { useFetcherData } from "~/utils/remix";

export type DeliverablePickerProps = ComboBoxParentPropsSingle & {
  splitActive?: boolean;
  projectId?: string | null;
};

export default function DeliverablePicker({
  splitActive = false,
  projectId,
  ...rest
}: DeliverablePickerProps) {
  const fetcher = useFetcherData<DeliverablesQuery | null>(
    projectId ? "/resources/deliverables/picker" : undefined,
    { projectId }
  );

  const milestoneParents = _.sortBy(
    fetcher?.data?.plan?.milestoneParents || [],
    "rank"
  );

  const groups = useMemo(
    () =>
      milestoneParents.map((mp) => ({
        label: mp.title,
        options: _.sortBy(mp.deliverables, "rank").map((deliverable) => ({
          value: deliverable.id,
          label: deliverable.description,
          rightLabel: deliverable.completed ? (
            splitActive ? (
              <Badge label="Done, Do Not Use" />
            ) : (
              <Badge label="Done" />
            )
          ) : null
        }))
      })),
    [milestoneParents, splitActive]
  );

  let promptMessage = "Deliverable...";
  if (!projectId) {
    promptMessage = "Select a project first...";
  } else if (!fetcher.data?.plan) {
    promptMessage = "Loading deliverables...";
  } else if (
    _.every(
      fetcher.data?.plan.milestoneParents,
      (mp) => mp.deliverables.length === 0
    )
  ) {
    promptMessage = "No deliverables available...";
  }

  return <ComboBox {...rest} options={groups} placeholder={promptMessage} />;
}
