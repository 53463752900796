import _ from "lodash";
import type { ComboBoxParentPropsSingle } from "~/components/combobox";
import ComboBox from "~/components/combobox";
import type { MilestoneParentsQuery } from "~/types/api";
import { useFetcherData } from "~/utils/remix";

export interface MilestoneParentPickerProps extends ComboBoxParentPropsSingle {
  planId: string;
}

export default function MilestoneParentPicker({
  planId,
  ...rest
}: MilestoneParentPickerProps) {
  const fetcher = useFetcherData<MilestoneParentsQuery>(
    "/resources/plans/milestone-parent-picker",
    { planId }
  );

  const filtered = (fetcher.data?.milestoneParents || []).filter(
    (mp) =>
      mp.title !== "Uncategorized Milestones & Deliverables" ||
      mp.deliverables.length > 0
  );

  const options = _.sortBy(filtered, "rank").map((mp) => ({
    value: mp.id,
    label: mp.title
  }));

  return (
    <ComboBox options={options} placeholder="Select a category..." {...rest} />
  );
}
