import { useEffect, useRef, useState } from "react";
import { DatePickerCalendar } from "~/components/date-field";
import Link, { useLocationPusher } from "~/components/link";
import { formatDate } from "~/utils/dates";

interface Props {
  value: string;
  redirect?: boolean;
  onChange?: (value: string) => void;
}

export default function DatePickerMenu({ value, redirect, onChange }: Props) {
  const push = useLocationPusher();
  const [show, setShow] = useState(false);
  const ref = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (show) {
      const handler = (e: MouseEvent) => {
        if (!ref.current?.contains(e.target as Node)) {
          setShow(false);
        }
      };
      window.addEventListener("click", handler, { capture: true });
      return () =>
        window.removeEventListener("click", handler, { capture: true });
    }
  }, [show]);

  const _onChange = (date: Date) => {
    setShow(false);
    const formatted = formatDate(date, { format: "YYYY-MM-DD" });
    if (redirect) {
      push({ date: formatted });
    } else {
      onChange?.(formatted);
    }
  };

  const label = <>Date: {formatDate(value)}</>;

  return (
    <li ref={ref}>
      <Link to={() => setShow(!show)}>
        {label}
        <span>
          {" "}
          <b className="caret" />
        </span>
      </Link>
      {show && <DatePickerCalendar value={value} onSelect={_onChange} />}
    </li>
  );
}
