import _ from "lodash";
import { useMemo } from "react";
import type {
  ComboBoxOption,
  ComboBoxParentPropsSingle
} from "~/components/combobox";
import ComboBox from "~/components/combobox";
import type { AuditTemplatesQuery } from "~/types/api";
import { useFetcherData } from "~/utils/remix";

export type AuditTemplatePickerProps = ComboBoxParentPropsSingle & {
  scope?: string;
  injectOptions?: ComboBoxOption[];
};

export default function AuditTemplatePicker({
  scope,
  injectOptions,
  ...rest
}: AuditTemplatePickerProps) {
  const fetcher = useFetcherData<AuditTemplatesQuery>(
    "/resources/audits/templates/picker"
  );

  const options = useMemo(() => {
    const sorted = _.sortBy(fetcher.data?.auditTemplates || [], "name").filter(
      (t) => (t.active || rest.value === t.id) && (!scope || scope === t.scope)
    );
    return [
      ...(injectOptions || []),
      ...sorted.map((item) => ({
        value: item.id,
        label: item.name,
        extra: item
      }))
    ];
  }, [rest.value, scope, fetcher.data, injectOptions]);

  return (
    <ComboBox
      placeholder="Select an Audit Type..."
      {...rest}
      options={options}
    />
  );
}
