import { useFetcher } from "@remix-run/react";
import clsx from "clsx";
import isHotkey from "is-hotkey";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import type { BaseEditor } from "slate";
import { Editor, Element, Point, Range, Transforms, createEditor } from "slate";
import type { HistoryEditor } from "slate-history";
import { withHistory } from "slate-history";
import type { ReactEditor } from "slate-react";
import { Editable, Slate, withReact } from "slate-react";
import Alert from "~/components/alert";
import Autosave from "~/components/autosave";
import { withImages } from "~/components/rich-editor/images";
import type { EditorWithLinks } from "~/components/rich-editor/links";
import { withLinks } from "~/components/rich-editor/links";
import { handleListKeys, withLists } from "~/components/rich-editor/lists";
import withNormalization from "~/components/rich-editor/normalization";
import { withPaste } from "~/components/rich-editor/paste";
import { renderElement, renderLeaf } from "~/components/rich-editor/rendering";
import SlateToolbar, { isFormatActive } from "~/components/rich-editor/toolbar";
import WordCounter from "~/components/word-counter";
import { useCurrentUser } from "~/utils/auth";
import { BLANK_SLATE } from "~/utils/text";

export type SlateMarkTypes =
  | "bold"
  | "italic"
  | "highlight"
  | "code"
  | "underline";

export type CustomText = {
  text: string;
  bold?: boolean;
  italic?: boolean;
  highlight?: boolean;
  code?: boolean;
  underline?: boolean;
};

export type LinkElement = {
  type: "link";
  url: string;
  children: CustomText[];
};

export type ParagraphElement = {
  type: "paragraph";
  softBreak?: boolean;
  children: (LinkElement | CustomText)[];
};

export type HeadingElement = {
  type: "h1" | "h2" | "h3" | "h4" | "h5";
  children: (LinkElement | CustomText)[];
};

export type ImageElement = {
  type: "image";
  url?: string;
  pending?: string;
  uuid?: string;
  size?: string;
  width?: string;
  children: [{ text: "" }];
};

export type PhotoElement = {
  type: "pfcs-photo";
  id: string;
  size?: string;
  children: [{ text: "" }];
};

export type ListItemChildElement = {
  type: "list-item-child";
  children: (LinkElement | CustomText)[];
};

export type ListItemElement = {
  type: "list-item";
  indent?: number;
  children: (ListItemChildElement | ListElement)[];
};

export type ListElement = {
  type: "ordered-list" | "unordered-list";
  start?: number | string;
  mode?: string;
  children: ListItemElement[];
};

export type PageBreakElement = {
  type: "page-break";
  children: [{ text: "" }];
};

export type CustomElement =
  | ParagraphElement
  | HeadingElement
  | ImageElement
  | PhotoElement
  | ListElement
  | ListItemElement
  | ListItemChildElement
  | PageBreakElement
  | LinkElement;

declare module "slate" {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor & HistoryEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

type SavePasteArgs = {
  fragment?: string;
  slateFragment?: string;
  plain?: string;
  html?: string;
};

export interface MyEditor
  extends ReactEditor,
    HistoryEditor,
    EditorWithRichText,
    EditorWithLinks {
  readOnly?: boolean;
  savePaste: (args: SavePasteArgs) => void;
}

export interface SlateEditorProps {
  warning?: { type: string; index: number; message: string };
  debug?: boolean;
  name: string;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  value?: unknown;
  minHeight?: number;
  panel?: boolean;
  onTemplateChange?: (template: string) => void;
  onChange?: (name: string, value: CustomElement[]) => void;
  wordCount?: boolean;
}

const MARK_HOTKEYS: { [key: string]: string } = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+h": "highlight"
};

export interface EditorWithRichText extends ReactEditor {
  toggleBlock: (block: string) => void;
}

export const withRichText = (_editor: Editor) => {
  const editor = _editor as MyEditor;
  const { isVoid } = editor;

  editor.isVoid = (element: CustomElement) => {
    return ["page-break"].includes(element.type) ? true : isVoid(element);
  };

  editor.toggleBlock = (block) => {
    if (block === "page-break") {
      Editor.withoutNormalizing(editor, () => {
        const newNode: PageBreakElement = {
          type: "page-break",
          children: [{ text: "" }]
        };
        // Add the page break
        Transforms.insertNodes(editor, newNode);
        // Split so our new page break is in its own node
        Transforms.splitNodes(editor, { mode: "highest" });
        // Unwrap the page break in case we're nested in a list
        Transforms.unwrapNodes(editor, {
          match: (n) =>
            Element.isElement(n) &&
            ["ordered-list", "unordered-list", "list-item"].includes(n.type),
          mode: "all",
          split: true
        });
      });
      // Move the selection to after the new page break
      Transforms.move(editor, { distance: 1, unit: "line" });
    }
  };

  return editor;
};

export const withShortcuts = (editor: MyEditor) => {
  const { insertText } = editor;
  editor.insertText = (text: string) => {
    const { selection } = editor;
    if (text === " " && selection && Range.isCollapsed(selection)) {
      const { anchor } = selection;
      const block = Editor.above(editor, {
        match: (n) => Element.isElement(n) && Editor.isBlock(editor, n)
      });
      if (block) {
        const path = block[1];
        const blockType = (block[0] as CustomElement).type;
        if (blockType === "paragraph") {
          const start = Editor.start(editor, path);
          const range = { anchor, focus: start };
          const beforeText = Editor.string(editor, range);
          if (["-", "*"].includes(beforeText)) {
            Transforms.select(editor, range);
            Transforms.delete(editor);
            Editor.withoutNormalizing(editor, () => {
              Transforms.setNodes(editor, { type: "list-item-child" });
              Transforms.wrapNodes(editor, {
                type: "unordered-list",
                children: []
              });
              Transforms.wrapNodes(editor, { type: "list-item", children: [] });
            });
            return;
          }
          if (beforeText === "#") {
            Transforms.select(editor, range);
            Transforms.delete(editor);
            Editor.withoutNormalizing(editor, () => {
              Transforms.setNodes(editor, { type: "list-item-child" });
              Transforms.wrapNodes(editor, {
                type: "ordered-list",
                children: []
              });
              Transforms.wrapNodes(editor, { type: "list-item", children: [] });
            });
            return;
          }
          if (beforeText.match(/^=+$/)) {
            const count = beforeText.length;
            Transforms.select(editor, range);
            Transforms.delete(editor);
            Transforms.setNodes(editor, {
              type: `h${count}` as HeadingElement["type"]
            });
            return;
          }
        }
      }
    }
    insertText(text);
  };
  return editor;
};

export default React.memo(function SlateEditor({
  debug,
  name,
  autoFocus,
  disabled,
  minHeight,
  value: valueOrString,
  onChange,
  panel,
  onTemplateChange,
  className = "",
  warning,
  placeholder = "Write...",
  wordCount
}: SlateEditorProps) {
  const [selection, setSelection] = useState<Range | null>(null);
  const [localValue, setLocalValue] = useState<CustomElement[]>(() =>
    !valueOrString
      ? BLANK_SLATE
      : _.isString(valueOrString)
        ? (JSON.parse(valueOrString) as CustomElement[])
        : (valueOrString as CustomElement[])
  );

  const [editor] = useState<MyEditor>(
    withNormalization(
      withPaste(
        withShortcuts(
          withImages(
            withLinks(
              withLists(withRichText(withHistory(withReact(createEditor()))))
            )
          )
        )
      )
    )
  );

  const currentUser = useCurrentUser();
  const location = useLocation();
  const fetcher = useFetcher<unknown>();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  editor.savePaste = useCallback(
    (args: SavePasteArgs) => {
      const fd = new FormData();
      if (args.fragment) fd.set("fragment", args.fragment);
      if (args.html) fd.set("html", args.html);
      if (args.plain) fd.set("plain", args.plain);
      if (args.slateFragment) fd.set("slateFragment", args.slateFragment);
      fd.set("children", JSON.stringify(editor.children));
      fd.set("selection", JSON.stringify(editor.selection));
      fd.set("userId", currentUser.id);
      fd.set("url", location.pathname);
      fetcher.submit(fd, { action: "/resources/pastes/save", method: "post" });
    },

    [currentUser, location, editor]
  );

  useEffect(() => {
    Editor.normalize(editor, { force: true });
  }, [editor]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      handleListKeys(editor, event, () => {
        // Reset to paragraph when hitting enter in a heading
        Object.keys(MARK_HOTKEYS).forEach((hotkey) => {
          if (isHotkey(hotkey, event.nativeEvent)) {
            event.preventDefault();
            if (isFormatActive(editor, MARK_HOTKEYS[hotkey])) {
              editor.removeMark(MARK_HOTKEYS[hotkey]);
            } else {
              editor.addMark(MARK_HOTKEYS[hotkey], true);
            }

            return;
          }
        });
        if (isHotkey("mod+shift+7", event.nativeEvent)) {
          event.preventDefault();
          editor.selection && editor.toggleBlock("ordered-list");
          return;
        }
        if (isHotkey("mod+shift+8", event.nativeEvent)) {
          event.preventDefault();
          editor.selection && editor.toggleBlock("unordered-list");
          return;
        }
        if (
          isHotkey("shift+enter", event.nativeEvent) &&
          editor.selection &&
          Range.isCollapsed(editor.selection)
        ) {
          const [match] = Editor.nodes(editor, {
            match: (node) =>
              Element.isElement(node) && node.type === "paragraph"
          });
          if (match) {
            event.preventDefault();
            Transforms.setNodes(editor, { softBreak: true });
            editor.insertBreak();
            Transforms.setNodes(editor, { softBreak: undefined });
            return;
          }
        }
        // Delete on an empty line with a void node after
        if (
          event.key === "Delete" &&
          editor.selection &&
          Range.isCollapsed(editor.selection)
        ) {
          const [match] = Editor.nodes(editor, {
            match: (node) => Element.isElement(node)
          });
          if (Editor.isEmpty(editor, match[0] as Element)) {
            const next = Editor.next(editor, { at: match[1] });
            if (next && editor.isVoid(next[0] as Element)) {
              event.preventDefault();
              Transforms.removeNodes(editor, { at: match[1] });
            }
          }
        }
        if (
          event.key === "Enter" &&
          editor.selection &&
          Range.isCollapsed(editor.selection)
        ) {
          const [match] = Editor.nodes(editor, {
            match: (node) =>
              Element.isElement(node) && /^h\d/.test(node.type as string)
          });
          // If we're in a heading, reset formatting for the next line
          if (match) {
            const [, path] = match;
            const end = Editor.end(editor, path);
            if (Point.equals(editor.selection.anchor, end)) {
              event.preventDefault();
              editor.insertBreak();
              Transforms.setNodes(editor, { type: "paragraph" });
              return;
            }
          } else {
            const [match] = Editor.nodes(editor, {
              match: (node) => Element.isElement(node)
            });
            const element = match[0] as Element;

            // When hitting enter on a void node, insert a line above and move up
            if (editor.isVoid(element)) {
              event.preventDefault();
              Transforms.insertNodes(
                editor,
                { type: "paragraph", children: [{ text: "" }] },
                { at: match[1] }
              );
              Transforms.setSelection(editor, {
                anchor: { path: [...match[1], 0], offset: 0 },
                focus: { path: [...match[1], 0], offset: 0 }
              });
              return;
            }

            // Fix hitting enter at the end of an inline
            const [inline] = Editor.nodes(editor, {
              match: (node) => editor.isInline(node as Element)
            });
            if (inline) {
              const path = inline[1];
              if (Editor.isEnd(editor, editor.selection.anchor, path)) {
                const point = Editor.after(editor, path)!;
                Transforms.setSelection(editor, { anchor: point });
              }
            }

            const softBreak =
              element.type === "paragraph" &&
              !!element.softBreak &&
              !Point.equals(
                editor.selection.anchor,
                Editor.end(editor, match[1])
              );
            event.preventDefault();
            Transforms.setNodes(editor, { softBreak: undefined });

            editor.insertBreak();
            if (softBreak) {
              Transforms.setNodes(editor, { softBreak: true });
            }
            return;
          }
        }
      });
    },
    [editor]
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const timer: number | null = null;
    if (debug) {
      window.setInterval(() => {
        setSelection(editor.selection);
      }, 500);
    }
    return () => {
      timer && window.clearInterval(timer);
    };
  }, []);

  return (
    <div
      className={clsx(
        "relative rounded-lg",
        className,
        panel ? "slate-container-panel" : "slate-container",
        panel ? "mb-0" : "mb-4",
        !panel && "border border-gray-300"
      )}
    >
      <Slate
        editor={editor}
        initialValue={localValue}
        onChange={(v) => {
          setLocalValue(v as CustomElement[]);
          const isAstChange = editor.operations.some(
            (op) => "set_selection" !== op.type
          );
          if (isAstChange) {
            onChange?.(name, v as CustomElement[]);
          }
        }}
      >
        <SlateToolbar
          editor={editor}
          panel={panel}
          onTemplateChange={onTemplateChange}
        />
        {warning && localValue[warning.index].type === warning.type && (
          <Alert className="m-4 mb-0" mode="danger">
            {warning.message}
          </Alert>
        )}
        <Editable
          autoFocus={autoFocus}
          placeholder={placeholder}
          readOnly={disabled}
          className={clsx(
            "slate-editor",
            disabled && "bg-[#eee] text-gray-500"
          )}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onKeyDown={handleKeyDown}
          // Slate defaults to pre-wrap which doesn't break between a bunch of trailing spaces...
          style={{
            whiteSpace: "break-spaces",
            outline: "none",
            ...(minHeight ? { minHeight: minHeight - 34 } : {})
          }}
        />
        {!wordCount && <Autosave value={localValue} name={name} slate />}

        <input type="hidden" name={name} value={JSON.stringify(localValue)} />
      </Slate>
      {wordCount && (
        <div className="flex items-center justify-end space-x-4 border-t border-gray-300 px-4 py-2 italic text-gray-500">
          {<Autosave value={localValue} name={name} slate inline />}
          <WordCounter text={localValue} longWarning={false} />
        </div>
      )}
      {debug && (
        <div className="flex divide-x divide-gray-300">
          <pre className="flex-1">
            <code>{JSON.stringify(localValue, null, 2)}</code>
          </pre>
          <pre className="w-[200px]">
            <code>
              Selection
              <br />
              <br />
              {JSON.stringify(selection, null, 2)}
            </code>
          </pre>
        </div>
      )}
    </div>
  );
});
