import _ from "lodash";
import { useMemo } from "react";
import type { ComboBoxParentProps } from "~/components/combobox";
import ComboBox from "~/components/combobox";
import type { ListCandidatesQuery } from "~/types/api";
import { useFetcherData } from "~/utils/remix";

export default function CandidatePicker({
  placeholder = "Select a Candidate...",
  ...rest
}: ComboBoxParentProps) {
  const fetcher = useFetcherData<ListCandidatesQuery>(
    "/resources/candidates/picker"
  );

  const options = useMemo(
    () =>
      _.sortBy(fetcher.data?.candidates || [], "firstName", "lastName").map(
        (o) => ({
          value: o.id,
          label: `${o.firstName} ${o.lastName}`
        })
      ),
    [fetcher.data]
  );

  return <ComboBox {...rest} placeholder={placeholder} options={options} />;
}
