// Used for cross-component communication. Right now, the only use is
// to trigger refreshing of data from useFetcher.
class EventManager {
  // biome-ignore lint/complexity/noBannedTypes: <explanation>
  events: { [key: string]: Function[] } = {};
  constructor() {
    this.events = {};
  }

  publish(name: string, data: unknown) {
    const handlers = this.events[name];
    if (!handlers) return;
    handlers.forEach((handler) => handler(data));
  }

  // biome-ignore lint/complexity/noBannedTypes: <explanation>
  subscribe(name: string, handler: Function) {
    if (!this.events[name]) this.events[name] = [];
    this.events[name].push(handler);
  }

  // biome-ignore lint/complexity/noBannedTypes: <explanation>
  unsubscribe(name: string, handler: Function) {
    const handlers = this.events[name];
    if (!handlers) return;
    handlers.splice(handlers.indexOf(handler), 1);
  }
}

export const eventManager = new EventManager();
